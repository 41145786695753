import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/quizzvideo`;
const token = localStorage.getItem("token");


/// GET
// Fonction pour afficher tous les quizzvideo
export const getQuizzVideos = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allQuizz`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors du chargement des quizzvideo:", error);
    return [];
  }
}

/// POST
// Fonction pour créer un quizzvideo
export const createQuizzVideo = async (quizzvideo) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addQuizz`,
      quizzvideo,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la création du quizzvideo:", error);
    return null;
  }
}

/// PUT
// Fonction pour modifier un quizzvideo
export const updateQuizzVideo = async (quizzvideo) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateQuizz/${quizzvideo.id}`,
      quizzvideo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la modification du quizzvideo:", error);
    return null;
  }
}

/// DELETE
// Fonction pour supprimer un quizzvideo
export const deleteQuizzVideo = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteQuizz/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la suppression du quizzvideo:", error);
    return null;
  }
}

