import React, { useState, useEffect } from "react";
import { getAllGalleries } from "./../../services/gallerieServices"; // Assurez-vous que le chemin est correct
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import useScrollToTop from "./../../hooks/useScrollToTop";
import Button from "./../../components/Button/Button";
import GalleryCard from "./GalleryCard"; // Assurez-vous que le chemin est correct
import { ToastContainer, toast } from "react-toastify";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import CreateAlbumModal from "./CreateAlbumModal"; // Assurez-vous que le chemin est correct
import ScrollIndicator from "./../../hooks/ScrollIndicator";

const GalleriesPhotos = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [galleries, setGalleries] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    fetchGalleries();
  }, [showAll]);

  const fetchGalleries = async () => {
    try {
      const response = await getAllGalleries();
      const filteredGalleries = showAll ? response : response.filter(gallery => gallery.is_active);
      setGalleries(filteredGalleries);
    } catch (error) {
      console.error("Failed to fetch galleries:", error);
    }
  };

  const handleAddGallery = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    fetchGalleries();
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Galeries Photos
      </h2>
      <InfoBubble title="Bienvenue sur la page Galeries Photos">
        <p>Sur cette page, nous regrouperons l'ensemble des photos et les podiums des compétitions.</p>
      </InfoBubble>
      <div className="overflow-x-auto w-full p-4 max-w-[1280px] mx-auto">
        <div className="flex flex-row mx-auto w-full gap-4 mb-4">
        {/* Bouton visible uniquement pour les administrateurs et le Bureau*/}
        {(userRole === "Administrateur" || userRole === "Bureau") && (
          <Button text="Ajouter un Album" onClick={handleAddGallery} className="mb-4" />
        )}
        {(userRole === "Administrateur" || userRole === "Bureau") && (
          <Button text={showAll ? "Voir uniquement actifs" : "Voir tous les albums"} onClick={toggleShowAll} className="mb-4" />
        )}
        </div>
        {galleries.map((gallery, index) => (
          <GalleryCard key={gallery.id} gallery={gallery} index={index} userRole={userRole} refreshGalleriesList={fetchGalleries} />
        ))}
      </div>
      <ToastContainer />
      {isCreateModalOpen && (
        <CreateAlbumModal
          isOpen={isCreateModalOpen}
          onClose={closeCreateModal}
          refreshList={fetchGalleries}
        />
      )}
    </div>
  );
}

export default GalleriesPhotos;
