import React, { useState, useEffect } from "react";
import { FaFilePdf, FaCamera } from "react-icons/fa";
import { getAllEvents } from "../../services/eventServices";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import { useUser } from "../../contexts/UserContext";
import useScrollToTop from "../../hooks/useScrollToTop";
import formatDate from "../../utils/formatDate";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";


const cateEvents = [
  "Eveil",
  "Mini-Poussin(e)",
  "Poussin(e)",
  "Benjamin(e)",
  "Minime",
  "Cadet(te)",
  "Junior",
  "Sénior",
  "Vétéran",
];

const ResultatsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = user ? user.role : 'guest';
  const [events, setEvents] = useState([]);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const fetchEvents = async () => {
    try {
      const eventsData = await getAllEvents();
      const filteredEvents = eventsData.filter(event => 
        event.level_event === "Départemental" &&
        (event.type_event === "Animation" || event.type_event === "Compétition")
      );
      setEvents(filteredEvents);
      setDisplayedEvents(filteredEvents); // Initialize displayedEvents
    } catch (error) {
      console.error("Erreur lors du chargement des événements:", error);
      toast.error("Erreur lors du chargement des événements");
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterEvents(searchTermLocal, selectedCategory);
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryLocal = e.target.value;
    setSelectedCategory(selectedCategoryLocal);
    filterEvents(searchTerm, selectedCategoryLocal);
  };

  const filterEvents = (term, category) => {
    let filteredEvents = events;
    if (term) {
      filteredEvents = filteredEvents.filter(
        (event) =>
          event.titre.toLowerCase().includes(term.toLowerCase())
      );
    }
    if (category) {
      filteredEvents = filteredEvents.filter(
        (event) => event.cate_event.split(", ").some(cat => cat.includes(category))
      );
    }
    setDisplayedEvents(filteredEvents);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing des Résultats Sportifs
      </h2>
            <InfoBubble title="Bienvenue sur la page Résultats Sportifs">
        <p>
          Sur cette page, vous trouverez les liens vers les résultats des compétitions et animations départementales. Vous pouvez filtrer les résultats par catégorie d'âge et recherche par titre.
        </p>
      </InfoBubble>
      <div className="shadow-xl w-full max-w-[1280px] mx-auto">
        <input
          className="input input-bordered mt-4 mb-4 w-full"
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select
          className="input input-bordered mb-4 w-full"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="">Toutes les catégories</option>
          {cateEvents.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className="overflow-x-auto shadow-xl">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Date
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Compétition / Animation
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Rapport
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Tableaux
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Sélection
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Photos Podiums
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedEvents.map((event) => (
              <tr key={event.id} className="text-left">
                <td className="px-5 py-3">{formatDate(event.start)}</td>
                <td className="px-5 py-3">{event.titre}</td>
                <td className="px-5 py-3">
                  {event.rapport && (
                    <a href={event.rapport} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-lg">
                      <FaFilePdf className="inline-block" />
                    </a>
                  )}
                </td>
                <td className="px-5 py-3">
                  {event.tableaux && (
                    <a href={event.tableaux} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-lg">
                      <FaFilePdf className="inline-block" />
                    </a>
                  )}
                </td>
                <td className="px-5 py-3">
                  {event.selection && (
                    <a href={event.selection} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-lg">
                      <FaFilePdf className="inline-block" />
                    </a>
                  )}
                </td>
                <td className="px-5 py-3">
                  {event.photos_podiums && (
                    <a href={event.photos_podiums} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-lg">
                      <FaCamera className="inline-block" />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResultatsPage;
