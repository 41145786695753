import React from 'react';
import Button from "../../components/Button/Button";
import { FaTimes } from "react-icons/fa";

const ObservationsModal = ({ isOpen, onClose, observations }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Observations
        </h3>
        <div className="mb-4 text-sm" dangerouslySetInnerHTML={{ __html: observations }}></div>
        <Button
          onClick={onClose}
          className="btn-ghost flex items-center gap-2 mt-4"
          text="Fermer"
          icon={FaTimes}
        />
      </div>
    </div>
  );
};

export default ObservationsModal;
