import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/contactform`;
const token = localStorage.getItem("token");

///GET
// Fonction pour récupérer tous les messages du formulaire de contact
export const getAllMessagesContactForm = async () => {
  try {
    const response = await axios.get(`${apiUrl}/AllMessagesContactForm`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des messages:", error);
  }
};

// Fonction pour récupérer le nombre de messages non lus du formulaire de contact
export const getUnreadMessagesCountContactForm = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/countUnreadMessagesContactForm`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.count;
  } catch (error) {
    console.error(
      "Erreur lors du chargement du nombre de messages non lus:",
      error
    );
    return 0;
  }
};

// Fonction pour sauvegarder un message du formulaire de contact
export const saveMessageContactForm = async (formData) => {
  try {
    await axios.post(`${apiUrl}/sendMessageContactForm`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Message sauvegardé avec succès");
  } catch (error) {
    console.log("formData:", formData);
    console.error("Erreur lors de la sauvegarde du message:", error);
  }
};

/// PATCH
// Fonction pour marquer une contactForm comme lu
export const markMessageContactForm = async (id) => {
  try {
    await axios.patch(
      `${apiUrl}/toggleReadContactForm/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du message ContactForm:",
      error
    );
  }
};

// Fonction pour marquer toutes les contactForm comme lus
export const markAllMessageContactForm = async () => {
  try {
    await axios.patch(
      `${apiUrl}/toggleAllReadContactForm`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de tous les messages ContactForm:",
      error
    );
  }
};

/// DELETE
// Fonction pour supprimer un message du formulaire de contact
export const deleteMessageContactForm = async (messageId) => {
  try {
    await axios.delete(`${apiUrl}/deleteMessageContactForm/${messageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Message supprimé avec succès");
  } catch (error) {
    console.error("Erreur lors de la suppression du message:", error);
  }
};
