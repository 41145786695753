import React, { useEffect, useState } from "react";
import { getActeursByUser } from "./../../services/userServices";
import { useUser } from "./../../contexts/UserContext";
import Card from "./Card";

const InscriptionFiche = ({ acteurs, fetchActeurs }) => {
  const { user } = useUser();
  const userId = user?.userId;

  useEffect(() => {
    fetchActeurs();
  }, [userId]);

  return (
    <div className="flex flex-col items-center">
      {acteurs.length > 0 ? (
        acteurs.map((acteur, index) => (
          <Card key={index} acteur={acteur} fetchActeurs={fetchActeurs} />
        ))
      ) : (
        <p>Aucun acteur trouvé</p>
      )}
    </div>
  );
};

export default InscriptionFiche;
