import React, { useState, useEffect } from 'react';
import VieClubCard from './VieClubCard';
import { useUser } from '../../contexts/UserContext';
import useScrollToTop from '../../hooks/useScrollToTop';
import ScrollIndicator from './../../hooks/ScrollIndicator';
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import { getAllArticles } from '../../services/vieclubServices';
import CreateArticleModal from './CreateArticleModal';
import Button from '../../components/Button/Button';
import { FaPlus } from 'react-icons/fa';

const VieClubPage = () => {
  useScrollToTop();
  const [articles, setArticles] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { user } = useUser();
  const userRole = user ? user.role_id : 'Visiteur';

  useEffect(() => {
    const fetchArticles = async () => {
      const fetchedArticles = await getAllArticles();
      setArticles(fetchedArticles);
    };

    fetchArticles();
  }, []);

  const refreshArticlesList = async () => {
    const fetchedArticles = await getAllArticles();
    setArticles(fetchedArticles);
  };

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        La Vie des Clubs
      </h2>
      <InfoBubble title="Bienvenue sur la page La Vie des Clubs">
        <p>Sur cette page, les responsables des clubs girondins peuvent écrire de petits articles pour partager un événement particulier concernant leur club, des résultats sportifs, des événements à venir ...  </p>
      </InfoBubble>

      {(userRole === 1 || userRole === 2 || userRole === 5) && (
        <div className="flex justify-center mb-4">
          <Button text="Ajouter un Article" onClick={openCreateModal} icon={FaPlus} className="bg-green-500" />
        </div>
      )}

      <div className="overflow-x-auto mx-auto grid grid-cols-1 gap-4 p-4 max-w-[1280px]">
        {articles.map((article, index) => (
          <VieClubCard
            key={article.id}
            article={article}
            index={index}
            userRole={userRole}
            refreshArticlesList={refreshArticlesList}
            user={user}
          />
        ))}
      </div>

      {createModalOpen && (
        <CreateArticleModal
          isOpen={createModalOpen}
          onClose={closeCreateModal}
          refreshList={refreshArticlesList}
        />
      )}
    </div>
  );
};

export default VieClubPage;
