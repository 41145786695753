import React, { useState } from "react";
import { FaEdit, FaTrashAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { deleteAnnonce } from "./../../services/annoncesServices"; // Assurez-vous que le chemin est correct
import { useUser } from "./../../contexts/UserContext";
import Button from "./../../components/Button/Button";
import EditAnnonceModal from "./EditAnnonceModal"; // Assurez-vous que le chemin est correct

const AnnonceCard = ({ annonce, fetchAnnonces }) => {
  const { user } = useUser();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDeleteAnnonce = async () => {
    try {
      await deleteAnnonce(annonce.id);
      fetchAnnonces();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'annonce:", error);
    }
  };

  const handleEdit = () => setIsEditModalOpen(true);
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchAnnonces();
  };

  const getUserInfoFromLocalStorage = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo || {};
  };

  const { role_id, userId } = getUserInfoFromLocalStorage();

  const canModify =
    role_id === 1 || role_id === 2 || userId === annonce.user_id;

  const cardBackgroundClass = annonce.is_active ? "bg-white" : "bg-gray-400";
  const imageUrl = annonce.image_url
    ? annonce.image_url
    : "https://res.cloudinary.com/dw2x1voyb/image/upload/v1721468236/illustration_petite_annonce_cnvse7.webp";

  return (
    <div
      className={`flex flex-col md:flex-row ${cardBackgroundClass} bg-opacity-80 rounded-lg overflow-hidden shadow-md relative m-4`}
    >
      <div className="relative w-full md:w-1/2">
        <img
          src={imageUrl}
          alt={annonce.title}
          className="object-cover rounded-t-lg md:rounded-tr-none md:rounded-l-lg max-h-72 w-full h-full"
        />
        <div className="absolute top-0 left-0 bg-yellow-500 text-white text-xs font-bold p-1 rounded-br-lg">
          {annonce.category}
        </div>
      </div>
      <div className="p-4 flex flex-col justify-between w-full md:w-1/2">
        <div>
          <h3 className="text-lg font-bold uppercase text-gray-800 my-2 w-full font-montserrat">
            {annonce.title}
          </h3>
          <h5 className="text-xs mb-3 text-black bg-backgroundMain w-full bg-gray-200 p-2">
            {annonce.user.prenom} {annonce.user.nom} - {annonce.club_user}
          </h5>
          <div
            className="text-gray-600 italic text-justify w-full mb-4"
            dangerouslySetInnerHTML={{ __html: annonce.content }}
          />

          <p className="text-gray-500 text-xs mb-2">
            Publié le:{" "}
            {new Date(annonce.date_annonce).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <div className="flex flex-row gap-2">
            {annonce.tel_user && (
              <a
                href={`tel:${annonce.tel_user}`}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded flex items-center text-xs"
              >
                <FaPhoneAlt className="mr-2" /> Appeler
              </a>
            )}
            {annonce.mail_user && (
              <a
                href={`mailto:${annonce.mail_user}`}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded flex items-center text-xs"
              >
                <FaEnvelope className="mr-2" /> Envoyer un mail
              </a>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between mt-4">
          <div className="flex gap-2">
            {canModify && (
              <>
                <button
                  onClick={handleEdit}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={handleDeleteAnnonce}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  <FaTrashAlt />
                </button>
              </>
            )}
          </div>
        </div>
        <EditAnnonceModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          fetchAnnonces={fetchAnnonces}
          annonce={annonce}
        />
      </div>
    </div>
  );
};

export default AnnonceCard;
