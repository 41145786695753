import React, { useState } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { createReglementArbitrage } from "../../services/reglementarbitrageServices";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { toast } from "react-toastify";

const CreateReglementArbitrageModal = ({ isOpen, onClose, onCreateSuccess }) => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    lien_url: "",
    lien_url_image: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      content: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createReglementArbitrage(formData);
      onCreateSuccess();
      toast.success("Règlement créé avec succès.");
    } catch (error) {
      console.error("Erreur lors de la création du règlement:", error);
      toast.error("Erreur lors de la création du règlement.");
    }
  };

  const handleCancel = () => {
    onClose();
    setFormData({ title: "", content: "", lien_url: "" });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg dark:bg-gray-400">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Ajouter un Règlement</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="title"
            placeholder="Titre du règlement"
            value={formData.title}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <ReactQuill
            value={formData.content}
            onChange={handleContentChange}
            className="mb-3"
          />
          <input
            type="url"
            name="lien_url"
            placeholder="URL du règlement"
            value={formData.lien_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <input
            type="url"
            name="lien_url_image"
            placeholder="URL de l'image du règlement"
            value={formData.lien_url_image}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={handleCancel} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateReglementArbitrageModal;
