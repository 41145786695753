// InfoModal.js
import React from "react";
import { FaTimes } from "react-icons/fa";

const InfoModal = ({ isOpen, onClose, acteur }) => {
  if (!isOpen) return null;

  const {
    nom,
    prenom,
    email,
    tel,
    adresse,
    cp,
    commune,
    genre,
    date_naissance,
    club_acteur,
    licence_number,
    groupe_souhaite,
  } = acteur;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-bold">Informations de l'acteur</h2>
          <button onClick={onClose} className="text-red-500">
            <FaTimes />
          </button>
        </div>
        <div className="p-4">
          <p>Nom Prénom : {nom} {prenom}</p>
          <p>Email : {email}</p>
          <p>Téléphone : {tel}</p>
          <p>Adresse : {adresse}</p>
          <p>CP : {cp}</p>
          <p>Commune : {commune}</p>
          <p>Genre : {genre}</p>
          <p>
            Date de Naissance:{" "}
            {new Date(date_naissance).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p>Club : {club_acteur}</p>
          <p>Licence : {licence_number}</p>
          <p>Groupes Souhaités : {groupe_souhaite}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
