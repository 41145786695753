import React, { useState, useEffect } from 'react';
import { FaTimes, FaEdit, FaTrash } from 'react-icons/fa';
import { getOneClubGirondin, deleteClubGirondin } from './../../services/clubsgirondinsServices';
import Button from './../../components/Button/Button';
import EditClubModal from './EditClubModal';
import { useUser } from './../../contexts/UserContext';
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import { toast } from 'react-toastify';

const ClubDetailModal = ({ isOpen, onClose, clubId, refreshList }) => {
  const [club, setClub] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  useEffect(() => {
    const fetchClubDetails = async () => {
      try {
        const clubDetails = await getOneClubGirondin(clubId);
        setClub(clubDetails);
      } catch (error) {
        console.error('Erreur lors du chargement des détails du club', error);
      }
    };

    if (isOpen) {
      fetchClubDetails();
    }
  }, [isOpen, clubId]);

  const handleDeleteClub = async () => {
    try {
      await deleteClubGirondin(clubId);
      refreshList();
      onClose();
      toast.success("Le club a bien été supprimé.");
    } catch (error) {
      console.error("Failed to delete club:", error);
      toast.error("Une erreur est survenue lors de la suppression du club.");
    }
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    refreshList();
  };

  if (!isOpen || !club) return null;

  return (
    <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Détails du Club</h3>
        <div className="text-left">
          <p className="text-sm mb-2"><strong>Nom du Club:</strong> {club.nom_club}</p>
          <p className="text-sm mb-2"><strong>Commune:</strong> {club.commune}</p>
          <p className="text-sm mb-2"><strong>Secteur:</strong> {club.secteur}</p>
          <p className="text-sm mb-2"><strong>Secteur Géographique:</strong> {club.secteur_geo}</p>
          <p className="text-sm mb-2"><strong>Numéro du Club:</strong> {club.numero_club}</p>
          <p className="text-sm mb-2"><strong>Téléphone:</strong> {club.tel}</p>
          <p className="text-sm mb-2"><strong>Email:</strong> <a href={`mailto:${club.mail}`} className="text-blue-500">{club.mail}</a></p>
          <p className="text-sm mb-2"><strong>Président:</strong> {club.president}</p>
          <p className="text-sm mb-2"><strong>Enseignant(s):</strong> {club.enseignant}</p>
          <p className="text-sm mb-2"><strong>Adresse:</strong> {club.adresse}</p>
          {club.site_internet && <p className="text-sm mb-2"><strong>Site Internet:</strong> <a href={club.site_internet} target="_blank" rel="noopener noreferrer" className="text-blue-500">{club.site_internet}</a></p>}
          {club.reseaux_sociaux && <p className="text-sm mb-2"><strong>Réseaux Sociaux:</strong> <a href={club.reseaux_sociaux} target="_blank" rel="noopener noreferrer" className="text-blue-500">{club.reseaux_sociaux}</a></p>}
          {club.lien_maps && <p className="text-sm mb-2"><strong>Localisation:</strong> <a href={club.lien_maps} target="_blank" rel="noopener noreferrer" className="text-blue-500">Voir sur Google Maps</a></p>}
          <p className="text-sm mb-2"><strong>Disciplines:</strong> {club.disciplines}</p>
        </div>
        <div className="flex justify-center space-x-2 mt-4">
          <Button text="Fermer" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          {(userRole === "Administrateur" || userRole === "Bureau") && (
            <>
              <Button text="Modifier" icon={FaEdit} onClick={handleOpenEditModal} type="button" className="btn-primary gap-2" />
              <Button text="Supprimer" icon={FaTrash} onClick={handleDeleteClub} type="button" className="btn-danger gap-2" />
            </>
          )}
        </div>
        {isEditModalOpen && <EditClubModal isOpen={isEditModalOpen} onClose={handleCloseEditModal} club={club} refreshList={refreshList} />}
      </div>
    </div>
  );
};

export default ClubDetailModal;
