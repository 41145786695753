import React, { useState, useEffect } from "react";
import { getSettings } from "../../services/settingsServices";

const LogoSection = () => {
  const [settings, setSettings] = useState({ nom_comite: "", shortname_comite: "", logo_comite: "" });

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
      }
    };

    fetchSettings();
  }, []);

  const clubName = window.innerWidth < 400 ? settings.shortname_comite : settings.nom_comite;

  return (
    <div className="flex items-center">
      {/* Taille ajustée pour mobile avec h-8 et sm:h-12 et lg:h-16 pour les écrans plus larges */}
      <img
        className="h-8 sm:h-12 lg:h-16"
        src={settings.logo_comite || "/img/logo_club.png"}
        alt="Logo du club"
      />
      {/* Tailles de texte ajustées pour mobile avec text-sm et sm:text-xl et lg:text-2xl pour les écrans plus larges */}
      <div className="ml-2 text-sm sm:text-xl lg:text-2xl font-bold text-gray-800 uppercase">
        {clubName || "Association"}
      </div>
    </div>
  );
};

export default LogoSection;