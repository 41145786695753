import React, { useState, useEffect } from "react";
import {
  getSettings,
  updateSettings,
  updateDefaultImageLicence, // Importer la fonction d'upload d'image
} from "../../services/settingsServices";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa";

const PaiementSettingsForm = () => {
  const [settings, setSettings] = useState({
    max_payments: 3,
    payment_interval: 1,
    photo_url_default_lic_cot: "", // Ajouter le champ pour l'URL de la photo par défaut
  });
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
      setLoading(false);
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadModal = () => {
    setIsUploading(true); // Ouvrir la modale pour le changement de photo
  };

  const handleCloseModal = () => {
    setIsUploading(false); // Fermer la modale de changement de photo
    setSelectedFile(null); // Réinitialiser le fichier sélectionné
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSettings(settings);
      toast.success("Paramètres mis à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des paramètres");
    }
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateDefaultImageLicence(selectedFile, token);
      setSettings((prevSettings) => ({
        ...prevSettings,
        photo_url_default_lic_cot: imageUrl,
      }));
      toast.success("Image par défaut mise à jour avec succès!");
      handleCloseModal(); // Fermer la modale après le succès
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      toast.error("Erreur lors de l'upload de l'image.");
    } finally {
      setIsUploading(false);
    }
  };

  if (loading) return <div>Chargement...</div>;

  const renderImageCard = (title, imageUrl, onChangeImage, isUploading, handleUploadSubmit, handleFileChange) => (
    <div className="bg-gray-200 shadow-md rounded-lg p-4 flex items-center mb-4">
      <div className="flex-1">
        <label className="text-sm font-medium text-gray-700 mb-1 block">{title}</label>
        <input
          type="text"
          value={imageUrl}
          readOnly
          className="input input-bordered w-full mb-2"
        />
        <Button text="Changer l'image" onClick={onChangeImage} className="mt-2" />
      </div>
      <div className="ml-4">
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Aperçu"
            className="w-32 h-32 object-cover rounded-md"
          />
        )}
      </div>
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Changer l'image
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={() => onChangeImage(false)}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4">
      <h3 className="text-xl font-semibold mb-6">Paramètres de Paiement (Licence-Assurance & Cotisation)</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Nombre maximum de paiements de la Licence-Assurance & Cotisation
          </label>
          <select
            name="max_payments"
            value={settings.max_payments}
            onChange={handleChange}
            className="input input-bordered w-full"
          >
            {[...Array(5)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Espacement des paiements (en mois)
          </label>
          <select
            name="payment_interval"
            value={settings.payment_interval}
            onChange={handleChange}
            className="input input-bordered w-full"
          >
            {[1, 2, 3].map((interval) => (
              <option key={interval} value={interval}>
                {interval}
              </option>
            ))}
          </select>
        </div>
        {renderImageCard(
          "Image par défaut de l'article Règlement Inscription",
          settings.photo_url_default_lic_cot,
          handleUploadModal,
          isUploading,
          handleUploadSubmit,
          handleFileChange
        )}
        <div className="flex justify-end">
          <Button
            text="Mettre à jour"
            type="submit"
            className="btn-primary gap-2"
          />
        </div>
      </form>
    </div>
  );
};

export default PaiementSettingsForm;
