import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { getCommissionArticles, deleteArticle, toggleArticleState } from '../../services/articlescommissionsServices';
import { getGroupNameById } from '../../services/groupServices';
import { useUser } from '../../contexts/UserContext';
import { getRoleFromRoleId } from './../../utils/roleHelper';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import Button from '../../components/Button/Button';
import ButtonEdit from '../../components/Button/ButtonEdit';
import ButtonDelete from '../../components/Button/ButtonDelete';
import CreateArticleModal from './CreateArticleModal';
import EditArticleModal from './EditArticleModal';
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CommissionArticlesPage = () => {
  useScrollToTop();
  const { id } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const userRole = getRoleFromRoleId(user?.role_id); 
  const [articles, setArticles] = useState([]);
  const [commissionName, setCommissionName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getCommissionArticles(id);
        const filteredArticles = data.filter(article => {
          if (article.group_id === parseInt(id, 10)) {
            if (!article.is_active) {
              return !["Officiels", "Responsables_clubs", "Utilisateurs"].includes(userRole);
            }
            return true;
          }
          return false;
        });
        setArticles(filteredArticles);
      } catch (error) {
        toast.error("Erreur lors de la récupération des articles");
      }
    };

    const fetchCommissionName = async () => {
      try {
        const commission = await getGroupNameById(id);
        setCommissionName(commission.nom);
      } catch (error) {
        toast.error("Erreur lors de la récupération des informations de la commission");
      }
    };

    fetchArticles();
    fetchCommissionName();
  }, [id, user, userRole]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenEditModal = (article) => {
    setCurrentArticle(article);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentArticle(null);
  };

  const handleRefreshList = async () => {
    try {
      const data = await getCommissionArticles(id);
      setArticles(data.filter(article => article.group_id === parseInt(id, 10)));
    } catch (error) {
      toast.error("Erreur lors de la récupération des articles");
    }
  };

  const handleDeleteArticle = async (articleId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
      try {
        await deleteArticle(articleId);
        toast.success("Article supprimé avec succès.");
        handleRefreshList();
      } catch (error) {
        toast.error("Erreur lors de la suppression de l'article");
      }
    }
  };

  const handleToggleArticle = async (article) => {
    try {
      await toggleArticleState(article.id, !article.is_active);
      toast.success(`L'article a été ${article.is_active ? "désactivé" : "activé"} avec succès.`);
      handleRefreshList(); 
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de l'état de l'article.");
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  // Vérifier si l'utilisateur a le droit d'éditer ou de supprimer un article
  const canEditOrDelete = (article) => {
    return (
      user?.id === article.user_id || 
      ["Administrateur", "Bureau", "Membres", "Membres_Arbitres"].includes(userRole)
    ) && 
    !["Officiels", "Responsables_clubs", "Utilisateurs"].includes(userRole);  // Empêche ces rôles d'éditer ou de supprimer
  };

  const canSeeTag = () => {
    return !["Officiels", "Responsables_clubs", "Utilisateurs"].includes(userRole);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ARTICLES COMMISSIONS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        {commissionName}
      </h2>
      <div className='flex justify-center space-x-2 mb-4'>
      {user && (["Administrateur", "Bureau", "Membres", "Membres_Arbitres"].includes(userRole)) && (
        <div>
          <Button text="Créer un Article" onClick={handleOpenModal} />
        </div>
      )}
      <Button
      text="Retour"
      onClick={() => navigate(-1)}
      className=""
      />
      </div>
      <CreateArticleModal isOpen={isModalOpen} onClose={handleCloseModal} refreshList={handleRefreshList} groupId={id} />
      <EditArticleModal isOpen={isEditModalOpen} onClose={handleCloseEditModal} refreshList={handleRefreshList} article={currentArticle} />
      <div className="py-4">
        {articles.length > 0 ? (
          <div className="space-y-8 max-w-[1280px] mx-auto">
            {articles.map(article => {
              const images = [article.photo_url1, article.photo_url2, article.photo_url3].filter(Boolean);
              const hasImages = images.length > 0;
              return (
                <div key={article.id} className="relative flex flex-col md:flex-row mb-6 shadow-lg bg-slate-200 dark:bg-slate-400 rounded-lg overflow-hidden w-full">
                  {canSeeTag() && (
                    <div className={`absolute top-2 right-2 px-4 py-2 rounded text-white text-sm font-bold ${article.is_active ? 'bg-green-500' : 'bg-orange-500'}`}>
                      {article.is_active ? 'Diffusée' : 'Non Diffusée'}
                    </div>
                  )}
                  <div className="md:w-1/2 w-full">
                    {hasImages ? (
                      images.length > 1 ? (
                        <Slider {...settings}>
                          {images.map((image, index) => (
                            <div key={index} className="w-full h-full">
                              <img src={image} alt={`Slide ${index + 1}`} className="w-full h-full object-contain" />
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <img src={images[0]} alt="Article cover" className="w-full h-full object-contain" />
                      )
                    ) : (
                      <img src="/img/default_image.jpg" alt="Default article cover" className="w-full h-full object-contain" />
                    )}
                  </div>
                  <div className="md:w-1/2 p-4 flex flex-col justify-between">
                    <div>
                      <h3 className="text-gray-600 font-bold text-xl dark:text-white">{article.titre}</h3>
                      <p className="text-gray-600 mt-2 mb-4 dark:text-slate-200" dangerouslySetInnerHTML={{ __html: article.contenu }}></p>
                      <p className="text-gray-500 dark:text-slate-200 text-xs">Publié le: {new Date(article.date_article).toLocaleDateString()} par la {commissionName}</p>
                      {!article.is_active && user?.id === article.user_id && (
                        <p className="text-yellow-600 mt-2 font-semibold">
                          Cet article est en attente de validation par le Bureau. Une notification a été envoyée.
                        </p>
                      )}
                    </div>
                    {canEditOrDelete(article) && (
                      <div className="flex gap-2 mt-4">
                        <ButtonEdit onClick={() => handleOpenEditModal(article)} />
                        <ButtonDelete onClick={() => handleDeleteArticle(article.id)} />
                        {["Administrateur", "Bureau"].includes(userRole) && (
                          <Button
                            text={article.is_active ? "Désactiver" : "Activer"}
                            onClick={() => handleToggleArticle(article)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p>Aucun article trouvé pour cette commission.</p>
        )}
      </div>
    </div>
  );
};

export default CommissionArticlesPage;
