import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../components/Button/Button";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import { getAllResults, deleteActorFromEvent } from "../../services/acteureventServices";
import ButtonEdit from "../../components/Button/ButtonEdit";
import ButtonDelete from "../../components/Button/ButtonDelete";
import formatDate from './../../utils/formatDate';
import NoteModal from "./../../pages/ConvocationsAdminPage/NoteModal"; // Assurez-vous que le chemin est correct
import ObservationsCell from "./ObservationsCell"; // Assurez-vous que le chemin est correct

const NotationPage = () => {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchPoste, setSearchPoste] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useScrollToTop();

  const fetchResults = async () => {
    const data = await getAllResults();
    setResults(data);
    setFilteredResults(data);
  };

  useEffect(() => {
    fetchResults();
  }, []);

  useEffect(() => {
    filterResults();
  }, [searchName, searchPoste, results]);

  const filterResults = () => {
    let filtered = results;

    if (searchName) {
      filtered = filtered.filter(result =>
        `${result.Acteur.nom} ${result.Acteur.prenom}`
          .toLowerCase()
          .includes(searchName.toLowerCase())
      );
    }

    if (searchPoste) {
      filtered = filtered.filter(result =>
        result.poste && result.poste.toLowerCase().includes(searchPoste.toLowerCase())
      );
    }

    setFilteredResults(filtered);
  };

  const handleEdit = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleDelete = async (acteurId, eventId) => {
    try {
      await deleteActorFromEvent(acteurId, eventId);
      toast.success("Acteur supprimé de l'événement avec succès.");
      fetchResults();
    } catch (error) {
      toast.error("Erreur lors de la suppression de l'acteur.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Notes des Officiels
      </h2>

      <div className="w-full max-w-[1280px] mx-auto">
        <input
          type="text"
          placeholder="Recherche par nom"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className="input input-bordered mt-4 mb-4 w-full"
        />
        <div className="mb-4">
          <select
            onChange={(e) => setSearchPoste(e.target.value)}
            className="input input-bordered w-full mb-4"
          >
            <option value="">Sélectionner un poste</option>
            <option value="Organisateur">Organisateur</option>
            <option value="Responsable Comité">Responsable Comité</option>
            <option value="Bénévolontaire">Bénévolontaire</option>
            <option value="Responsable Arbitrage">Responsable Arbitrage</option>
            <option value="Notateur">Notateur</option>
            <option value="Arbitre">Arbitre</option>
            <option value="Commissaire Sportif">Commissaire Sportif</option>
            <option value="Juge">Juge</option>
          </select>
        </div>
      </div>

      <div className="overflow-x-auto shadow-xl">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Nom et Prénom</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Date de l'événement</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Nom de l'événement</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Poste</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Note</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Observations</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredResults.map((result) => (
              <tr key={result.id}>
                <td className="border px-4 py-2">
                  {result.Acteur.nom} {result.Acteur.prenom}
                </td>
                <td className="border px-4 py-2">{formatDate(result.Event.start)}</td>
                <td className="border px-4 py-2">{result.Event.titre}</td>
                <td className="border px-4 py-2">{result.poste}</td>
                <td className="border px-4 py-2">{result.note}</td>
                <td className="border px-4 py-2">
                  <ObservationsCell observations={result.observations} />
                </td>
                <td className="flex items-center gap-2">
                  <ButtonEdit id={result.id} onClick={() => handleEdit(result)} className="" hoverLabel="Éditer" />
                  <ButtonDelete id={result.id} onClick={() => handleDelete(result.Acteur.id, result.Event.id)} className="" hoverLabel="Supprimer" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedEvent && (
        <NoteModal
          isOpen={isModalOpen}
          onClose={closeModal}
          actorEvent={selectedEvent}
          fetchActors={fetchResults}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default NotationPage;
