import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAnnonces } from "../../services/annoncesServices";
import AnnonceCard from "../AnnoncesPage/AnnonceCard";
import Button from "../../components/Button/Button";
import { useUser } from "../../contexts/UserContext";

const LastAnnoncesClub = () => {
  const [annonces, setAnnonces] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchAnnonces = async () => {
      try {
        const response = await getAnnonces();
        const lastAnnonces = response.filter(annonce => annonce.is_active).slice(0, 2);
        setAnnonces(lastAnnonces);
      } catch (error) {
        console.error("Failed to fetch annonces", error);
      }
    };

    fetchAnnonces();
  }, []);

  const handleViewMore = () => {
    navigate("/petites-annonces");
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-3xl font-extrabold text-center mt-2 font-montserrat uppercase">
        Dernières Petites Annonces
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
        {annonces.map((annonce) => (
          <AnnonceCard
            key={annonce.id}
            annonce={annonce}
            fetchAnnonces={() => {}} // Pas de rafraîchissement des annonces dans ce contexte
          />
        ))}
      </div>
      {user && (
        <Button
          text="Voir plus d'annonces"
          onClick={handleViewMore}
          className="self-center mt-4"
        />
      )}
    </div>
  );
};

export default LastAnnoncesClub;
