import React, { useState, useEffect } from "react";
import { getAllEvents } from "./../../services/eventServices";
import EventCardUser from "./EventCardUser";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import EventDetailsModal from "./EventDetailsModal";
import { useUser } from "../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import { FaTh, FaList } from "react-icons/fa";
import EventTableUser from "./EventTableUser";
import Button from "./../../components/Button/Button";

const CalendarPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterLevel, setFilterLevel] = useState("");
  const [filterStatus, setFilterStatus] = useState("A Venir");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState("cards");

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await getAllEvents();
        setEvents(data.filter((event) => event.is_active)); // Filtrer pour garder seulement les événements actifs
        console.log(userRole);
      } catch (error) {
        console.error("Erreur lors du chargement des événements", error);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  // Liste des types d'événements (à ajuster selon vos données)
  const typeEvents = [
    "Compétition",
    "Animation",
    "Stage & Regroupement Technique",
    "Passage de Grade",
    "Entraînement Départemental",
    "Stage Sportif Départemental",
    "Tournoi Interclubs",
    "Cours commun Club",
    "Stage Club",
    "Autre",
  ];

  // Liste des niveaux d'événements (à ajuster selon vos données)
  const levelEvents = [
    "Club",
    "Départemental",
    "Régional",
    "National",
    "International",
  ];

  // Liste des catégories d'événements
  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  // Définir les statuts basés sur la date
  const statusEvents = ["A Venir", "Passé"];

  const filteredEvents = events.filter((event) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour la comparaison de date seulement

    const eventStartDate = new Date(event.start);
    eventStartDate.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour la comparaison de date seulement

    const isPast = eventStartDate < today;
    const isTodayOrFuture = eventStartDate >= today;

    const statusFilter =
      filterStatus === "A Venir"
        ? isTodayOrFuture
        : filterStatus === "Passé"
        ? isPast
        : true; // Si filterStatus n'est pas "A Venir" ou "Passé", tous les événements sont valides

    const categories = event.cate_event ? event.cate_event.split(",") : [];
    const categoryFilter = filterCategory
      ? categories.includes(filterCategory)
      : true;

    return (
      event.titre.toLowerCase().includes(searchTerm.toLowerCase()) &&
      categoryFilter &&
      (filterType ? event.type_event === filterType : true) &&
      (filterLevel ? event.level_event === filterLevel : true) &&
      statusFilter
    );
  });

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Calendrier
      </h2>
      <InfoBubble title="Bienvenue sur la page Calendrier">
        <p>
          Sur cette page, vous pouvez consulter tous les événements départementaux
          disponibles. Vous pouvez filtrer les événements par catégorie d'âge,
          type, niveau et recherche par titre.
        </p>
      </InfoBubble>

      <div className="flex justify-between items-center max-w-[1280px] mx-auto">
        <div className="flex flex-wrap justify-center items-middle mb-4 gap-4">
          <input
            type="text"
            placeholder="Recherche par titre"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input input-bordered w-full"
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
            <select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              className="select select-bordered w-full dark:text-slate-800"
            >
              <option value="">Toutes catégories</option>
              {cateEvents.map((cate) => (
                <option key={cate} value={cate}>
                  {cate}
                </option>
              ))}
            </select>
            <select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="select select-bordered w-full dark:text-slate-800"
            >
              <option value="">Tous types</option>
              {typeEvents.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <select
              value={filterLevel}
              onChange={(e) => setFilterLevel(e.target.value)}
              className="select select-bordered w-full dark:text-slate-800"
            >
              <option value="">Tous niveaux</option>
              {levelEvents.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="select select-bordered w-full dark:text-slate-800"
            >
              <option value="">Tous statuts</option>
              {statusEvents.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <div className="flex space-x-2">
            <Button
              text="Vue en cartes"
              onClick={() => setViewMode("cards")}
              icon={FaTh}
              className="w-sm gap-2 text-sm"
            />

            <Button
              text="Vue en tableau"
              onClick={() => setViewMode("table")}
              icon={FaList}
              className="w-sm gap-2 text-sm"
            />
          </div>
        </div>
      </div>

      {viewMode === "cards" ? (
        <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-[1280px] mx-auto">
          {filteredEvents.map((event) => (
            <EventCardUser
              key={event.id}
              event={event}
              onClick={() => handleEventClick(event)}
              userRole={userRole}
            />
          ))}
        </div>
      ) : (
        <EventTableUser
          events={filteredEvents}
          onEventClick={handleEventClick}
          userRole={userRole}
        />
      )}

      <EventDetailsModal
        event={selectedEvent}
        isOpen={isModalOpen}
        onClose={closeModal}
        userRole={userRole}
      />
    </div>
  );
};

export default CalendarPage;
