import React, { useState, useEffect } from 'react';
import PartenaireKataCard from './PartenaireKataCard';
import { useUser } from '../../contexts/UserContext';
import useScrollToTop from '../../hooks/useScrollToTop';
import ScrollIndicator from './../../hooks/ScrollIndicator';
import { getPartenairesKata } from '../../services/partenaireskataServices';
import CreatePartenaireModal from './CreatePartenaireModal';
import Button from '../../components/Button/Button';
import { FaPlus } from 'react-icons/fa';

const PartenairesKataPage = () => {
  useScrollToTop();
  const [partenaires, setPartenaires] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { user } = useUser();
  const userRole = user ? user.role_id : 'Visiteur';

  useEffect(() => {
    const fetchPartenaires = async () => {
      const fetchedPartenaires = await getPartenairesKata();
      setPartenaires(fetchedPartenaires);
    };

    fetchPartenaires();
  }, []);

  const refreshPartenairesList = async () => {
    const fetchedPartenaires = await getPartenairesKata();
    setPartenaires(fetchedPartenaires);
  };

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Partenaires Kata
      </h2>

      {(userRole === 1 || userRole === 2 || userRole === 5) && (
        <div className="flex justify-center mb-4 gap-2">
          <Button text="Ajouter un Partenaire" onClick={openCreateModal} icon={FaPlus} className="space-x-2 gap-2" />
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-full p-4">
        {partenaires.map((partenaire, index) => (
          <PartenaireKataCard
            key={partenaire.id}
            partenaire={partenaire}
            index={index}
            userRole={userRole}
            refreshPartenairesList={refreshPartenairesList}
            user={user}
          />
        ))}
      </div>

      {createModalOpen && (
        <CreatePartenaireModal
          isOpen={createModalOpen}
          onClose={closeCreateModal}
          refreshList={refreshPartenairesList}
        />
      )}
    </div>
  );
};

export default PartenairesKataPage;
