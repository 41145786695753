import React, { useState, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { deleteEvent } from "../../services/eventServices";
import { useUser } from "../../contexts/UserContext";
import { getUserById } from "../../services/userServices";
import EditEventModal from "./EditEventModal";
import ButtonEdit from "../../components/Button/ButtonEdit";
import ButtonDelete from "../../components/Button/ButtonDelete";
import ButtonCalendar from "../../components/Button/ButtonCalendar";
import { getRoleFromRoleId } from "../../utils/roleHelper";

const EventCard = ({ event, fetchEvents }) => {
  const { user } = useUser();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [eventUser, setEventUser] = useState(null);

  useEffect(() => {
    const fetchEventUser = async () => {
      if (event.user_id) {
        const fetchedUser = await getUserById(event.user_id);
        setEventUser(fetchedUser);
      }
    };

    fetchEventUser();
  }, [event.user_id]);

  const handleDeleteEvent = async () => {
    try {
      await deleteEvent(event.id);
      fetchEvents();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement:", error);
    }
  };

  const handleEdit = () => setIsEditModalOpen(true);
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchEvents();
  };

  const cardBackgroundClass = event.is_active ? "bg-white" : "bg-gray-400";
  const imageUrl = event.photo_url ? event.photo_url : "./img/default_image.jpg";

  const getTypeColor = (type) => {
    switch (type) {
      case "Cours commun Club":
        return "bg-yellow-500";
      case "Stage Club":
        return "bg-green-500";
      case "Tournoi Interclubs":
        return "bg-blue-500";
      default:
        return "bg-gray-500";
    }
  };

  const eventEndDate = new Date(event.end);
  const isEndDateValid = !isNaN(eventEndDate);

  // Ajout de l'accès au rôle de l'utilisateur
  const userRole = user ? getRoleFromRoleId(user.role_id) : null;

  // Modification de la condition canEditOrDelete
  const canEditOrDelete = user && eventUser && (user.userId === eventUser.id || userRole === "Administrateur" || userRole === "Bureau");

  return (
    <div
      className={`flex flex-col md:flex-row ${cardBackgroundClass} bg-opacity-80 rounded-lg overflow-hidden shadow-md relative m-4`}
    >
      <div className="relative w-full md:w-1/2 h-full">
        <img
          src={imageUrl}
          alt={event.titre}
          className="object-cover w-full h-full"
        />
        <div className={`absolute px-2 top-0 left-0 ${getTypeColor(event.type_event)} text-white text-xs p-1 rounded-br-lg`}>
          {event.type_event}
        </div>
      </div>
      <div className="p-2 flex flex-col justify-between w-full md:w-1/2">
        <div>
          <h3 className="font-bold uppercase text-gray-800 bg-gray-300 p-1 w-full font-montserrat">
            {event.titre}
          </h3>
          {!event.is_active && (
            <p className="text-gray-200 bg-black p-1 text-xs text-center">
              Cet événement doit être validé par l'administrateur
            </p>
          )}
          <div
            className="text-gray-600 text-xs text-justify w-full mb-4"
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
          <p className="text-gray-500 text-xs mb-2">
            Date de début:{" "}
            {new Date(event.start).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          {isEndDateValid && (
            <p className="text-gray-500 text-xs mb-2">
              Date de fin:{" "}
              {eventEndDate.toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          )}
          <p className="text-gray-500 text-xs mb-2">
            Lieu: {event.lieu_event}
          </p>
          <div
            className="text-gray-500 text-xs mb-2"
            dangerouslySetInnerHTML={{ __html: event.horaire_event }}
          />
          
          <div className="flex flex-wrap gap-1 mt-2">
            {event.cate_event.split(",").map((cate, index) => (
              <span key={index} className="bg-black text-white text-xs px-2 py-1 rounded-full">
                {cate}
              </span>
            ))}
          </div>
        </div>
        <div className="flex flex-row justify-between mt-4">
          <div className="flex gap-2 items-center">
            {event.agenda_url && (
              <a href={event.agenda_url} target="_blank" rel="noopener noreferrer">
                <ButtonCalendar />
              </a>
            )}
            {canEditOrDelete && (
              <>
                <ButtonEdit
                  onClick={handleEdit}
                />
                <ButtonDelete
                  onClick={handleDeleteEvent}
                />
              </>
            )}
          </div>
        </div>
        <EditEventModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          fetchEvents={fetchEvents}
          event={event}
        />
        {eventUser && (
          <div className=" text-gray-800 bg-gray-300 p-2 text-xs mt-2">
            Évènement proposé par {eventUser.prenom} {eventUser.nom}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
