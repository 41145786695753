import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../components/Button/Button';
import { updateEvent } from '../../services/eventServices';

const RewardsEditModal = ({ isOpen, onClose, event, refreshList }) => {
  const [formData, setFormData] = useState({
    rapport: '',
    tableaux: '',
    selection: '',
    photos_podiums: ''
  });

  useEffect(() => {
    if (event) {
      setFormData({
        rapport: event.rapport || '',
        tableaux: event.tableaux || '',
        selection: event.selection || '',
        photos_podiums: event.photos_podiums || ''
      });
    }
  }, [event]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedEvent = {
        ...event,
        rapport: formData.rapport,
        tableaux: formData.tableaux,
        selection: formData.selection,
        photos_podiums: formData.photos_podiums
      };
      await updateEvent(updatedEvent);
      refreshList();
      onClose();
      toast.success('Récompenses mises à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour des récompenses', error);
      toast.error('Erreur lors de la mise à jour des récompenses.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier les Récompenses
        </h3>
        <form onSubmit={handleSubmit}>
          <label htmlFor="rapport" className="text-gray-800">
            Rapport
          </label>
          <input
            type="url"
            name="rapport"
            placeholder="URL du rapport"
            value={formData.rapport}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label htmlFor="tableaux" className="text-gray-800">
            Tableaux
          </label>
          <input
            type="url"
            name="tableaux"
            placeholder="URL des tableaux"
            value={formData.tableaux}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label htmlFor="selection" className="text-gray-800">
            Sélection
          </label>
          <input
            type="url"
            name="selection"
            placeholder="URL de la sélection"
            value={formData.selection}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label htmlFor="photos_podiums" className="text-gray-800">
            Photos des Podiums
          </label>
          <input
            type="url"
            name="photos_podiums"
            placeholder="URL des photos des podiums"
            value={formData.photos_podiums}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default RewardsEditModal;
