import React, { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { getRoleFromRoleId } from "../../utils/roleHelper";
import useScrollToTop from "../../hooks/useScrollToTop";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import Button from "../../components/Button/Button";
import { ToastContainer, toast } from "react-toastify";
import { getQuizzVideos } from "../../services/quizzvideoServices";
import QuizzVideoCard from "./QuizzVideoCard";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import Select from 'react-select';

const QuizzVideoPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [quizzVideos, setQuizzVideos] = useState([]);
  const [filteredQuizzVideos, setFilteredQuizzVideos] = useState([]);
  const [family, setFamily] = useState("");

  useEffect(() => {
    const fetchQuizzVideos = async () => {
      const data = await getQuizzVideos();
      setQuizzVideos(data);
    };
    fetchQuizzVideos();
  }, []);

  const handleFamilyChange = (selectedOption) => {
    const familyValue = selectedOption ? selectedOption.value : "";
    setFamily(familyValue);
    filterQuizzByFamily(familyValue);
  };

  const filterQuizzByFamily = (family) => {
    if (family) {
      const filtered = quizzVideos.filter((qv) => qv.famille_quizz === family);
      setFilteredQuizzVideos(filtered);
    } else {
      setFilteredQuizzVideos([]);
    }
  };

  const getRandomQuizz = () => {
    const shuffled = [...quizzVideos].sort(() => 0.5 - Math.random());
    setFilteredQuizzVideos(shuffled.slice(0, 10));
  };

  const familyOptions = [
    { value: '', label: 'Sélectionner une famille' },
    ...[...new Set(quizzVideos.map(qv => qv.famille_quizz))].map(fam => ({
      value: fam,
      label: fam,
    }))
  ];

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        Arbitres & Commissaires Sportifs
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Quizz Vidéo
      </h2>
      <InfoBubble title="Bienvenue sur la page Quizz Vidéo">
        <p>Sur cette page, vous pouvez vous entraîner en visionnant des vidéos d'arbitrage. Vous pouvez sélectionner une famille ou bien tirer au sort 10 quiz.</p>
      </InfoBubble>
      <div className="flex flex-col sm:flex-row justify-center items-center mb-4 gap-4">
        <Select
          value={familyOptions.find(option => option.value === family)}
          onChange={handleFamilyChange}
          options={familyOptions}
          className="w-full max-w-xs"
        />
        <Button text="10 Quiz Aléatoires" onClick={getRandomQuizz} className="ml-0 sm:ml-4 mt-4 sm:mt-0" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 max-w-[1280px] mx-auto mb-4 mt-10">
        {(filteredQuizzVideos.length > 0 ? filteredQuizzVideos : quizzVideos.slice(0, 10)).map((quizz) => (
          <QuizzVideoCard key={quizz.id} quizz={quizz} />
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default QuizzVideoPage;
