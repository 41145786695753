import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/events`;
const token = localStorage.getItem("token");


/// GET
// Fonction pour récupérer tous les événements
export const getAllEvents = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allevents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

// Fonction pour récupérer les événements actifs et départementaux
export const getDeptEvents = async () => {
  try {
    const response = await axios.get(`${apiUrl}/deptevents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
}

// Fonction pour récupérer les evénements clubs
export const getClubEvents = async () => {
  try {
    const response = await axios.get(`${apiUrl}/clubevents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

// Fonction pour afficher uniquement les 3 prochains événements si is-active est true
export const getUpcomingEvents = async () => {
  try {
    const response = await axios.get(`${apiUrl}/nextevents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

/// POST
// Fonction pour créer un événement
export const createEvent = async (event) => {
  try {
    const response = await axios.post(`${apiUrl}/add`, event, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

/// PUT
// Fonction pour modifier un événement
export const updateEvent = async (event) => {
  try {
    const response = await axios.put(`${apiUrl}/update/${event.id}`, event, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {}
}

// Fonction pour toggle le statut is_active de l'événement
export const toggleEventActive = async (eventId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/activeevents/${eventId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du changement de l'état de l'événement:", error);
  }
};


/// DELETE
// Fonction pour supprimer un événement
export const deleteEvent = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};