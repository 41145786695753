import React, { useState, useEffect } from "react";
import { getAnnonces } from "./../../services/annoncesServices"; // Assurez-vous que le chemin est correct
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import useScrollToTop from "./../../hooks/useScrollToTop";
import Button from "./../../components/Button/Button";
import { ToastContainer, toast } from "react-toastify";
import CreateAnnonceModal from "./CreateAnnonceModal"; // Assurez-vous que le chemin est correct
import AnnonceCard from "./AnnonceCard"; // Assurez-vous que le chemin est correct

const AnnoncesPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [annonces, setAnnonces] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [searchTitle, setSearchTitle] = useState(""); // État pour le filtre de recherche par titre
  const [selectedCategory, setSelectedCategory] = useState(""); // État pour le filtre de catégorie

  useEffect(() => {
    fetchAnnonces();
  }, [showAll, searchTitle, selectedCategory]);

  const fetchAnnonces = async () => {
    try {
      const response = await getAnnonces();
      const filteredAnnonces = response.filter((annonce) => {
        return (
          (showAll || annonce.is_active) &&
          annonce.title.toLowerCase().includes(searchTitle.toLowerCase()) &&
          (selectedCategory === "" || annonce.category === selectedCategory)
        );
      });
      setAnnonces(filteredAnnonces);
    } catch (error) {
      console.error("Failed to fetch annonces:", error);
    }
  };

  const handleAddAnnonce = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    fetchAnnonces();
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (!user) {
    return <div>Loading...</div>; // Affiche un message de chargement si user est null
  }

  const categories = ["Catégorie 1", "Catégorie 2", "Catégorie 3"]; // Remplacez par vos catégories réelles

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        RESPONSABLES CLUBS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Petites Annonces
      </h2>
      <div className="overflow-x-auto w-full p-4 max-w-[1280px] mx-auto">
        <div className="flex flex-row mx-auto w-full gap-4 mb-4">
          {(userRole === "Administrateur" ||
            userRole === "Bureau" ||
            userRole === "Responsables_clubs") && (
            <Button
              text="Ajouter une Annonce"
              onClick={handleAddAnnonce}
              className="mb-4"
            />
          )}
        </div>
        <div className="flex flex-row gap-4 mb-4">
          <input
            type="text"
            placeholder="Rechercher par titre"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            className="border p-2 rounded"
          />
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="border p-2 rounded"
          >
            <option value="">Toutes les catégories</option>

            <option value="Recherche Enseignant">Recherche Enseignant</option>
            <option value="Enseignant cherche club">
              Enseignant cherche club
            </option>
            <option value="Recherche Matériel">Recherche Matériel</option>
            <option value="Don Matériel">Don Matériel</option>
            <option value="Autres">Autres</option>
          </select>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
          {annonces.map((annonce) => (
            <AnnonceCard
              key={annonce.id}
              annonce={annonce}
              fetchAnnonces={fetchAnnonces}
            />
          ))}
        </div>
      </div>
      <CreateAnnonceModal
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        userId={user.userId}
        fetchAnnonces={fetchAnnonces}
      />
      <ToastContainer />
    </div>
  );
};

export default AnnoncesPage;
