import React, { useState, useEffect } from "react";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import { getAllEvents, deleteEvent, toggleEventActive, updateEvent } from "./../../services/eventServices"; // Importer updateEvent
import Button from "./../../components/Button/Button";
import ButtonEdit from "./../../components/Button/ButtonEdit";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import { FaPlusSquare, FaInfoCircle, FaTrophy } from "react-icons/fa"; // Importer FaTrophy
import formatDate from "./../../utils/formatDate";
import CreateEventModal from "./CreateEventModal";
import EditEventModal from "./EditEventModal";
import EventDetailsModal from "./../../pages/CalendarPage/EventDetailsModal"; // Importer EventDetailsModal
import RewardsEditModal from "./RewardsEditModal"; // Importer RewardsEditModal
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import { ToastContainer, toast } from "react-toastify";

const EventsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const [events, setEvents] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // État pour EventDetailsModal
  const [isRewardsModalOpen, setIsRewardsModalOpen] = useState(false); // État pour RewardsEditModal
  const [editableEvent, setEditableEvent] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null); // État pour l'événement sélectionné
  const [sortAscending, setSortAscending] = useState(true);
  const [filter, setFilter] = useState("upcoming");
  const [typeFilter, setTypeFilter] = useState("All");
  const [levelFilter, setLevelFilter] = useState("All");
  const [cateFilter, setCateFilter] = useState("All");

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const data = await getAllEvents();
      setEvents(data);
    } catch (error) {
      console.error("Erreur lors du chargement des événements", error);
    }
  };

  const openEditModal = (event) => {
    setEditableEvent(event);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDetailsModal = (event) => { // Fonction pour ouvrir EventDetailsModal
    setSelectedEvent(event);
    setIsDetailsModalOpen(true);
  };

  const closeDetailsModal = () => { // Fonction pour fermer EventDetailsModal
    setIsDetailsModalOpen(false);
    setSelectedEvent(null);
  };

  const openRewardsModal = (event) => { // Fonction pour ouvrir RewardsEditModal
    setEditableEvent(event);
    setIsRewardsModalOpen(true);
  };

  const closeRewardsModal = () => { // Fonction pour fermer RewardsEditModal
    setIsRewardsModalOpen(false);
  };

  const deleteEventFunc = async (id) => {
    try {
      await deleteEvent(id);
      setEvents(events.filter((event) => event.id !== id));
      toast.success("Événement supprimé avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement", error);
      toast.error("Erreur lors de la suppression de l'événement.");
    }
  };

  const toggleSortOrder = () => {
    setSortAscending(!sortAscending);
  };

  const handleToggleActive = async (event) => {
    try {
      const updatedEvent = await toggleEventActive(event.id);
      setEvents((prevEvents) =>
        prevEvents.map((e) =>
          e.id === event.id ? { ...e, is_active: updatedEvent.is_active } : e
        )
      );
    } catch (error) {
      console.error("Erreur lors du changement de l'état de l'événement", error);
      toast.error("Erreur lors du changement de l'état de l'événement.");
    }
  };

  const isPastDate = (date) => {
    return new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const typeEvents = [
    "Compétition",
    "Animation",
    "Stage & Regroupement Technique",
    "Passage de Grade",
    "Entraînement Départemental",
    "Stage Sportif Départemental",
    "Cours commun Club",
    "Stage Club",
    "Tournoi Interclubs",
    "Autre",
  ];

  const levelEvents = ["Club", "Départemental", "Régional", "National", "International"];

  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.start).setHours(0, 0, 0, 0);
    const now = new Date().setHours(0, 0, 0, 0);
    const categories = event.cate_event ? event.cate_event.split(",") : [];
    return (
      (filter === "all" ||
        (filter === "upcoming" && eventDate >= now) ||
        (filter === "past" && eventDate < now)) &&
      (typeFilter === "All" || event.type_event === typeFilter) &&
      (levelFilter === "All" || event.level_event === levelFilter) &&
      (cateFilter === "All" || categories.includes(cateFilter))
    );
  });

  const sortedEvents = filteredEvents.sort((a, b) => {
    const dateA = new Date(a.start),
      dateB = new Date(b.start);
    return sortAscending ? dateA - dateB : dateB - dateA;
  });

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Liste des Événements
      </h2>

      <div className="flex flex-col items-center w-full px-4 mt-4">
        <Button
          label="Ajouter un événement"
          text="Ajouter un événement"
          icon={FaPlusSquare}
          onClick={() => setIsCreateModalOpen(true)}
          className="mb-8 mt-4 gap-2"
        />
        <div className="flex flex-wrap gap-4 w-full font-montserrat text-normal max-w-[800px] mb-4">
          <select
            onChange={handleFilterChange}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="all">Tous</option>
            <option value="upcoming">À venir</option>
            <option value="past">Passés</option>
          </select>
          <select
            onChange={(e) => setTypeFilter(e.target.value)}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="All">Tous les types</option>
            {typeEvents.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => setLevelFilter(e.target.value)}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="All">Tous les niveaux</option>
            {levelEvents.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => setCateFilter(e.target.value)}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="All">Toutes les catégories</option>
            {cateEvents.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="bg-gray-100 dark:bg-gray-500 shadow-lg w-full max-w-[800px] mx-auto font-montserrat">
          <div className="overflow-x-auto">
            <table className="table-auto bg-white dark:bg-slate-300 font-montserrat max-w-[800px] mx-auto border-collapse border-2 border-slate-300">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Afficher
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Photo
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Date
                    <button
                      onClick={toggleSortOrder}
                      className="ml-2 text-xs text-white"
                    >
                      {sortAscending ? "⬇️" : "⬆️"}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Nom et Info
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedEvents.length > 0 ? (
                  sortedEvents.map((event) => {
                    const isPastEvent = isPastDate(event.start);
                    return (
                      <tr
                        key={event.id}
                        className={`${
                          isPastEvent ? "bg-gray-200" : "bg-white"
                        } hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm`}
                      >
                        <td
                          className="px-2 py-3 text-center cursor-pointer"
                          onClick={() => handleToggleActive(event)}
                        >
                          {event.is_active ? "✅" : "❌"}
                        </td>
                        <td className="px-2 py-3 text-center">
                          <img
                            src={event.photo_url}
                            alt={event.titre}
                            className="w-64 h-auto object-cover mx-auto"
                          />
                          <p className="text-xs mt-2 cursor-pointer" onClick={() => openEditModal(event)}>Modifier Image</p>
                        </td>
                        <td className="px-2 py-3 text-center">
                          {formatDate(event.start)}
                        </td>
                        <td className="px-2 py-3 text-left">
                          <div>{event.titre}</div>
                        </td>
                        <td className="px-2 py-3 flex items-center justify-center space-x-2">
                          <button
                            onClick={() => openDetailsModal(event)} // Ouvrir EventDetailsModal
                            className="w-8 h-8 flex items-center justify-center text-white rounded-md bg-primary p-2 shadow-sm transition duration-300 ease-in-out hover:bg-orange-700"
                          >
                            <FaInfoCircle />
                          </button>
                          <button
                            onClick={() => openRewardsModal(event)} // Ouvrir RewardsEditModal
                            className="w-8 h-8 flex items-center justify-center text-white rounded-md bg-primary p-2 shadow-sm transition duration-300 ease-in-out hover:bg-yellow-500"
                          >
                            <FaTrophy />
                          </button>
                          <ButtonEdit onClick={() => openEditModal(event)} />
                          <ButtonDelete
                            onClick={() => deleteEventFunc(event.id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center py-4 px-6 text-gray-800"
                    >
                      Aucun événement.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <EditEventModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          event={editableEvent}
          refreshList={fetchEvents}
        />
        <CreateEventModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refreshList={fetchEvents}
        />
        <EventDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={closeDetailsModal}
          event={selectedEvent}
          userRole={userRole} // Passer userRole à EventDetailsModal
        />
        <RewardsEditModal // Inclure RewardsEditModal
          isOpen={isRewardsModalOpen}
          onClose={closeRewardsModal}
          event={editableEvent}
          refreshList={fetchEvents}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default EventsPage;
