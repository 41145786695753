import React, { useState, useEffect } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { updateArticle } from "../../services/articlescommissionsServices";
import { useUser } from "../../contexts/UserContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditArticleModal = ({ isOpen, onClose, refreshList, article }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    titre: article?.titre || "",
    contenu: article?.contenu || "",
    photo_url1: article?.photo_url1 || "",
    photo_url2: article?.photo_url2 || "",
    photo_url3: article?.photo_url3 || "",
    is_active: false, // Par défaut à false
    date_article:
      article?.date_article || new Date().toISOString().split("T")[0],
  });

  useEffect(() => {
    if (article) {
      setFormData({
        titre: article.titre,
        contenu: article.contenu,
        photo_url1: article.photo_url1,
        photo_url2: article.photo_url2,
        photo_url3: article.photo_url3,
        is_active: article.is_active || false, // Définit à false si la valeur n'est pas définie
        date_article: article.date_article,
      });
    }
  }, [article]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      contenu: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateArticle(article.id, formData);
      refreshList();
      onClose();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'article", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier l'Article
        </h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="titre"
            placeholder="Titre de l'article"
            value={formData.titre}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
            required
          />
          <ReactQuill
            value={formData.contenu}
            onChange={handleContentChange}
            className="mb-3 dark:bg-slate-200"
            theme="snow"
            placeholder="Contenu de l'article"
          />
          <input
            type="url"
            name="photo_url1"
            placeholder="URL de la photo 1"
            value={formData.photo_url1}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="url"
            name="photo_url2"
            placeholder="URL de la photo 2"
            value={formData.photo_url2}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="url"
            name="photo_url3"
            placeholder="URL de la photo 3"
            value={formData.photo_url3}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="date"
            name="date_article"
            value={formData.date_article}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          {/* La case à cocher "is_active" a été retirée */}
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditArticleModal;
