import { CiMail } from 'react-icons/ci'; // Importing an example icon for the button
import Button from '../../components/Button/Button'; // Ensure this import path matches your Button component's location
import useScrollToTop from "../../hooks/useScrollToTop";

const FaqPage = () => {
  // Scroll top top
  useScrollToTop();
  return (
    <div className='flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 mt-[120px] bg-backgroundMain dark:bg-backgroundMainDark dark:text-white'>
      <h1 className='text-xl tracking-widest text-gray-800 text-center dark:text-white'>FAQ - Questions Fréquemment Posées</h1>
      <h2 className='text-4xl font-bold uppercase text-center my-6 mb-10'>Avez-vous des questions ? Nous avons des réponses.</h2>

      {/* Header image */}
      <div className="text-center mb-4">
        <img src="/img/carrousel07.webp" alt="Inscription" className="mx-auto" />
      </div>

      {/* Collapsible FAQ Items */}
      <div className='space-y-4'>
        {/* Question 1 */}
        <details className='group'>
          <summary className='text-lg font-medium cursor-pointer'>Comment puis-je m'inscrire afin de créer un compte ?</summary>
          <p className='mt-2 p-4 bg-base-300 rounded-lg'>
            Pour vous inscrire, visitez notre page{' '}
            <a href='/register' className='text-primary underline'>
              Inscription
            </a>
            . Après cette étape, vous pouvez vous connecter à votre compte en utilisant votre adresse e-mail et votre mot de passe.
            sur notre page{' '}
            <a href='/login' className='text-primary underline'>
              Connexion
            </a>
          </p>
        </details>

        {/* Question 2 */}
        <details className='group'>
          <summary className='text-lg font-medium cursor-pointer'>Comment créer une inscription en tant qu'acteur du judo girondin</summary>
          <p className='mt-2 p-4 bg-base-300 rounded-lg'>
            En étant connecté, vous pouvez créer une inscription en allant sur la page{' '}
            <a href='/inscription' className='text-primary underline'>
              Inscription
            </a>
            . Remplir le formulaire, n'oubliez pas de choisi sur quel groupe vous souhaitez être placé. L'administrateur validera votre inscription et vous répartira dans le groupe sélectionné.
          </p>
        </details>


        {/* Question 3 */}
        <details className='group'>
          <summary className='text-lg font-medium cursor-pointer'>A quoi sert de faire une inscription en tant qu'acteur du judo girondin ?</summary>
          <p className='mt-2 p-4 bg-base-300 rounded-lg'>
            En tant que responsables ou enseignants de clubs, vous aurez acces à une messagerie afin de communiquer avec le comité et les différentes commissions, passer ou consulter des annonces, proposer des événements comme des cours communs, stages ou tournois interclubs.
            En tant qu'arbitres, commissaires sportifs, bénévolontaires, vous aurez accès à des informations spécifiques à votre activité et les convocations sur les différentes compétitions.
            En tant que membres du comité ou des différentes commissions, vous aurez la possibilité de publier des articles sur le site.
          </p>
        </details>
      </div>

    </div>
  );
};

export default FaqPage;
