import React from "react";
import useScrollToTop from "../../hooks/useScrollToTop";

const ReglementInterieur = () => {
  useScrollToTop();

  const discoursPreliminaire = `
    <p>Judokas, Amis sportifs,</p>
    <p>L’équipe d’organisation vous souhaite la bienvenue. Comme vous, nous désirons que cette manifestation se déroule dans les meilleures conditions pour le plaisir de tous.</p>
    <br/>
    <p>La Fédération Française de Judo attire particulièrement votre attention sur quelques points essentiels. Le Judo a été créé dans le but de formation et d’éducation. L’esprit du Judo et sa pratique implique d’abord le respect de l’adversaire, des arbitres, des commissaires sportifs, du public ; ensuite le respect absolu des règles, car respecter les décisions de l’arbitre fait partie de la formation du jeune judoka ; enfin le respect des lieux, c’est-à-dire qu’il convient de ne pas laisser vos déchets dans les gradins et de laisser la salle aussi propre que vous l’avez trouvée en arrivant.</p>
    <br/>
    <p>Nous demandons aux parents, aux accompagnateurs et au public, d’avoir un comportement en harmonie avec ces valeurs. Aucune pression ne doit s’exercer sur les jeunes combattants, car le plaisir de pratiquer est plus important que la victoire ou la défaite.</p>
    <p>Nous comptons sur les enseignants et les arbitres pour y veiller tout particulièrement.</p>
    <p>Les compétiteurs, en saluant correctement le tapis et l’adversaire, en sachant rester fair-play et garder une attitude juste, montrent leur attachement aux valeurs du judo. Ils sont ainsi dignes de leur grade, de leur professeur, de leur club et contribuent à l’image de marque du Judo français.</p>
    <br/>
    <p>Prêt pour le salut : Rei !</p>
  `;

  return (
    <div className="container mx-auto pt-[150px] px-4 mb-10 font-montserrat">
      <h2 className="text-2xl uppercase font-bold mb-4 max-w-[1024px] mx-auto">
        Discours Préliminaire lors de compétitions.

      </h2>

      <div className="mb-8 max-w-[1024px] mx-auto">
        <div dangerouslySetInnerHTML={{ __html: discoursPreliminaire }} />
      </div>
    </div>
  );
};

export default ReglementInterieur;
