import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/taches`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les tâches
export const getAllTasks = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allTasks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des tâches:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour récupérer une tâche par son id
export const getTaskById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/taskById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement de la tâche:", error);
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter une tâche
export const addTask = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/addTask`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 201) throw new Error("Failed to add data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout de la tâche:", error);
    throw error;
  }
};

/// PUT
// Fonction pour modifier une tâche
export const updateTask = async (taskId, data) => {
  try {
    const response = await axios.put(`${apiUrl}/updateTask/${taskId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to update data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la modification de la tâche:", error);
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer une tâche
export const deleteTask = async (taskId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteTask/${taskId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to delete data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression de la tâche:", error);
    throw error;
  }
};
