import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { updatePartenaireKata } from './../../services/partenaireskataServices';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditPartenaireModal = ({ isOpen, onClose, partenaire, refreshList }) => {
  const [formData, setFormData] = useState({
    club: '',
    passage_grade: '',
    content: '',
    tel: '',
    mail: '',
  });

  useEffect(() => {
    if (partenaire) {
      setFormData({
        club: partenaire.club,
        passage_grade: partenaire.passage_grade,
        content: partenaire.content,
        tel: partenaire.tel,
        mail: partenaire.mail,
      });
    }
  }, [partenaire]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData(prev => ({
      ...prev,
      content: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updatePartenaireKata({ ...formData, id: partenaire.id });
      refreshList();
      onClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du partenaire', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Éditer le Partenaire</h3>
        <form onSubmit={handleSubmit}>
          <label className="label text-gray-800">Club</label>
          <input
            type="text"
            name="club"
            placeholder="Nom du club"
            value={formData.club}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
            required
          />
          <label className="label text-gray-800">Grade</label>
          <select
            name="passage_grade"
            value={formData.passage_grade}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
            required
          >
            <option value="" disabled>Choisir le grade</option>
            <option value="1er Dan">1er Dan</option>
            <option value="2ème Dan">2ème Dan</option>
            <option value="3ème Dan">3ème Dan</option>
            <option value="4ème Dan">4ème Dan</option>
            <option value="5ème Dan">5ème Dan</option>
            <option value="6ème Dan">6ème Dan</option>
            <option value="7ème Dan">7ème Dan</option>
            <option value="8ème Dan">8ème Dan</option>
          </select>
          <label className="label text-gray-800">Contenu</label>
          <ReactQuill
            value={formData.content}
            onChange={handleContentChange}
            className="mb-3 dark:bg-slate-200"
            theme="snow"
            placeholder="Contenu"
          />
          <label className="label text-gray-800">Contact Téléphonique</label>
          <input
            type="tel"
            name="tel"
            placeholder="Téléphone"
            value={formData.tel}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <label className="label text-gray-800">Contact Email</label>
          <input
            type="email"
            name="mail"
            placeholder="Email"
            value={formData.mail}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPartenaireModal;
