import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { getRoleFromRoleId } from "../../utils/roleHelper";
import useScrollToTop from "../../hooks/useScrollToTop";
import Button from "./../../components/Button/Button";
import ButtonEdit from "./../../components/Button/ButtonEdit";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import { ToastContainer, toast } from "react-toastify";
import { getAllTasks, deleteTask } from "../../services/tachesServices";
import { getAllUsers } from "../../services/userServices";
import "react-toastify/dist/ReactToastify.css";
import AddTaskModal from "./AddTaskModal";
import EditTaskModal from "./EditTaskModal";

const ToDoListPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const data = await getAllTasks();
        setTasks(data);
        setFilteredTasks(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des tâches:", error);
        toast.error("Erreur lors de la récupération des tâches");
      }
    };

    const fetchUsers = async () => {
      try {
        const data = await getAllUsers();
        setUsers(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs:", error);
        toast.error("Erreur lors de la récupération des utilisateurs");
      }
    };

    fetchTasks();
    fetchUsers();
  }, []);

  useEffect(() => {
    filterTasks();
  }, [statusFilter, userFilter, tasks]);

  const filterTasks = () => {
    let filtered = tasks;

    if (statusFilter) {
      filtered = filtered.filter(task => task.statut === statusFilter);
    }

    if (userFilter) {
      filtered = filtered.filter(task => task.user_id === parseInt(userFilter));
    }

    setFilteredTasks(filtered);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const handleEditClick = (task) => {
    setCurrentTask(task);
    setShowEditModal(true);
  };

  const handleDeleteClick = async (taskId) => {
    try {
      await deleteTask(taskId);
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
      toast.success("Tâche supprimée avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression de la tâche:", error);
      toast.error("Erreur lors de la suppression de la tâche");
    }
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleUserFilterChange = (e) => {
    setUserFilter(e.target.value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Effectué":
        return "bg-green-200 text-green-800";
      case "A faire":
        return "bg-yellow-200 text-yellow-800";
      case "Urgent":
        return "bg-red-200 text-red-800";
      case "En cours":
        return "bg-blue-200 text-blue-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  // Créer un ensemble d'ID d'utilisateurs ayant au moins une tâche en cours
  const usersWithTasks = new Set(tasks.map(task => task.user_id));

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        MEMBRES DU COMITÉ
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        ToDoList
      </h2>

      <div className="mx-auto p-4 w-full max-w-[1280px]">
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4 mb-4">
          <div className="w-full md:w-1/3">
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className="select select-bordered w-full dark:bg-gray-800 dark:text-white dark:placeholder-gray-400 mb-3 md:mb-0"
            >
              <option value="">Filtrer par statut</option>
              <option value="A faire">A faire</option>
              <option value="En cours">En cours</option>
              <option value="Urgent">Urgent</option>
              <option value="Effectué">Effectué</option>
            </select>
          </div>
          <div className="w-full md:w-1/3">
            <select
              value={userFilter}
              onChange={handleUserFilterChange}
              className="select select-bordered w-full dark:bg-gray-800 dark:text-white dark:placeholder-gray-400 mb-3 md:mb-0"
            >
              <option value="">Filtrer par utilisateur</option>
              {users
                .filter(user => usersWithTasks.has(user.id))
                .map(user => (
                  <option key={user.id} value={user.id}>
                    {user.nom} {user.prenom}
                  </option>
              ))}
            </select>
          </div>
          <Button text="Ajouter une Tâche" onClick={handleAddClick} className="w-full md:w-auto" />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredTasks.length > 0 ? (
            filteredTasks.map((task) => (
              <div key={task.id} className={`p-4 rounded-lg shadow-md ${getStatusColor(task.statut)}`}>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <img src={task.user.photoURL} alt={task.user.nom} className="w-12 h-12 rounded-full mr-2 object-cover" />
                    <div>
                      <div className="font-semibold">{task.user.nom}</div>
                      <div>{task.user.prenom}</div>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <ButtonEdit onClick={() => handleEditClick(task)} />
                    <ButtonDelete onClick={() => handleDeleteClick(task.id)} />
                  </div>
                </div>
                <div className="text-sm text-gray-800 mb-2">
                  <div className="font-semibold">{task.categorie}</div>
                  <div className="font-semibold">{task.titre}</div>
                  <div dangerouslySetInnerHTML={{ __html: task.contenu }} />
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-4 px-6 text-gray-800">Aucune tâche disponible.</div>
          )}
        </div>
      </div>
      <ToastContainer />
      {showAddModal && (
        <AddTaskModal
          isOpen={showAddModal}
          onClose={handleCloseAddModal}
          refreshList={() => {
            const fetchTasks = async () => {
              try {
                const data = await getAllTasks();
                setTasks(data);
              } catch (error) {
                console.error("Erreur lors de la récupération des tâches:", error);
                toast.error("Erreur lors de la récupération des tâches");
              }
            };
            fetchTasks();
          }}
        />
      )}
      {showEditModal && (
        <EditTaskModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          task={currentTask}
          refreshList={() => {
            const fetchTasks = async () => {
              try {
                const data = await getAllTasks();
                setTasks(data);
              } catch (error) {
                console.error("Erreur lors de la récupération des tâches:", error);
                toast.error("Erreur lors de la récupération des tâches");
              }
            };
            fetchTasks();
          }}
        />
      )}
    </div>
  );
};

export default ToDoListPage;
