import React, { useState, useEffect } from "react";
import { RiCheckDoubleFill } from "react-icons/ri";
import Button from "./../../components/Button/Button";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import ButtonEyeNoRead from "../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../components/Button/ButtonEyeRead";
import ButtonReply from "../../components/Button/ButtonReply";
import {
  getMessagesByUser,
  deleteMessageById,
  markMessageAsRead,
  markAllMessagesAsRead,
} from "./../../services/messageServices";
import { useMessage } from "./../../contexts/MessageContext";
import { FaEnvelope } from "react-icons/fa";
import CreateResponseMessageForm from "./CreateResponseMessageForm";
import { toast } from "react-toastify";

const MessagesDisplay = ({ userId, refresh, setRefresh }) => {
  const [messages, setMessages] = useState([]);
  const { unreadMessageCount, setUnreadMessageCount } = useMessage();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);

  useEffect(() => {
    if (refresh) {
      loadMessages();
      setRefresh(false);
    }
  }, [refresh, userId]);

  const loadMessages = async () => {
    try {
      const data = await getMessagesByUser(userId);
      // Filtrer les messages pour s'assurer que seuls les messages originaux sont affichés
      const filteredMessages = data.filter((message) => !message.is_copy);
      setMessages(filteredMessages);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
    }
  };

  const handleReply = (message) => {
    setCurrentMessage({ recipientId: message.sender_id, subject: `RE: ${message.subject}` });
    setShowReplyModal(true);
  };

  const closeReplyModal = () => {
    setShowReplyModal(false);
    setCurrentMessage(null);
  };

  const promptDeleteMessage = (messageId) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?");
    if (confirmDelete) {
      handleDeleteMessage(messageId);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageById(messageId);
      setMessages(messages.filter((message) => message.id !== messageId)); // Update state immediately
      setShowConfirmModal(false);
      toast.success("Message supprimé avec succès.");
    } catch (error) {
      console.error("Error deleting message:", error);
      toast.error("Erreur lors de la suppression du message.");
    }
  };

  const toggleReadStatus = async (messageId) => {
    try {
      const message = messages.find((m) => m.id === messageId);
      await markMessageAsRead(messageId, !message.is_read);
      setMessages(messages.map((m) => (m.id === messageId ? { ...m, is_read: !m.is_read } : m)));
      setUnreadMessageCount((current) => (message.is_read ? current + 1 : current - 1));
    } catch (error) {
      console.error("Erreur lors du basculement de l'état de lecture:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllMessagesAsRead(userId);
      setMessages(messages.map((m) => ({ ...m, is_read: true })));
      setUnreadMessageCount(0);
    } catch (error) {
      console.error("Erreur lors du marquage de tous les messages comme lus:", error);
    }
  };

  useEffect(() => {
    loadMessages();
  }, [userId]);

  return (
    <div className="overflow-x-auto shadow-xl">
      <div className="flex justify-between">
        <h3 className="card-title mb-2 ml-2">
          <div className="relative">
            <FaEnvelope className={`icon ${unreadMessageCount > 0 ? "" : ""}`} title="Messages Reçus" />
            {unreadMessageCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-primary text-white text-xs rounded-full px-2 py-0.5">
                {unreadMessageCount}
              </span>
            )}
          </div>
          Messages Reçus
        </h3>
        <div className="flex">
          <Button
            onClick={handleMarkAllAsRead}
            icon={RiCheckDoubleFill}
            className="mb-4 align-right"
            hoverLabel="Tout Marquer Comme Lu"
          />
        </div>
      </div>
      {messages.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-50 divide-y divide-gray-400">
              <thead className="bg-gray-50">
                <tr className="bg-slate-400">
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Détails
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {messages.map((message) => (
                  <tr key={message.id}
                  className={`hover:bg-gray-300 ${
                      message.is_read ? "bg-gray-200" : "bg-gray-100"
                    }`}>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 ">
                      {new Date(message.created_at).toLocaleDateString("fr-FR")}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500 w-full sm:w-auto">
                      {message.Sender && ( // Vérifiez la présence de Sender avant d'accéder à ses propriétés
                        <>
                          <div className="font-semibold">{`${message.Sender.nom} ${message.Sender.prenom}`}</div>
                          <div className="text-sm text-blue-800 font-bold">{message.subject}</div>
                          <div className="text-sm" dangerouslySetInnerHTML={{ __html: message.content }}></div>
                        </>
                      )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium gap-2 flex">
                      <ButtonReply onClick={() => handleReply(message)} hoverLabel="Répondre" />
                      {message.is_read ? (
                        <ButtonEyeRead onClick={() => toggleReadStatus(message.id)} hoverLabel="Marquer comme non lu" />
                      ) : (
                        <ButtonEyeNoRead onClick={() => toggleReadStatus(message.id)} hoverLabel="Marquer comme lu" />
                      )}
                      <ButtonDelete onClick={() => promptDeleteMessage(message.id)} hoverLabel="Supprimer" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {messages.map((message) => (
              <div key={message.id}
              className={`shadow-md rounded-lg p-4 mb-4 ${
                  message.is_read ? "bg-gray-200" : "bg-white"
                }`}>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold text-gray-900">
                    {new Date(message.created_at).toLocaleDateString("fr-FR")}
                  </span>
                  <div className="flex space-x-2">
                    <ButtonReply onClick={() => handleReply(message)} hoverLabel="Répondre" />
                    {message.is_read ? (
                      <ButtonEyeRead onClick={() => toggleReadStatus(message.id)} hoverLabel="Marquer comme non lu" />
                    ) : (
                      <ButtonEyeNoRead onClick={() => toggleReadStatus(message.id)} hoverLabel="Marquer comme lu" />
                    )}
                    <ButtonDelete onClick={() => promptDeleteMessage(message.id)} hoverLabel="Supprimer" />
                  </div>
                </div>
                <div className="text-sm text-gray-500 mb-2">
                  {message.Sender && ( // Vérifiez la présence de Sender avant d'accéder à ses propriétés
                    <>
                      <div className="font-semibold">{`${message.Sender.nom} ${message.Sender.prenom}`}</div>
                      <div className="text-sm text-blue-800 font-bold">{message.subject}</div>
                      <div className="text-sm" dangerouslySetInnerHTML={{ __html: message.content }}></div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4 bg-white dark:text-gray-800">Aucuns messages.</div>
      )}
      {showReplyModal && (
        <CreateResponseMessageForm
          show={showReplyModal}
          onHide={() => setShowReplyModal(false)}
          userId={userId}
          initialRecipientId={currentMessage.recipientId}
          initialSubject={currentMessage.subject}
        />
      )}
    </div>
  );
};

export default MessagesDisplay;
