import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { updateArticle } from './../../services/vieclubServices';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditArticleModal = ({ isOpen, onClose, refreshList, article }) => {
    const [formData, setFormData] = useState({
        club: article.club,
        title: article.title,
        content: article.content,
        date_article: article.date_article,
        photo_url1: article.photo_url1,
        photo_url2: article.photo_url2,
        photo_url3: article.photo_url3,
        lien_url: article.lien_url,
        is_active: article.is_active
    });

    useEffect(() => {
        // Réinitialiser les champs du formulaire chaque fois que l'article change
        setFormData({
            club: article.club,
            title: article.title,
            content: article.content,
            date_article: article.date_article,
            photo_url1: article.photo_url1,
            photo_url2: article.photo_url2,
            photo_url3: article.photo_url3,
            lien_url: article.lien_url,
            is_active: article.is_active
        });
    }, [article]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleContentChange = (value) => {
        setFormData(prev => ({
            ...prev,
            content: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateArticle(article.id, formData);
            refreshList();
            onClose();
            toast.success("Article mis à jour avec succès.");
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'article', error);
            toast.error("Erreur lors de la mise à jour de l'article.");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Éditer un Article</h3>
                <form onSubmit={handleSubmit}>
                    <label className="label text-gray-800">Club</label>
                    <input
                        type="text"
                        name="club"
                        placeholder="Nom du Club"
                        value={formData.club}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <label className="label text-gray-800">Titre</label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Titre de l'article"
                        value={formData.title}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <ReactQuill
                        value={formData.content}
                        onChange={handleContentChange}
                        className="mb-3"
                        placeholder="Contenu de l'article"
                    />
                    <label className="label text-gray-800">Date de l'article</label>
                    <input
                        type="date"
                        name="date_article"
                        value={formData.date_article}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <label className="label text-gray-800">Photo 1</label>
                    <input
                        type="text"
                        name="photo_url1"
                        placeholder="URL de la photo 1"
                        value={formData.photo_url1}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label className="label text-gray-800">Photo 2 (facultative)</label>
                    <input
                        type="text"
                        name="photo_url2"
                        placeholder="URL de la photo 2"
                        value={formData.photo_url2}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label className="label text-gray-800">Photo 3 (facultative)</label>
                    <input
                        type="text"
                        name="photo_url3"
                        placeholder="URL de la photo 3"
                        value={formData.photo_url3}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label className="label text-gray-800">Lien URL (article, site, réseaux sociaux)</label>
                    <input
                        type="text"
                        name="lien_url"
                        placeholder="Lien URL"
                        value={formData.lien_url}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label className="label cursor-pointer flex justify-start space-x-2 mb-4">
                        <input
                            type="checkbox"
                            name="is_active"
                            checked={formData.is_active}
                            onChange={handleChange}
                            className="checkbox checkbox-primary"
                        />
                        <span className="label-text">Afficher sur la page</span>
                    </label>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditArticleModal;
