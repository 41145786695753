import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VieClubCard from "../VieClubPage/VieClubCard";
import { getAllArticles } from "../../services/vieclubServices";
import Button from "../../components/Button/Button";
import { useUser } from "../../contexts/UserContext";

const LastVieClubs = () => {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const fetchedArticles = await getAllArticles();
        const lastArticles = fetchedArticles.slice(0, 2); // On récupère les deux derniers articles
        setArticles(lastArticles);
      } catch (error) {
        console.error("Failed to fetch articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const handleViewMore = () => {
    navigate("/vie-des-clubs");
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-3xl font-extrabold text-center mt-2 font-montserrat uppercase">
        Derniers Articles de la Vie des Clubs
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
        {articles.map((article, index) => (
          <VieClubCard
            key={article.id}
            article={article}
            index={index}
            userRole={user?.role_id || "Visiteur"}
            refreshArticlesList={() => {}} // Pas de rafraîchissement nécessaire ici
          />
        ))}
      </div>
      {user && (
        <Button
          text="Voir plus d'articles"
          onClick={handleViewMore}
          className="self-center mt-4"
        />
      )}
    </div>
  );
};

export default LastVieClubs;
