import React, { useState } from "react";
import Button from "../../components/Button/Button";

const QuizzVideoCard = ({ quizz }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  // Extract the video ID from the video URL
  const videoId = quizz.lien_url_video.split("youtu.be/")[1];

  // Construct the image URL using the video ID
  const imageUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  return (
    <div className="card bg-gray-200 shadow-lg p-4 rounded-lg border dark:border-gray-700 dark:bg-gray-800">
      <div className="flex">
        <div className="w-1/2">
          <a href={quizz.lien_url_video} target="_blank" rel="noopener noreferrer">
            <img
              src={imageUrl}
              alt={quizz.nom_quizz}
              className="w-full h-40 object-cover rounded-lg"
            />
          </a>
        </div>
        <div className="w-1/2 p-4">
          <h3 className="text-lg font-bold mb-2">{quizz.nom_quizz}</h3>
          <p className="text-sm mb-4">Famille: {quizz.famille_quizz}</p>
          <Button text={showDetails ? "Masquer les détails" : "Voir les détails"} onClick={toggleDetails} className="mb-4" />
          {showDetails && (
            <>
              <p className="text-sm mb-2"><strong>Décision:</strong> {quizz.decision}</p>
              <p className="text-sm"><strong>Description:</strong> {quizz.description}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizzVideoCard;
