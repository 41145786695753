import React, { useState, useEffect } from "react";
import {
  getGroupsList,
  deleteGroup,
  activateGroup,
  updateOrderGroup,
} from "../../services/groupServices";
import Button from "../../components/Button/Button";
import ButtonEdit from "../../components/Button/ButtonEdit";
import ButtonDelete from "../../components/Button/ButtonDelete";
import { FaPlus } from "react-icons/fa";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import CreateGroup from "./CreateGroup";
import EditGroupModal from "./EditGroupModal";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import { useUser } from "../../contexts/UserContext";
import useScrollToTop from "../../hooks/useScrollToTop";

const GroupsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = user ? user.role : "guest";
  const [groups, setGroups] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const data = await getGroupsList();
      setGroups(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des groupes", error);
      toast.error("Erreur lors de la récupération des groupes");
    }
  };

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterGroups(searchTermLocal);
  };

  const filterGroups = (term) => {
    let filteredGroups = groups;
    if (term) {
      filteredGroups = filteredGroups.filter((group) =>
        group.nom.toLowerCase().includes(term.toLowerCase())
      );
    }
    setGroups(filteredGroups);
  };

  const handleDeleteGroup = async (group) => {
    if (group.nombreActeurs > 0) {
      toast.error("Impossible de supprimer un groupe avec des membres actifs.");
      return;
    }

    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer le groupe ${group.nom} ?`
      )
    ) {
      try {
        await deleteGroup(group.id);
        toast.success("Groupe supprimé avec succès");
        fetchGroups();
      } catch (error) {
        toast.error("Erreur lors de la suppression d’un groupe");
        console.error("Erreur lors de la suppression d’un groupe", error);
      }
    }
  };

  const toggleActiveStatus = async (groupId) => {
    try {
      await activateGroup(groupId);
      toast.success("Statut du groupe mis à jour avec succès");
      fetchGroups();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du statut du groupe");
      console.error("Erreur lors de la mise à jour du statut du groupe", error);
    }
  };

  const openEditModal = (group) => {
    setSelectedGroup(group);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedGroup(null);
    setIsEditModalOpen(false);
  };

  const moveGroupUp = async (index) => {
    if (index === 0) return; // Premier élément ne peut pas être déplacé vers le haut
    const newGroups = [...groups];
    const temp = newGroups[index - 1];
    newGroups[index - 1] = newGroups[index];
    newGroups[index] = temp;
    newGroups[index - 1].ordre_groupe--;
    newGroups[index].ordre_groupe++;
    setGroups(newGroups);
    await updateOrderGroup(newGroups);
    fetchGroups();
  };

  const moveGroupDown = async (index) => {
    if (index === groups.length - 1) return; // Dernier élément ne peut pas être déplacé vers le bas
    const newGroups = [...groups];
    const temp = newGroups[index + 1];
    newGroups[index + 1] = newGroups[index];
    newGroups[index] = temp;
    newGroups[index + 1].ordre_groupe++;
    newGroups[index].ordre_groupe--;
    setGroups(newGroups);
    await updateOrderGroup(newGroups);
    fetchGroups();
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing des Groupes du Judo
      </h2>
      <div className="shadow-xl w-full max-w-[1280px] mx-auto">
        <Button
          text="Ajouter Groupe"
          onClick={() => setIsCreateModalOpen(true)}
          icon={FaPlus}
          className="w-full gap-2"
        />
        <CreateGroup
          isActive={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refreshGroups={fetchGroups}
        />
        {isEditModalOpen && (
          <EditGroupModal
            isActive={isEditModalOpen}
            onClose={closeEditModal}
            refreshGroups={fetchGroups}
            groupData={selectedGroup}
          />
        )}
        <input
          className="input input-bordered mt-4 mb-4 w-full"
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="overflow-x-auto shadow-xl">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Nom du groupe
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Image
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Ordre
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Actif
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Nombre d'acteurs
              </th>

              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => (
              <tr key={group.id} className="text-left">
                <td className={`px-5 py-3 text-left font-semibold font ${group.color_groupe}`}>
                  {group.nom}
                </td>
                <td className="px-2 py-3 text-center">
                  <img
                    src={group.photo_url || './img/default_image.jpg'}
                    alt={group.nom}
                    className="w-32 h-auto object-cover mx-auto"
                  />
                  <p className="text-xs mt-2 cursor-pointer">Modifier Image</p>
                </td>
                <td className="px-5 py-14 text-left flex items-center">
                  {group.ordre_groupe}
                  <div className="flex flex-col ml-2">
                    <button
                      onClick={() => moveGroupUp(index)}
                      className="text-blue-400 hover:text-blue-900"
                    >
                      <TbTriangleFilled />
                    </button>
                    <button
                      onClick={() => moveGroupDown(index)}
                      className="text-blue-400 hover:text-blue-900"
                    >
                      <TbTriangleInvertedFilled />
                    </button>
                  </div>
                </td>
                <td className="px-5 py-3 text-left">
                  <button onClick={() => toggleActiveStatus(group.id)}>
                    {group.is_active ? "✅" : "❌"}
                  </button>
                </td>
                <td className="px-5 py-3 text-left">{group.nombreActeurs}</td>

                <td className="px-5 py-3 flex items-center gap-2">
                  <ButtonEdit
                    onClick={() => openEditModal(group)}
                    className=""
                    hoverLabel="Éditer"
                  />
                  <ButtonDelete
                    onClick={() => handleDeleteGroup(group)}
                    className=""
                    hoverLabel="Supprimer"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default GroupsPage;
