import React, { useState, useEffect } from "react";
import { getAllClubsGirondins, deleteClubGirondin } from "./../../services/clubsgirondinsServices";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import Button from "./../../components/Button/Button";
import MiniClubCard from "./MiniClubCard";
import { ToastContainer, toast } from "react-toastify";
import CreateClubModal from "./CreateClubModal";
import EditClubModal from "./EditClubModal";
import MapComponent from "./../../components/MapComponent/MapComponent"; // Importation du composant de la carte

const ListingClubs = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [clubs, setClubs] = useState([]);
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [secteurgeo, setSecteurgeo] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [selectedCommune, setSelectedCommune] = useState("");
  const [selectedSecteur, setSelectedSecteur] = useState("");
  const [selectedSecteurGeo, setSelectedSecteurGeo] = useState("");
  const [selectedDiscipline, setSelectedDiscipline] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);

  useEffect(() => {
    fetchClubs();
  }, []);

  const fetchClubs = async () => {
    try {
      const response = await getAllClubsGirondins();
      const clubsWithCoordinates = response.map(club => {
        const [latitude, longitude] = club.coordonnees_gps.split(',').map(coord => parseFloat(coord.trim()));
        return { ...club, latitude, longitude };
      });
      setClubs(clubsWithCoordinates);
      setFilteredClubs(clubsWithCoordinates);

      // Extract unique communes
      const uniqueCommunes = [...new Set(clubsWithCoordinates.map(club => club.commune))];
      setCommunes(uniqueCommunes);

      // Extract unique secteurs
      const uniqueSecteurs = [...new Set(clubsWithCoordinates.map(club => club.secteur))];
      setSecteurs(uniqueSecteurs);

      // Extract unique secteurs geo
      const uniqueSecteursGeo = [...new Set(clubsWithCoordinates.map(club => club.secteur_geo))];
      setSecteurgeo(uniqueSecteursGeo);

      // Extract unique disciplines
      const allDisciplines = clubsWithCoordinates.flatMap(club => club.disciplines.split('/').map(d => d.trim()));
      const uniqueDisciplines = [...new Set(allDisciplines)];
      setDisciplines(uniqueDisciplines);

    } catch (error) {
      console.error("Failed to fetch clubs:", error);
    }
  };

  const handleAddClub = () => {
    setIsCreateModalOpen(true);
  };

  const handleEditClub = (club) => {
    setSelectedClub(club);
    setIsEditModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    fetchClubs();
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedClub(null); // Reset selected club
    fetchClubs();
  };

  const handleDeleteClub = async (clubId) => {
    try {
      await deleteClubGirondin(clubId);
      fetchClubs();
      toast.success("Le club a bien été supprimé.");
    } catch (error) {
      console.error("Failed to delete club:", error);
      toast.error("Une erreur est survenue lors de la suppression du club.");
    }
  };

  // Handle filtering
  useEffect(() => {
    let filtered = clubs;

    if (selectedCommune) {
      filtered = filtered.filter(club => club.commune === selectedCommune);
    }

    if (selectedSecteur) {
      filtered = filtered.filter(club => club.secteur === selectedSecteur);
    }

    if (selectedSecteurGeo) {
      filtered = filtered.filter(club => club.secteur_geo=== selectedSecteurGeo);
    }

    if (selectedDiscipline) {
      filtered = filtered.filter(club => club.disciplines.includes(selectedDiscipline));
    }

    if (searchText) {
      filtered = filtered.filter(club => 
        club.nom_club.toLowerCase().includes(searchText.toLowerCase()) || 
        club.commune.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilteredClubs(filtered);
  }, [selectedCommune, selectedSecteur, selectedSecteurGeo ,selectedDiscipline, searchText, clubs]);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Liste des clubs girondins
      </h2>

      {/* Filter Section */}
      <div className="mb-4">
        <div className="flex flex-wrap gap-4 justify-center">
          <select 
            value={selectedCommune} 
            onChange={(e) => setSelectedCommune(e.target.value)} 
            className="p-2 rounded border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          >
            <option value="">Toutes les communes</option>
            {communes.map((commune, index) => (
              <option key={index} value={commune}>{commune}</option>
            ))}
          </select>

          <select 
            value={selectedSecteur} 
            onChange={(e) => setSelectedSecteur(e.target.value)} 
            className="p-2 rounded border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          >
            <option value="">Tous les secteurs</option>
            {secteurs.map((secteur, index) => (
              <option key={index} value={secteur}>{secteur}</option>
            ))}
          </select>

          <select
            value={selectedSecteurGeo}
            onChange={(e) => setSelectedSecteurGeo(e.target.value)}
            className="p-2 rounded border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          >
            <option value="">Tous les secteurs géographiques</option>
            {secteurgeo.map((secteur_geo, index) => (
              <option key={index} value={secteur_geo}>{secteur_geo}</option>
            ))}
          </select>

          <select 
            value={selectedDiscipline} 
            onChange={(e) => setSelectedDiscipline(e.target.value)} 
            className="p-2 rounded border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          >
            <option value="">Toutes les disciplines</option>
            {disciplines.map((discipline, index) => (
              <option key={index} value={discipline}>{discipline}</option>
            ))}
          </select>

          <input 
            type="text" 
            value={searchText} 
            onChange={(e) => setSearchText(e.target.value)} 
            placeholder="Rechercher par nom ou commune" 
            className="p-2 rounded border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          />
        </div>
      </div>

      {/* Counter Section */}
      <div className="text-center mb-4 flex gap-2 mx-auto">
        <p className="text-lg font-semibold">
          Nombre total de clubs : {clubs.length}
        </p>
        <p className="text-lg font-semibold">
          // Club(s) affiché(s) : {filteredClubs.length}
        </p>
      </div>

      <MapComponent clubs={filteredClubs} /> {/* Ajout de la carte ici */}
      <div className="overflow-x-auto w-full p-4 max-w-[1280px] mx-auto">
        <div className="mx-auto w-full gap-4 mb-4">
          {(userRole === "Administrateur" || userRole === "Bureau") && (
            <Button text="Ajouter un Club" onClick={handleAddClub} className="mb-4 items-center mx-auto" />
          )}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredClubs.map((club) => (
            <MiniClubCard
              key={club.id}
              club={club}
              handleEditClub={handleEditClub}
              handleDeleteClub={handleDeleteClub}
              refreshList={fetchClubs}
            />
          ))}
        </div>
      </div>
      <CreateClubModal isOpen={isCreateModalOpen} onClose={closeCreateModal} refreshList={fetchClubs} />
      <EditClubModal isOpen={isEditModalOpen} onClose={closeEditModal} refreshList={fetchClubs} club={selectedClub} />
      <ToastContainer />
    </div>
  );
}

export default ListingClubs;
