import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/partenaireskata`;
const token = localStorage.getItem("token");

//GET
// Fonction pour afficher tous les partenaires de Kata
export const getPartenairesKata = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllPartenairesKata`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors du chargement des partenaires:", error);
    return [];
  }
}

/// POST
// Fonction pour créer un partenaire
export const createPartenaireKata = async (partenaire) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addPartenaireKata`,
      partenaire,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la création du partenaire:", error);
    return null;
  }
}


/// PUT
// Fonction pour modifier un partenaire
export const updatePartenaireKata = async (partenaire) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updatePartenaireKata/${partenaire.id}`,
      partenaire,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la modification du partenaire:", error);
    return null;
  }
}

/// DELETE
// Fonction pour supprimer un partenaire
export const deletePartenaireKata = async (id) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deletePartenaireKata/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la suppression du partenaire:", error);
    return null;
  }
}


