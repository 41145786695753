import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/clubsgirondins`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les clubs girondins
export const getAllClubsGirondins = async () => {
  try {
    const response = await axios.get(`${apiUrl}/AllClubsGirondins`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des clubs girondins:", error);
  }
};

// Fonction pour récupérer un club girondin par son id
export const getOneClubGirondin = async (clubId) => {
  try {
    const response = await axios.get(`${apiUrl}/OneClubGirondin/${clubId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement du club girondin:", error);
  }
};

// Fonction pour récupérer le nombre de clubs girondins
export const countClubsGirondins = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countClubsGirondins`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du chargement du nombre de clubs girondins:",
      error
    );
  }
};

/// POST
// Fonction pour ajouter un nouveau club girondin
export const addClubGirondin = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/addClubGirondin`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout du club girondin:", error);
  }
};

/// PUT
// Fonction pour modifier un club girondin avec son id
export const updateClubGirondin = async (clubId, data) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateClubGirondin/${clubId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du club girondin:", error);
  }
};

/// DELETE
// Fonction pour supprimer un club girondin avec son id
export const deleteClubGirondin = async (clubId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteClubGirondin/${clubId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du club girondin:", error);
  }
};

