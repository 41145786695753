import React, { useState } from "react";
import { createActeurByUser } from "./../../services/acteursServices";
import { sendNotificationToAdmin } from '../../services/notificationServices';
import { toast } from "react-toastify";
import Button from "./../../components/Button/Button";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import { useUser } from "./../../contexts/UserContext";

const InscriptionForm = ({ onActorCreated }) => {
  const { user } = useUser();
  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    tel: "",
    adresse: "",
    cp: "",
    commune: "",
    genre: "",
    date_naissance: "",
    photo_url: "",
    club_acteur: "",
    licence_number: "",
    groupe_souhaite: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const acteurData = { ...formData, user_id: user.userId };
      await createActeurByUser(acteurData, token);
      const message = `${user.nom} ${user.prenom} a créé un nouvel acteur : ${formData.nom} ${formData.prenom} / ${formData.club_acteur} / ${formData.groupe_souhaite}`;
      await sendNotificationToAdmin(message, user.id);
      toast.success("Acteur créé avec succès");
      setFormData({
        nom: "",
        prenom: "",
        email: "",
        tel: "",
        adresse: "",
        cp: "",
        commune: "",
        genre: "",
        date_naissance: "",
        photo_url: "",
        club_acteur: "",
        licence_number: "",
        groupe_souhaite: "",
      });
      onActorCreated(); // Appel de la fonction de rappel après la soumission réussie
    } catch (error) {
      toast.error("Erreur lors de la création de l'acteur");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-lg">
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="nom">
          Nom
        </label>
        <input
          type="text"
          id="nom"
          name="nom"
          value={formData.nom}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="prenom">
          Prénom
        </label>
        <input
          type="text"
          id="prenom"
          name="prenom"
          value={formData.prenom}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="tel">
          Téléphone
        </label>
        <input
          type="tel"
          id="tel"
          name="tel"
          value={formData.tel}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="adresse">
          Adresse
        </label>
        <input
          type="text"
          id="adresse"
          name="adresse"
          value={formData.adresse}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="cp">
          Code Postal
        </label>
        <input
          type="text"
          id="cp"
          name="cp"
          value={formData.cp}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="commune">
          Commune
        </label>
        <input
          type="text"
          id="commune"
          name="commune"
          value={formData.commune}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="genre">
          Genre
        </label>
        <select
          id="genre"
          name="genre"
          value={formData.genre}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          required
        >
          <option value="">Sélectionnez</option>
          <option value="Homme">Homme</option>
          <option value="Femme">Femme</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="date_naissance">
          Date de Naissance
        </label>
        <input
          type="date"
          id="date_naissance"
          name="date_naissance"
          value={formData.date_naissance}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="photo_url">
          Lien de la Photo
        </label>
        <input
          type="url"
          id="photo_url"
          name="photo_url"
          value={formData.photo_url}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="club_acteur">
          Nom du Club
        </label>
        <input
          type="text"
          id="club_acteur"
          name="club_acteur"
          value={formData.club_acteur}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="licence_number">
          Numéro de Licence
        </label>
        <input
          type="text"
          id="licence_number"
          name="licence_number"
          value={formData.licence_number}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <InfoBubble title="Les différents groupes">
        <p>✅ Responsable de club</p>
        <p>✅ Enseignant</p>
        <p>✅ Membre de Commission</p>
        <p>✅ Membre du Comité</p>
        <p>✅ Bénévolontaire</p>
        <p>✅ Arbitre (titulaire, stagiaire, école d'arbitrage)</p>
        <p>✅ Commissaire Sportif (titulaire, stagiaire, école d'arbitrage)</p>
      </InfoBubble>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="groupe_souhaite">
          Choix de groupe(s)
        </label>
        <textarea
          id="groupe_souhaite"
          name="groupe_souhaite"
          value={formData.groupe_souhaite}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Indiquez vos choix de groupe(s) ici"
        />
      </div>
      <div className="flex items-center justify-between">
        <Button type="submit" text="Créer Acteur" className="w-full" />
      </div>
    </form>
  );
};

export default InscriptionForm;
