import React, { useState, useEffect } from 'react';
import Button from "../../components/Button/Button";
import { FaSave, FaTimes } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateActorEvent } from "../../services/acteureventServices";
import { toast } from "react-toastify";

const NoteModal = ({ isOpen, onClose, actorEvent, fetchActors }) => {
  const [note, setNote] = useState("");
  const [observations, setObservations] = useState("");

  useEffect(() => {
    if (actorEvent) {
      setNote(actorEvent.note || "");
      setObservations(actorEvent.observations || "");
    }
    console.log(actorEvent);
  }, [actorEvent]);

  const handleSave = async () => {
    if (actorEvent) {
      try {
        await updateActorEvent(actorEvent.acteur_id, actorEvent.event_id, { note, observations });
        toast.success("Informations mises à jour avec succès.");
        fetchActors(); // Rafraîchir la liste des acteurs
        onClose();
      } catch (error) {
        console.error("Erreur lors de la mise à jour des informations de l'acteur", error);
        toast.error("Erreur lors de la mise à jour des informations de l'acteur.");
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Noter au poste : {actorEvent?.poste}
        </h3>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Note:</label>
          <input
            type="text"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="input input-bordered w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Observations:</label>
          <ReactQuill
            value={observations}
            onChange={setObservations}
            className="h-40"
          />
        </div>
        <div className="flex justify-center gap-2 mt-14">
          <Button
            onClick={handleSave}
            className="btn-primary flex items-center gap-2"
            text="Enregistrer"
            icon={FaSave}
          />
          <Button
            onClick={onClose}
            className="btn-ghost flex items-center gap-2"
            text="Fermer"
            icon={FaTimes}
          />
        </div>
      </div>
    </div>
  );
};

export default NoteModal;
