import React, { useState } from 'react';
import { FaInfoCircle, FaEdit, FaTrash } from 'react-icons/fa';
import ClubDetailModal from './ClubDetailModal';
import { useUser } from './../../contexts/UserContext';
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import Button from './../../components/Button/Button';

const MiniClubCard = ({ club, handleEditClub, handleDeleteClub, refreshList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    refreshList();
  };

  return (
    <div className="relative bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4 flex flex-col justify-between">
      <div className="absolute top-2 right-2">
        <button
          onClick={handleOpenModal}
          className="text-white bg-primary rounded-full p-2 hover:bg-primary-dark"
        >
          <FaInfoCircle size={16} />
        </button>
      </div>
      <div className="flex flex-col items-center">
        <img
          src={club.lien_logo}
          alt={`Logo de ${club.nom_club}`}
          className="max-h-16 w-full object-contain mb-2"
        />
        <h3 className="font-semibold mb-2 text-center">{club.nom_club}</h3>
        <p className="text-sm mb-2 text-center"><strong>Commune:</strong> {club.commune}</p>
        <p className="text-sm text-center"><strong>Disciplines:</strong> {club.disciplines}</p>
      </div>
      {(userRole === "Administrateur" || userRole === "Bureau") && (
        <div className="flex justify-center space-x-2 mt-4">
          <Button text="" icon={FaEdit} onClick={() => handleEditClub(club)} type="button" className="btn-primary gap-2" />
          <Button text="" icon={FaTrash} onClick={() => handleDeleteClub(club.id)} type="button" className="btn-danger gap-2" />
        </div>
      )}
      {isModalOpen && <ClubDetailModal isOpen={isModalOpen} onClose={handleCloseModal} clubId={club.id} refreshList={refreshList} />}
    </div>
  );
};

export default MiniClubCard;
