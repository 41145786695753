import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/group`;
const token = localStorage.getItem("token");

/// GET
// Récupérer la liste des groupes
export const getGroupsList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/listGroup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des groupes:", error);
    throw error;
  }
};

// Récuperer la liste des groupes de Commissions
export const getGroupsListCommissions = async () => {
  try {
    const response = await axios.get(`${apiUrl}/listGroupCommissions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des groupes:", error);
    throw error;
  }
};

// Fonction pour récupérer tous les groupes (nom + id) pour le formulaire d'envoi de messages groupés
export const getAllGroupsMessage = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allgroupsMessage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des groupes:", error);
  }
};

// Trouver le nom d'un groupe par son id
export const getGroupNameById = async (groupId) => {
  try {
    const response = await axios.get(`${apiUrl}/getGroupNameById/${groupId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du nom du groupe:", error);
    throw error;
  }
};

/// POST
// Créer un groupe
export const createGroup = async (groupData) => {
  try {
    const response = await axios.post(`${apiUrl}/addGroup`, groupData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du groupe:", error);
    throw error;
  }
};

/// PUT
// Fonction pour modifier un groupe
export const updateGroup = async (groupId, groupData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateGroup/${groupId}`,
      groupData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du groupe:", error);
  }
};

// Fonction pour activer ou désactiver un groupe
export const activateGroup = async (groupId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/activateGroup/${groupId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du groupe:", error);
  }
};

//Fonction pour modifier l'ordre d'un groupe
export const updateOrderGroup = async (groups) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateOrderGroup`,
      { groups },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'ordre des groupes:",
      error
    );
  }
};

/// DELETE
// Supprimer un groupe
export const deleteGroup = async (groupId) => {
  try {
    const response = await axios.delete(`${apiUrl}/removeGroup/${groupId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du groupe:", error);
    throw error;
  }
};
