import React, { useState, useEffect } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { updateTeamMember } from "../../services/teamServices";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditTeamMemberModal = ({ isActive, onClose, refreshTeam, memberData }) => {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    fonction: "",
    photoURL: "",
    categorie: "",
    position: "",
  });

  useEffect(() => {
    if (memberData) {
      setFormData({
        nom: memberData.nom,
        prenom: memberData.prenom,
        fonction: memberData.fonction,
        photoURL: memberData.photoURL,
        categorie: memberData.categorie,
        position: memberData.position,
      });
    }
  }, [memberData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      fonction: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateTeamMember(memberData.id, formData);
      toast.success("Membre modifié avec succès");
      onClose();
      refreshTeam();
    } catch (error) {
      console.error("Erreur lors de la modification du membre", error);
      toast.error("Erreur lors de la modification du membre");
    }
  };

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Modifier Membre
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            className="input input-bordered w-full"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            required
            placeholder="Nom"
          />
          <input
            type="text"
            className="input input-bordered w-full"
            name="prenom"
            value={formData.prenom}
            onChange={handleChange}
            required
            placeholder="Prénom"
          />
          <ReactQuill
            value={formData.fonction}
            onChange={handleContentChange}
            className="mb-3"
            placeholder="Fonction"
          />
          <input
            type="text"
            className="input input-bordered w-full"
            name="photoURL"
            value={formData.photoURL}
            onChange={handleChange}
            required
            placeholder="URL de la photo"
          />
          <input
            type="text"
            className="input input-bordered w-full"
            name="categorie"
            value={formData.categorie}
            onChange={handleChange}
            required
            placeholder="Catégorie"
          />
          <input
            type="number"
            className="input input-bordered w-full"
            name="position"
            value={formData.position}
            onChange={handleChange}
            required
            placeholder="Position"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTeamMemberModal;
