import React, { useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import EditNewsModal from './EditNewsResponsablesModal';
import { deleteNews } from '../../services/newsServices';
import { useUser } from '../../contexts/UserContext';
import Button from '../../components/Button/Button';

const NewsResponsablesCard = ({ news, refreshList }) => {
  const { user } = useUser();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDeleteNews = async () => {
    try {
      await deleteNews(news.id);
      refreshList();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'actualité:", error);
    }
  };

  const handleEdit = () => setIsEditModalOpen(true);
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    refreshList();
  };

  const getUserInfoFromLocalStorage = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return userInfo || {};
  };

  const { role_id, userId } = getUserInfoFromLocalStorage();

  const canModify = role_id === 1 || role_id === 2;

  const cardBackgroundClass = news.is_active ? 'bg-white' : 'bg-gray-400';

  const imageUrl = news.image_url ? news.image_url : "./img/default_image.jpg";

  return (
    <div className={`flex flex-col ${cardBackgroundClass} bg-opacity-80 rounded-lg overflow-hidden shadow-md relative m-4`}>
      <img src={imageUrl} alt={news.title} className='object-cover rounded-t-lg max-h-72 w-full' />
      <div className='p-4 flex flex-col justify-between'>
        <div>
          <h3 className='text-lg font-bold uppercase text-gray-800 my-2 w-full font-montserrat'>{news.title}</h3>
          {news.author && (
            <h5 className='text-xs mb-3 text-black bg-backgroundMain w-full bg-gray-200 p-2'>
              {news.author.prenom} {news.author.nom}
            </h5>
          )}
          <div className='text-gray-600 italic text-justify w-full mb-4' dangerouslySetInnerHTML={{ __html: news.content }} />
          <p className='text-gray-500 text-xs mb-2'>
            Publié le: {new Date(news.created_at).toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </p>
        </div>
        <div className='flex justify-end gap-2 mt-4'>
          {canModify && (
            <>
              <button onClick={handleEdit} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                <FaEdit />
              </button>
              <button onClick={handleDeleteNews} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                <FaTrashAlt />
              </button>
            </>
          )}
        </div>
        <EditNewsModal isOpen={isEditModalOpen} onClose={closeEditModal} refreshList={refreshList} news={news} />
      </div>
    </div>
  );
};

export default NewsResponsablesCard;
