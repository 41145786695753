import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getAllActorsByEventId,
  toggleActeurValidation,
} from "./../../services/acteureventServices";
import { getAllEvents } from "./../../services/eventServices";
import { toast, ToastContainer } from "react-toastify";
import NoteModal from "./NoteModal";
import { FaCheck, FaTimes } from "react-icons/fa";
import Button from "../../components/Button/Button";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";

const ConvocationByEventPage = () => {
  useScrollToTop();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [actors, setActors] = useState({
    Organisateur: [],
    ResponsableComite: [],
    Benevolontaire: [],
    ResponsableArbitrage: [],
    Notateur: [],
    Juge: [],
    Arbitre: [],
    CommissaireSportif: [],
  });
  const [selectedActorEvent, setSelectedActorEvent] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if (eventId) {
      fetchActors();
      fetchEvents();
    }
  }, [eventId]);

  const fetchActors = async () => {
    try {
      const data = await getAllActorsByEventId(eventId);

      const sortedActors = {
        Organisateur: [],
        ResponsableComite: [],
        Benevolontaire: [],
        ResponsableArbitrage: [],
        Notateur: [],
        Juge: [],
        Arbitre: [],
        CommissaireSportif: [],
      };

      if (data.Acteur && Array.isArray(data.Acteur)) {
        data.Acteur.forEach((actor) => {
          switch (actor.ActeurEvent.poste) {
            case "Organisateur":
              sortedActors.Organisateur.push(actor);
              break;
            case "Responsable Comité":
              sortedActors.ResponsableComite.push(actor);
              break;
            case "Bénévolontaire":
              sortedActors.Benevolontaire.push(actor);
              break;
            case "Responsable Arbitrage":
              sortedActors.ResponsableArbitrage.push(actor);
              break;
            case "Notateur":
              sortedActors.Notateur.push(actor);
              break;
            case "Juge":
              sortedActors.Juge.push(actor);
              break;
            case "Arbitre":
              sortedActors.Arbitre.push(actor);
              break;
            case "Commissaire Sportif":
              sortedActors.CommissaireSportif.push(actor);
              break;
            default:
              break;
          }
        });
      }

      setEvent(data);
      setActors(sortedActors);
    } catch (error) {
      console.error(
        "Erreur lors du chargement des acteurs de l'événement",
        error
      );
      toast.error("Erreur lors du chargement des acteurs de l'événement.");
    }
  };

  const fetchEvents = async () => {
    try {
      const events = await getAllEvents();
      setEventList(events);
      const index = events.findIndex(
        (event) => event.id.toString() === eventId
      );
      setCurrentIndex(index);
    } catch (error) {
      console.error("Erreur lors du chargement des événements", error);
      toast.error("Erreur lors du chargement des événements.");
    }
  };

  const openNoteModal = (actorEvent) => {
    setSelectedActorEvent(actorEvent);
    setIsNoteModalOpen(true);
  };

  const closeNoteModal = () => {
    setSelectedActorEvent(null);
    setIsNoteModalOpen(false);
  };

  const handleToggleValidation = async (acteurId) => {
    try {
      await toggleActeurValidation(acteurId, eventId);
      toast.success("Validation mise à jour avec succès.");
      fetchActors();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la validation", error);
      toast.error("Erreur lors de la mise à jour de la validation.");
    }
  };

  const handleNavigate = (direction) => {
    if (direction === "prev" && currentIndex > 0) {
      navigate(`/convocations-admin/${eventList[currentIndex - 1].id}`);
    } else if (direction === "next" && currentIndex < eventList.length - 1) {
      navigate(`/convocations-admin/${eventList[currentIndex + 1].id}`);
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Détails de l'Événement
      </h2>

      {event && (
        <div className="bg-gray-100 dark:bg-gray-500 shadow-lg w-full max-w-[800px] mx-auto p-4 mb-4 flex flex-col sm:flex-row items-center">
          <div className="w-full sm:w-2/3">
            <h3 className="text-xl font-bold mb-2 bg-gray-300 p-2 rounded-sm mr-4">
              {event.titre}
            </h3>
            <p className="flex space-x-2">
              <strong>Description :</strong>{" "}
              <span dangerouslySetInnerHTML={{ __html: event.description }} />
            </p>
            <p>
              <strong>Type :</strong> {event.type_event}
            </p>
            <p>
              <strong>Niveau :</strong> {event.level_event}
            </p>
            <p>
              <strong>Catégorie :</strong> {event.cate_event}
            </p>
            <p>
              <strong>Lieu :</strong> {event.lieu_event}
            </p>
            <p>
              <strong>Date :</strong> {new Date(event.start).toLocaleDateString()}
              {event.end && event.end !== "0000-00-00" && (
                <> - {new Date(event.end).toLocaleDateString()}</>
              )}
            </p>
            <p className="flex space-x-2">
              <strong>Horaire :</strong>{" "}
              <span dangerouslySetInnerHTML={{ __html: event.horaire_event }} />
            </p>
          </div>
          <div className="relative w-full md:w-1/2 h-full">
            <img
              src={event.photo_url}
              alt={event.titre}
              className="object-cover rounded-t-lg md:rounded-l-lg w-full h-64"
            />
          </div>
        </div>
      )}

      <div className="flex justify-between mb-4">
        <Button
          onClick={() => handleNavigate("prev")}
          text="Événement Précédent"
          className="items-center gap-2"
          disabled={currentIndex <= 0}
        />
        <Button
          onClick={() => navigate("/convocations-admin")}
          text="Retour"
          className="items-center gap-2"
        />
        <Button
          onClick={() => handleNavigate("next")}
          text="Événement Suivant"
          className="items-center gap-2"
          disabled={currentIndex >= eventList.length - 1}
        />
      </div>

      <InfoBubble title="Notations & Observations">
        <p>
          Sur cette partie, vous pouvez cocher ou décocher la présence des
          acteurs à l'événement. En cliquant sur le nom de l'acteur, vous pouvez
          ajouter une note et des observations.
        </p>
      </InfoBubble>

      <div className="bg-gray-100 dark:bg-gray-500 shadow-lg w-full max-w-[1280px] mx-auto">
        <div className="overflow-x-auto">
          <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">
                  Organisateur
                </h3>
                {actors.Organisateur.length > 0 ? (
                  actors.Organisateur.map((actor) => (
                    <p key={actor.ActeurEvent.acteur_id} className="mb-2">
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </p>
                  ))
                ) : (
                  <p>Aucun organisateur</p>
                )}
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">
                  Responsable Comité
                </h3>
                {actors.ResponsableComite.length > 0 ? (
                  actors.ResponsableComite.map((actor) => (
                    <p key={actor.ActeurEvent.acteur_id} className="mb-2">
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </p>
                  ))
                ) : (
                  <p>Aucun responsable comité</p>
                )}
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">
                  Bénévolontaire
                </h3>
                {actors.Benevolontaire.length > 0 ? (
                  actors.Benevolontaire.map((actor) => (
                    <p key={actor.ActeurEvent.acteur_id} className="mb-2">
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </p>
                  ))
                ) : (
                  <p>Aucun bénévolontaire</p>
                )}
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">
                  Responsable Arbitrage
                </h3>
                {actors.ResponsableArbitrage.length > 0 ? (
                  actors.ResponsableArbitrage.map((actor) => (
                    <p key={actor.ActeurEvent.acteur_id} className="mb-2">
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </p>
                  ))
                ) : (
                  <p>Aucun responsable arbitrage</p>
                )}
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">
                  Notateur
                </h3>
                {actors.Notateur.length > 0 ? (
                  actors.Notateur.map((actor) => (
                    <p key={actor.ActeurEvent.acteur_id} className="mb-2">
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </p>
                  ))
                ) : (
                  <p>Aucun notateur</p>
                )}
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">Juge</h3>
                {actors.Juge.length > 0 ? (
                  actors.Juge.map((actor) => (
                    <p key={actor.ActeurEvent.acteur_id} className="mb-2">
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </p>
                  ))
                ) : (
                  <p>Aucun juge</p>
                )}
              </div>
              <div>
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">
                  Arbitre
                </h3>
                {actors.Arbitre.length > 0 ? (
                  actors.Arbitre.map((actor) => (
                    <div
                      key={actor.ActeurEvent.acteur_id}
                      className="mb-2 flex items-center"
                    >
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </div>
                  ))
                ) : (
                  <p>Aucun arbitre</p>
                )}
              </div>
              <div>
                <h3 className="bg-gray-200 text-lg font-semibold mt-2">
                  Commissaire Sportif
                </h3>
                {actors.CommissaireSportif.length > 0 ? (
                  actors.CommissaireSportif.map((actor) => (
                    <div
                      key={actor.ActeurEvent.acteur_id}
                      className="mb-2 flex items-center"
                    >
                      <button
                        onClick={() =>
                          handleToggleValidation(actor.ActeurEvent.acteur_id)
                        }
                      >
                        {actor.ActeurEvent.is_validate ? (
                          <FaCheck className="inline text-green-500" />
                        ) : (
                          <FaTimes className="inline text-red-500" />
                        )}
                      </button>
                      <button
                        onClick={() => openNoteModal(actor.ActeurEvent)}
                        className="ml-2 underline"
                      >
                        {actor.nom} {actor.prenom}
                      </button>
                    </div>
                  ))
                ) : (
                  <p>Aucun commissaire sportif</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoteModal
        isOpen={isNoteModalOpen}
        onClose={closeNoteModal}
        actorEvent={selectedActorEvent}
        fetchActors={fetchActors} // Passez fetchActors comme prop
      />
      <ToastContainer />
    </div>
  );
};

export default ConvocationByEventPage;
