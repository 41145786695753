// src/utils/roleHelper.js
export const getRoleFromRoleId = (roleId) => {
  const roleMap = {
    1: "Administrateur",
    2: "Bureau",
    3: "Membres",
    4: "Officiels",
    5: "Responsables_clubs",
    6: "Utilisateurs",
    7: "Membres_Arbitres",
  };
  return roleMap[roleId] || "Visiteurs"; // Fallback à 'user' si `roleId` est inconnu
};
