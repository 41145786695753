import React from 'react';
import { createPortal } from 'react-dom';

const Modal = ({ children, onClose, title }) => {
  return createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-600 p-4 rounded-lg shadow-lg">
        <h2 className='text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow'>{title}</h2>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;