import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Button from '../../components/Button/Button';
import { deleteArticle } from '../../services/vieclubServices';
import EditArticleModal from './EditArticleModal';
import { getUserById } from '../../services/userServices';
import { toast } from 'react-toastify';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VieClubCard = ({ article, index, userRole, refreshArticlesList, user }) => {
  const defaultImage = "/img/default_image.jpg";
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [author, setAuthor] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null); // État pour l'image sélectionnée

  const imageCount = [article.photo_url1, article.photo_url2, article.photo_url3].filter(Boolean).length;

  const settings = {
    dots: imageCount > 1,
    infinite: imageCount > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: imageCount > 1,
    autoplaySpeed: 3000,
    arrows: imageCount > 1
  };

  useEffect(() => {
    const fetchAuthor = async () => {
      if (article.user_id) {
        const authorData = await getUserById(article.user_id);
        setAuthor(authorData);
      }
    };

    fetchAuthor();
  }, [article.user_id]);

  const handleDeleteArticle = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
      try {
        await deleteArticle(article.id);
        toast.success("Article supprimé avec succès.");
        refreshArticlesList(); // Call to refresh the articles list after deletion
      } catch (error) {
        console.error("Erreur lors de la suppression de l'article:", error);
        toast.error("Erreur lors de la suppression de l'article.");
      }
    }
  };

  // Determine if the layout should be reversed based on the index
  const isReversed = index % 2 === 1;

  // Check if the user can edit or delete the article
  const canEditOrDelete = user?.userId === article.user_id || userRole === 1 || userRole === 2 || userRole === 5 || userRole === 7;

  // Ensure the URL is correctly formatted
  const formatUrl = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  };

  const handleClick = (img) => {
    setSelectedImg(img);
  };

  return (
    <div className={`flex flex-col ${isReversed ? 'md:flex-row-reverse' : 'md:flex-row'} mb-6 shadow-lg bg-slate-200 dark:bg-slate-400 rounded-lg overflow-hidden w-full`}>
      <div className="md:w-1/2">
        {(article.photo_url1 || article.photo_url2 || article.photo_url3) ? (
          <Slider {...settings}>
            {article.photo_url1 && (
              <div className="w-full h-full" onClick={() => handleClick(article.photo_url1)}>
                <img src={article.photo_url1} alt="Slide 1" className="object-cover w-full h-80 cursor-pointer" />
              </div>
            )}
            {article.photo_url2 && (
              <div className="w-full h-full" onClick={() => handleClick(article.photo_url2)}>
                <img src={article.photo_url2} alt="Slide 2" className="object-cover w-full h-80 cursor-pointer" />
              </div>
            )}
            {article.photo_url3 && (
              <div className="w-full h-full" onClick={() => handleClick(article.photo_url3)}>
                <img src={article.photo_url3} alt="Slide 3" className="object-cover w-full h-80 cursor-pointer" />
              </div>
            )}
          </Slider>
        ) : (
          <img src={defaultImage} alt="Default article cover" className="object-cover w-full h-80" />
        )}
      </div>
      <div className="md:w-1/2 p-4 flex flex-col justify-between">
        <div>
          <h3 className="text-gray-600 font-bold text-xl dark:text-white">{article.title}</h3>
          {author && (
            <div className="flex items-center mt-2 mb-4">
              <img src={author.photoURL || defaultImage} alt={author.nom} className="w-10 h-10 rounded-full mr-2" />
              <div className="text-gray-600 dark:text-slate-200">
                <p className="font-semibold">{author.nom} {author.prenom}</p>
                <p className='text-xs'>{article.club}</p>
              </div>
            </div>
          )}
          <p className="text-gray-600 mt-2 mb-4 dark:text-slate-200" dangerouslySetInnerHTML={{ __html: article.content }}></p>
          <p className="text-gray-500 dark:text-slate-200 text-xs">Publié le: {new Date(article.date_article).toLocaleDateString()}</p>
          {article.lien_url && (
            <p className="mt-2">
              <a href={formatUrl(article.lien_url)} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Lien vers l'article</a>
            </p>
          )}
        </div>
        {canEditOrDelete && (
          <div className='flex flex-row gap-4 justify-center mt-4'>
            <Button text="Supprimer" onClick={handleDeleteArticle} className="bg-red-500" />
            <Button text="Éditer l'Article" onClick={() => setEditModalOpen(true)} className="bg-blue-500" />
          </div>
        )}
      </div>
      {editModalOpen && (
        <EditArticleModal
          article={article}
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          refreshList={refreshArticlesList}
        />
      )}
      {selectedImg && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
          onClick={() => setSelectedImg(null)}
        >
          <img
            src={selectedImg}
            alt="Selected"
            className="max-w-[90%] max-h-[90%]"
          />
        </div>
      )}
    </div>
  );
};

export default VieClubCard;
