import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../components/Button/Button';
import { updateNews } from '../../services/newsServices';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditNewsModal = ({ isOpen, onClose, refreshList, news }) => {
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        image_url: '',
        created_at: '',
        is_active: true,
        id: '',
        destinaires_news: ''
    });

    useEffect(() => {
        if (news) {
            setFormData({
                title: news.title,
                content: news.content,
                image_url: news.image_url,
                is_active: news.is_active,
                created_at: news.created_at,
                id: news.id,
                destinaires_news: news.destinaires_news
            });
        }
    }, [news]);

    const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
    }));
};

    const handleContentChange = (value) => {
        setFormData((prev) => ({
            ...prev,
            content: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateNews(formData);
            refreshList();
            onClose();
            toast.success('Actualité mise à jour avec succès');
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'actualité', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white p-4 rounded-lg shadow-lg dark:bg-gray-400">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Mettre à jour une Actualité</h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="title"
                        placeholder="Titre de l'actualité"
                        value={formData.title}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <ReactQuill
                        value={formData.content}
                        onChange={handleContentChange}
                        className="mb-3"
                    />
                    <input
                        type="url"
                        name="image_url"
                        placeholder="URL de l'image"
                        value={formData.image_url}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <input
                        type="date"
                        name="created_at"
                        value={formData.created_at}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    {/* Choix entre "Tous public" ou "Responsables clubs"   */}
                    
                    <label htmlFor="destinaires_news" className="block mb-2">Destinataires</label>
                    <select
                        name="destinaires_news"
                        value={formData.destinaires_news}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    >
                        <option value="Tout public">Tout public</option>
                        <option value="Responsables clubs">Responsables clubs</option>
                    </select>
                    <div className="flex items-center mb-3">
                        <label htmlFor="is_active" className="mr-2">Actif</label>
                        <input
                            type="checkbox"
                            name="is_active"
                            checked={formData.is_active}
                            onChange={handleChange}
                            className="form-checkbox"
                        />
                    </div>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Mise à jour" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditNewsModal;
