import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { addArticle } from './../../services/vieclubServices';
import { sendNotificationToAdmin } from '../../services/notificationServices';
import { useUser } from './../../contexts/UserContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateArticleModal = ({ isOpen, onClose, refreshList }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    club: '',
    date_article: new Date().toISOString().split('T')[0], // Default to today's date
    photo_url1: '',
    photo_url2: '',
    photo_url3: '',
    lien_url: '',
    is_active: true, // Default to active
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData(prev => ({
      ...prev,
      content: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addArticle({ ...formData, user_id: user.userId });
      const message = `${user.nom} ${user.prenom} a créé un nouvel article de club : ${formData.title}`;
      await sendNotificationToAdmin(message, user.token);
      refreshList();
      onClose();
      setFormData({
        title: '',
        content: '',
        club: '',
        date_article: new Date().toISOString().split('T')[0],
        photo_url1: '',
        photo_url2: '',
        photo_url3: '',
        lien_url: '',
        is_active: true,
      });
    } catch (error) {
      console.error('Erreur lors de la création de l\'article', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Créer un Article</h3>
        <form onSubmit={handleSubmit}>
          <label className="label text-gray-800">Titre</label>
          <input
            type="text"
            name="title"
            placeholder="Titre de l'article"
            value={formData.title}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
            required
          />
          <label className="label text-gray-800">Club</label>
          <input
            type="text"
            name="club"
            placeholder="Nom du club"
            value={formData.club}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
            required
          />
          <label className="label text-gray-800">Contenu de l'article</label>
          <ReactQuill
            value={formData.content}
            onChange={handleContentChange}
            className="mb-3 dark:bg-slate-200"
            theme="snow"
            placeholder="Contenu de l'article"
          />
          <label className="label text-gray-800">Photo 1</label>
          <input
            type="url"
            name="photo_url1"
            placeholder="URL de la photo 1"
            value={formData.photo_url1}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <label className="label text-gray-800">Photo 2 (facultative)</label>
          <input
            type="url"
            name="photo_url2"
            placeholder="URL de la photo 2"
            value={formData.photo_url2}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <label className="label text-gray-800">Photo 3 (facultative)</label>
          <input
            type="url"
            name="photo_url3"
            placeholder="URL de la photo 3"
            value={formData.photo_url3}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <label className="label text-gray-800">Lien URL (article, site, réseaux sociaux)</label>
          <input
            type="text"
            name="lien_url"
            placeholder="URL du lien"
            value={formData.lien_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
            <label className="label cursor-pointer flex justify-start space-x-2 mb-4">
            <input
              type="checkbox"
              name="is_active"
              checked={formData.is_active}
              onChange={e => setFormData(prev => ({ ...prev, is_active: e.target.checked }))}
              className="checkbox checkbox-primary"
            />
            <span className="label-text flex justify-start">Afficher sur la page</span>
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateArticleModal;
