import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";
import "./LinkNav.css";
import MegaMenu from "./MegaMenu";
import LogoSection from "./LogoSection";
import Modal from "./../../components/Modal/Modal";
import { getSettings } from "../../services/settingsServices";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { GiKimono } from 'react-icons/gi';

const LinkNav = ({ user }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useState({
    adresse_club: "",
    tel_club: "",
    mail_club: "",
    facebook_club: "",
    instagram_club: "",
  });
  const [IconComponent, setIconComponent] = useState(() => GiKimono);

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
        const iconName = data[0].discipline_icone;
        if (iconName && iconName === 'GiKimono') {
          setIconComponent(() => GiKimono);
        }
      }
    };

    fetchSettings();
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (showMegaMenu) setShowMegaMenu(false);
  };

  const toggleMegaMenu = () => {
    setShowMegaMenu(!showMegaMenu);
    if (showDropdown) setShowDropdown(false);
  };

  const handleNavigate = (id) => {
    if (id === "accueil") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 200,
          behavior: "smooth",
        });
      }
    }
    toggleDropdown();
  };

  const handleInscription = (event) => {
    if (!user) {
      event.preventDefault();
      setShowModal(true);
    } else {
      toggleDropdown();
      navigate("/ajouter-inscription-adherent");
    }
  };

  return (
    <div className={`flex h-15 ${window.innerWidth < 400 ? "h-15" : ""} bg-white bg-opacity-85 text-black py-2 shadow-md items-center justify-between w-full gap-2`}>
      <div className="flex items-center ml-2">
        <NavLink to="/">
          <LogoSection />
        </NavLink>
      </div>
      <div className="flex items-center mr-2">
        <button
          onClick={toggleDropdown}
          aria-label="Menu"
          title="Menu"
          className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center w-sm h-10 gap-2"
        >
          <IoIosMenu className="text-xl" />
          Menu
        </button>
        <button
          onClick={toggleMegaMenu}
          aria-label="Application"
          title="Application"
          className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center w-sm h-10 gap-2 ml-4"
        >
          <IconComponent className="text-xl" />
          Application
        </button>
      </div>

      {showDropdown && (
        <div className="dropdown-content backdrop-filter backdrop-blur-sm shadow absolute top-full left-0 py-2 z-20 w-full bg-white/70">
          <div className="flex flex-col items-center">
            {[
              { id: "accueil", label: "Accueil" },
              { id: "about", label: "A Propos" },
              { id: "equipes", label: "Notre Equipe" },
              { id: "events", label: "Calendrier" },
              { id: "news", label: "Actualités" },
              { id: "galerie", label: "Galeries Photos" },
              { id: "articles", label: "Articles Commissions" },
              { id: "partenaires", label: "Nos Partenaires" },
              { id: "contact", label: "Nous Contacter" },
            ].map((section) => (
              <NavLink
                key={section.id}
                to="/"
                state={{ scrollTo: section.id }}
                onClick={() => handleNavigate(section.id)}
                className="block w-full px-4 py-3 text-xl hover:bg-primary hover:text-white text-black link-nav2 text-center"
              >
                {section.label}
              </NavLink>
            ))}
            <div className="flex flex-col items-center mt-4">
              <a href={`mailto:${settings.mail_club}`} className="text-black hover:text-primary">
                {settings.mail_club}
              </a>
              <p className="text-black">{settings.tel_club}</p>
              <div className="flex space-x-4 mt-2">
                <a href={settings.facebook_club} target="_blank" rel="noopener noreferrer" className="text-black hover:text-primary">
                  <FaFacebookF />
                </a>
                <a href={settings.instagram_club} target="_blank" rel="noopener noreferrer" className="text-black hover:text-primary">
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMegaMenu && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={toggleMegaMenu}
        >
          <div className="absolute top-32 right-0 left-0 mx-auto w-full max-w-[1280px] px-4 shadow-xl">
            <MegaMenu role_id={user ? user.role_id : null} settings={settings} onClose={toggleMegaMenu} />
          </div>
        </div>
      )}

      {showModal && (
        <Modal title="Inscription Adhérent" onClose={() => setShowModal(false)}>
          <div className="text-center space-y-4">
            <p>
              Merci de vous connecter à votre compte ou de vous inscrire pour faire une adhésion.
            </p>
            <div className="flex justify-around p-4">
              <button
                onClick={() => {
                  setShowModal(false);
                  toggleDropdown();
                }}
                className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary"
              >
                Annuler
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  toggleDropdown();
                  navigate("/login");
                }}
                className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary"
              >
                Se connecter
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  toggleDropdown();
                  navigate("/register");
                }}
                className="bg-primary text-white px-4 py-2 rounded-3xl shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary"
              >
                S'inscrire
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LinkNav;
