import React, { useState } from "react";
import { FaSave, FaTimes, FaPlus, FaTrash } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { createEvent } from "../../services/eventServices";
import { sendNotificationToAdmin } from '../../services/notificationServices';
import { useUser } from './../../contexts/UserContext';
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateEventModal = ({ isOpen, onClose, fetchEvents, userId }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    titre: "",
    description: "",
    start: "",
    end: "",
    type_event: "",
    level_event: "Club",
    cate_event: [],
    lieu_event: "",
    horaire_event: "",
    photo_url: "",
    agenda_url: "",
    is_active: false,
  });

  const [selectedCate, setSelectedCate] = useState("");

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleQuillChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      horaire_event: value,
    }));
  };

  const handleDescriptionChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleAddCategory = () => {
    if (selectedCate && !formData.cate_event.includes(selectedCate)) {
      setFormData((prev) => ({
        ...prev,
        cate_event: [...prev.cate_event, selectedCate],
      }));
      setSelectedCate("");
    }
  };

  const handleRemoveCategory = (category) => {
    setFormData((prev) => ({
      ...prev,
      cate_event: prev.cate_event.filter((cate) => cate !== category),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const eventData = {
        ...formData,
        cate_event: formData.cate_event.join(","),
        user_id: userId,
      };
      await createEvent(eventData);
      const message = `${user.nom} ${user.prenom} a créé un nouvel événement club : ${formData.type_event} / ${formData.titre}`;
      await sendNotificationToAdmin(message, user.token);
      fetchEvents();
      onClose();
      toast.success("Événement créé avec succès");
      setFormData({
        titre: "",
        description: "",
        start: "",
        end: "",
        type_event: "",
        level_event: "Club",
        cate_event: [],
        lieu_event: "",
        horaire_event: "",
        photo_url: "",
        agenda_url: "",
        is_active: false,
      });
    } catch (error) {
      console.error("Erreur lors de la création de l'événement", error);
      toast.error("Erreur lors de la création de l'événement");
    }
  };

  const typeEvents = ["Cours commun Club", "Tournoi Interclubs", "Stage Club"];

  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-gray-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Proposer un Événement
        </h3>
        <form onSubmit={handleSubmit}>
          <label className="text-sm font-semibold">Titre de l'événement</label>
          <input
            type="text"
            name="titre"
            placeholder="Titre de l'événement"
            value={formData.titre}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <label className="text-sm font-semibold">Description de l'événement</label>
          <ReactQuill
            value={formData.description}
            onChange={handleDescriptionChange}
            className="mb-3"
          />
          <label className="text-sm font-semibold">Date de l'événement</label>
          <input
            type="date"
            name="start"
            value={formData.start}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <label className="text-sm font-semibold">Date de fin de l'événement</label>
          <input
            type="date"
            name="end"
            value={formData.end}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label className="text-sm font-semibold">Type de l'événement</label>
          <select
            name="type_event"
            value={formData.type_event}
            onChange={handleChange}
            className="select select-bordered w-full mb-3"
            required
          >
            <option value="" disabled>
              Type de l'événement
            </option>
            {typeEvents.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <label className="text-sm font-semibold">Catégories concernées</label>
          <div className="flex items-center mb-3">
            <select
              name="cate_event"
              value={selectedCate}
              onChange={(e) => setSelectedCate(e.target.value)}
              className="select select-bordered w-full mr-2"
            >
              <option value="" disabled>
                Sélectionner une catégorie
              </option>
              {cateEvents.map((cate) => (
                <option key={cate} value={cate}>
                  {cate}
                </option>
              ))}
            </select>
            <button
              type="button"
              onClick={handleAddCategory}
              className="btn btn-primary"
            >
              <FaPlus />
            </button>
          </div>
          <div className="flex flex-wrap mb-3">
            {formData.cate_event.map((cate, index) => (
              <div
                key={index}
                className="bg-gray-200 dark:bg-gray-700 text-black dark:text-white p-2 rounded-lg m-1 flex items-center"
              >
                <span>{cate}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveCategory(cate)}
                  className="ml-2 text-red-500"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
          <label className="text-sm font-semibold">Lieu de l'événement</label>
          <input
            type="text"
            name="lieu_event"
            placeholder="Lieu de l'événement"
            value={formData.lieu_event}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <label className="text-sm font-semibold">Horaires de l'événement</label>
          <ReactQuill
            value={formData.horaire_event}
            onChange={handleQuillChange}
            className="mb-3"
          />
          <label className="text-sm font-semibold">URL de la photo</label>
          <input
            type="url"
            name="photo_url"
            placeholder="URL de la photo"
            value={formData.photo_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label className="text-sm font-semibold">URL de l'agenda</label>
          <input
            type="url"
            name="agenda_url"
            placeholder="URL de l'agenda"
            value={formData.agenda_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Créer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEventModal;
