import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/vieclub`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les articles de la vie de club
export const getAllArticles = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllArticles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des articles:", error);
  }
};

// Fonction pour récupérer un article par son id
export const getArticleById = async (articleId) => {
  try {
    const response = await axios.get(`${apiUrl}/getArticleById/${articleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de l'article:", error);
  }
};

/// POST
// Fonction pour ajouter un nouvel article
export const addArticle = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/addArticle`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'article:", error);
  }
};

/// PUT
// Fonction pour mettre à jour un article
export const updateArticle = async (articleId, data) => {
  try {
    const response = await axios.put(
      `${apiUrl}/editArticle/${articleId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'article:", error);
  }
};

/// DELETE
// Fonction pour supprimer un article
export const deleteArticle = async (articleId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteArticle/${articleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'article:", error);
  }
};



