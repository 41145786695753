import React, { useState, useEffect } from "react";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import { ToastContainer } from "react-toastify";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import InscriptionForm from "./InscriptionForm";
import InscriptionFiche from "./InscriptionFiche";
import { getActeursByUser } from "./../../services/userServices";
import { getActeurById } from "./../../services/acteursServices";
import { useUser } from "./../../contexts/UserContext";

const InscriptionPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userId = user?.userId;

  const [acteurs, setActeurs] = useState([]);

  const fetchActeurs = async () => {
    if (!userId) return;
    try {
      const acteursData = await getActeursByUser(userId);
      const detailedActeurs = await Promise.all(
        acteursData.map(async (acteur) => {
          const acteurDetails = await getActeurById(acteur.id);
          return acteurDetails;
        })
      );
      setActeurs(detailedActeurs);
    } catch (error) {
      console.error("Erreur lors de la récupération des acteurs :", error);
    }
  };

  useEffect(() => {
    fetchActeurs();
  }, [userId]);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Inscription & Fiche Acteur du Judo
      </h2>

      <InfoBubble title="Bienvenue sur la page d'Inscription">
        <p>
          Sur cette page, vous pouvez vous inscrire en tant qu'acteur du Judo.
          Que vous soyez arbitre ou commissaire sportif, membre du comité ou
          membre de commission, responsable de club ou enseignant.
          L'administrateur pourra ainsi vous octroyer un nouveau rôle donnant
          accès à du contenu supplémentaire.
        </p>
      </InfoBubble>

      <div className="flex flex-col items-center w-full px-4 mt-4">
        <InscriptionFiche acteurs={acteurs} fetchActeurs={fetchActeurs} />
        <InscriptionForm onActorCreated={fetchActeurs} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default InscriptionPage;
