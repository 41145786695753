import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { createAnnonce } from './../../services/annoncesServices'; // Assurez-vous que le chemin est correct
import { sendNotificationToAdmin } from '../../services/notificationServices';
import { toast } from 'react-toastify';
import { useUser } from './../../contexts/UserContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateAnnonceModal = ({ isOpen, onClose, fetchAnnonces, userId }) => {
      const { user } = useUser();
    const [formData, setFormData] = useState({
        category: '',
        title: '',
        content: '',
        image_url: '',
        club_user: '',
        tel_user: '',
        mail_user: '',
        date_annonce: '',
        is_active: true
    });

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleContentChange = (value) => {
        setFormData((prev) => ({
            ...prev,
            content: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSubmit = { ...formData, user_id: userId }; // Ajout explicite de user_id
        try {
            await createAnnonce(dataToSubmit);
            const message = `${user.nom} ${user.prenom}  a créé une nouvelle petite annonce : ${formData.category} / ${formData.title}`;
            await sendNotificationToAdmin(message, userId);
            fetchAnnonces();
            onClose();
            toast.success('Annonce créée avec succès');
            setFormData({ // Réinitialiser les champs du formulaire
                category: '',
                title: '',
                content: '',
                image_url: '',
                club_user: '',
                tel_user: '',
                mail_user: '',
                date_annonce: '',
                is_active: true
            });
        } catch (error) {
            console.error('Erreur lors de la création de l\'annonce', error);
            toast.error('Erreur lors de la création de l\'annonce');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white p-4 rounded-lg shadow-lg dark:bg-gray-400">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Créer une Annonce</h3>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="category" className="text-sm">Catégorie de l'annonce</label>
                    <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className="select select-bordered w-full mb-3"
                        required
                    >
                        <option value="" disabled>Catégorie de l'annonce</option>
                        <option value="Recherche Enseignant">Recherche Enseignant</option>
                        <option value="Enseignant cherche club">Enseignant cherche club</option>
                        <option value="Recherche Matériel">Recherche Matériel</option>
                        <option value="Don Matériel">Don Matériel</option>
                        <option value="Autres">Autres</option>
                    </select>
                    <label htmlFor="title" className="text-sm">Titre de l'annonce</label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Titre de l'annonce"
                        value={formData.title}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <label htmlFor="content" className="text-sm">Contenu de l'annonce</label>
                    <ReactQuill
                        value={formData.content}
                        onChange={handleContentChange}
                        className="mb-3"
                    />
                    <label htmlFor="image_url" className="text-sm">URL de l'image</label>
                    <input
                        type="url"
                        name="image_url"
                        placeholder="URL de l'image"
                        value={formData.image_url}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label htmlFor="club_user" className="text-sm">Club</label>
                    <input
                        type="text"
                        name="club_user"
                        placeholder="Nom du club"
                        value={formData.club_user}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label htmlFor="tel_user" className="text-sm">Contact Téléphonique</label>
                    <input
                        type="tel"
                        name="tel_user"
                        placeholder="Téléphone"
                        value={formData.tel_user}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label htmlFor="mail_user" className="text-sm">Contact Email</label>
                    <input
                        type="email"
                        name="mail_user"
                        placeholder="Adresse email"
                        value={formData.mail_user}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <label htmlFor="date_annonce" className="text-sm">Date de l'annonce</label>
                    <input
                        type="date"
                        name="date_annonce"
                        value={formData.date_annonce}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                    />
                    <div className="flex items-center mb-3">
                        <input
                            type="checkbox"
                            name="is_active"
                            checked={formData.is_active}
                            onChange={handleChange}
                            className="form-checkbox mr-2"
                        />
                        <label htmlFor="is_active" className="mr-2">Afficher sur la page</label>
                    </div>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Créer" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateAnnonceModal;
