import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLastCommissionArticles } from "../../services/articlescommissionsServices";
import Button from "../../components/Button/Button";
import { useUser } from "../../contexts/UserContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LastArticlesCommissions = () => {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const latestArticles = await getLastCommissionArticles();
        setArticles(latestArticles);
      } catch (error) {
        console.error("Failed to fetch articles", error);
      }
    };

    fetchArticles();
  }, []);

  const handleReadMore = (articleId) => {
    navigate(`/commissions`);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-3xl font-extrabold text-center mt-2 font-montserrat uppercase">
        Derniers Articles de Commissions
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
        {articles.map((article) => {
          const images = [article.photo_url1, article.photo_url2, article.photo_url3].filter(Boolean);
          const hasImages = images.length > 0;

          return (
            <div key={article.id} className="flex flex-col mb-6 shadow-lg bg-slate-200 dark:bg-slate-400 rounded-lg overflow-hidden w-full">
              {hasImages && (
                <div className="w-full">
                  {images.length > 1 ? (
                    <Slider {...settings}>
                      {images.map((image, index) => (
                        <div key={index} className="w-full h-full">
                          <img src={image} alt={`Slide ${index + 1}`} className="w-full h-full object-contain" />
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <img src={images[0]} alt="Article cover" className="w-full h-full object-contain" />
                  )}
                </div>
              )}
              <div className="p-4 flex flex-col justify-between h-[400px]"> {/* Hauteur fixe pour la carte */}
                <div className="overflow-y-auto"> {/* Contenu avec défilement vertical */}
                  <h3 className="text-gray-600 font-bold text-xl dark:text-white">{article.titre}</h3>
                  <div className="text-gray-600 mt-2 mb-4 dark:text-slate-200">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: article.contenu,
                      }}
                    />
                  </div>
                  <p className="text-gray-500 dark:text-slate-200 text-xs">
                    Publié le: {new Date(article.date_article).toLocaleDateString()} par {article.group?.nom}
                  </p>
                </div>
                <Button
                  text="Voir plus d'articles"
                  onClick={() => handleReadMore(article.id)}
                  className="self-center mt-4"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LastArticlesCommissions;
