import React, { useEffect } from "react";
import Header from './Header';
import About from './About';
import Diaporama from './Diaporama';
import ContactForm from './ContactForm';
import useScrollToTop from "../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import { useUser } from "./../../contexts/UserContext";
import AuthIcons from "./../../hooks/AuthIcons";
import NosEquipes from "./NosEquipes";
import PartenairesCarousel from "./PartenairesCarousel";
import LastGallerie from "./LastGallerie";
import LastNews from "./LastNews";
import UpcomingEvents from "./UpcomingEvents";
import LastAnnoncesClub from "./LastAnnoncesClub";
import LastArticlesCommissions from "./LastArticlesCommissions";
import LastVieClubs from "./LastVieClubs";
import LinkNav from "./../Navbar/LinkNav";
import { saveVisite } from "../../services/visitesServices";
import "./Home.css";

const HomePage = () => {
  useScrollToTop();
  const { user } = useUser();
   useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Obtenir la date au format AAAA-MM-JJ
    const lastVisitDate = localStorage.getItem("lastVisitDate");

    if (lastVisitDate !== today) {
      // Si la dernière visite enregistrée est différente d'aujourd'hui, on enregistre une nouvelle visite
      saveVisite();
      localStorage.setItem("lastVisitDate", today);
    }
  }, []);

  return (
    <div>
      <LinkNav user={user} />
      {!user && <AuthIcons />}
      <ScrollIndicator />
      <div id="home">
        <Header />
      </div>
      <div id="about">
        <About />
      </div>
      <div>
        <Diaporama />
      </div>
      <div id="equipes">
        <NosEquipes />
      </div>
      <div id="events">
        <UpcomingEvents />
      </div>
      <div id="news">
        <LastNews />
      </div>
      <div id="galerie">
        <LastGallerie />
      </div>
      <div id="articles">
        <LastArticlesCommissions />
      </div>
      <div id="annonces">
        <LastAnnoncesClub />
      </div>
      <div id="vieclubs">
        <LastVieClubs />
      </div>
      <div id="partenaires">
        <PartenairesCarousel />
      </div>
      <div id="contact" >
        <ContactForm />
      </div>
    </div>
  );
};

export default HomePage;
