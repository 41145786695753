import React, { useState } from 'react';
import { FaUserFriends, FaBuilding, FaHome, FaFileInvoice, FaCheck, FaHandshake, FaChartBar } from 'react-icons/fa';
import { useUser } from '../../contexts/UserContext';
import { getRoleFromRoleId } from '../../utils/roleHelper';
import useScrollToTop from '../../hooks/useScrollToTop';
import SettingsForm from './SettingsForm';
import HomeSettingsForm from './HomeSettingsForm';
import PaiementSettingsForm from './PaiementSettingsForm';
import TeamManagement from './TeamManagement';
import SettingsPartenaires from './SettingsPartenaires'; // Import du nouveau composant
import VisitesLineChart from './VisitesLineChart';
import { ToastContainer } from 'react-toastify';

const SettingsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [activeTab, setActiveTab] = useState('equipes');

  const tabClasses = (tab) =>
    `tab tab-lifted ${activeTab === tab ? 'tab-active bg-blue-500 text-white' : 'text-black'}`;

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Paramètres du Comité
      </h2>

      <div className="w-full">
        <div className="tabs tabs-lifted flex-wrap justify-center mb-4 text-black">

          <button className={tabClasses('equipes')} onClick={() => setActiveTab('equipes')}>
            <FaUserFriends className={`mr-2 ${activeTab === 'equipes' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Equipes</span>
          </button>
          <button className={tabClasses('comite')} onClick={() => setActiveTab('comite')}>
            <FaBuilding className={`mr-2 ${activeTab === 'comite' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Comité</span>
          </button>
          <button className={tabClasses('pageAccueil')} onClick={() => setActiveTab('pageAccueil')}>
            <FaHome className={`mr-2 ${activeTab === 'pageAccueil' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Page Accueil</span>
          </button>
          <button className={tabClasses('reglements')} onClick={() => setActiveTab('reglements')}>
            <FaFileInvoice className={`mr-2 ${activeTab === 'reglements' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Règlements</span>
          </button>
          <button className={tabClasses('partenaires')} onClick={() => setActiveTab('partenaires')}>
            <FaHandshake className={`mr-2 ${activeTab === 'partenaires' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Partenaires</span>
          </button>
          <button className={tabClasses('compteurs')} onClick={() => setActiveTab('compteurs')}>
            <FaChartBar className={`mr-2 ${activeTab === 'compteurs' ? 'text-black' : 'text-black'}`} />
            <span className="text-black">Compteurs de Visite</span>
          </button>
        </div>

        <div className="flex flex-col w-full px-2 mb-8 gap-5 items-center text-black">
          {activeTab === 'equipes' && <TeamManagement />}
          {activeTab === 'comite' && <SettingsForm />}
          {activeTab === 'pageAccueil' && <HomeSettingsForm />}
          {activeTab === 'reglements' && <PaiementSettingsForm />}
          {activeTab === 'partenaires' && <SettingsPartenaires />}
          {activeTab === 'compteurs' && <VisitesLineChart />}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SettingsPage;
