import React, { useState, useEffect } from "react";
import { getNews, deleteNews, createNews } from "./../../services/newsServices";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import useScrollToTop from "./../../hooks/useScrollToTop";
import Button from "./../../components/Button/Button";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import NewsCard from './NewsCard';
import CreateNewsModal from "./CreateNewsModal";
import EditNewsModal from "./EditNewsModal";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "./../../hooks/ScrollIndicator";

const NewsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [newsList, setNewsList] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getNews();
        setNewsList(data);
      } catch (error) {
        console.error('Failed to fetch news', error);
      }
    };
    fetchNews();
  }, []);

  const canViewInactive = (news) => {
    return news.is_active || userRole === "Administrateur" || userRole === "Bureau";
  };

  const handleAddNewsClick = () => {
    setCurrentNews(null); // Clear current news
    setIsCreateModalOpen(true); // Open create modal
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateSuccess = async (newsData) => {
    try {
      const newNews = await createNews(newsData);
      setCurrentNews(newNews);
      closeCreateModal();
      await refreshNewsList();
      toast.success('Actualité créée avec succès');
    } catch (error) {
      console.error("Erreur lors de la création de l'actualité:", error);
      toast.error("Erreur lors de la création de l'actualité.");
    }
  };

  const handleEditNews = (news) => {
    if (userRole === "Administrateur" || userRole === "Bureau") {
      setCurrentNews(news); // Set current news to be edited
      setIsEditModalOpen(true); // Open the modal
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentNews(null);
  };

  const handleEditSuccess = async () => {
    closeEditModal();
    await refreshNewsList();
    toast.success('Actualité mise à jour avec succès');
  };

  const handleDeleteNews = async (id) => {
    if (userRole === "Administrateur" || userRole === "Bureau") {
      try {
        await deleteNews(id);
        setNewsList(newsList.filter(news => news.id !== id)); // Update state after deletion
        toast.success('Actualité supprimée avec succès');
      } catch (error) {
        console.error('Failed to delete news', error);
        toast.error('Erreur lors de la suppression de l\'actualité');
      }
    }
  };

  const refreshNewsList = async () => {
    try {
      const updatedNewsList = await getNews();
      setNewsList(updatedNewsList);
      return updatedNewsList;  // Retourner la promesse pour un chaînage correct
    } catch (error) {
      console.error('Failed to refresh news', error);
      throw error;  // Propager l'erreur pour le catch
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Actualités & Résultats
      </h2>
      <InfoBubble title="Bienvenue sur la page Actualités & Résultats">
        <p>Sur cette page, vous pouvez voir toutes les dernières actualités du comité départemental, les résultats hors départements de nos judokas girondins </p>
      </InfoBubble>
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-center mb-4 gap-2">
          {(userRole === "Administrateur" || userRole === "Bureau") && (
            <>
              <Button text="Ajouter une Actualité" onClick={handleAddNewsClick} className="mb-4" />
              <Button text={showInactive ? "Cacher les actualités inactives" : "Voir les actualités inactives"} onClick={() => setShowInactive(!showInactive)} className="mb-4" />
            </>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
          {newsList
            .filter(news => canViewInactive(news) || (showInactive && !news.is_active))
            .map(news => (
              <NewsCard 
                key={news.id} 
                news={news} 
                onDelete={() => handleDeleteNews(news.id)}
                canModify={userRole === "Administrateur" || userRole === "Bureau"}
                refreshList={() => refreshNewsList()}
              />
            ))}
        </div>
      </div>
      <CreateNewsModal isOpen={isCreateModalOpen} onClose={closeCreateModal} onCreateSuccess={handleCreateSuccess} />
      <EditNewsModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        refreshList={refreshNewsList}
        news={currentNews}
      />
      <ToastContainer />
    </div>
  );
};

export default NewsPage;
