import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/articlesCommissions`;
const token = localStorage.getItem("token");

//GET
// Fonction pour afficher tous les articles de commissions
export const getCommissionArticles = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllArticlesCommissions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du chargement des articles de commissions:",
      error
    );
    return [];
  }
};

// Fonction pour compter le nombre d'articles de commissions
export const countCommissionArticles = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getArticlesCommissionsCount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du comptage des articles de commissions:",
      error
    );
    return [];
  }
};

// Fonction pour afficher les 2 derniers articles de commissions pour la HomePage
export const getLastCommissionArticles = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getLastTwoArticlesCommissions`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du chargement des derniers articles de commissions:",
      error
    );
    return [];
  }
};

/// POST
// Fonction pour ajouter un article de commissions
export const addArticle = async (article) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addArticlesCommissions`,
      article,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'article de commissions:", error);
  }
};

/// PUT
// Fonction pour modifier un article de commissions
export const updateArticle = async (id, article) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateArticlesCommissions/${id}`,
      article,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'article de commissions:", error);
  }
};


// Fonction pour toggle l'état d'un article de commissions
export const toggleArticleState = async (id, is_active) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/toggleArticlesCommissions/${id}`,
      { is_active },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du basculement de l'état de l'article de commissions:",
      error
    );
  }
};

/// DELETE
// Fonction pour supprimer un article de commissions
export const deleteArticle = async (id) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteArticlesCommissions/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de l'article de commissions:",
      error
    );
  }
};
