import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { addGallery } from './../../services/gallerieServices'; // Assurez-vous que cette fonction existe et est correctement importée
import { useUser } from './../../contexts/UserContext';
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateAlbumModal = ({ isOpen, onClose, refreshList }) => {
    const { user } = useUser();
    const userRole = getRoleFromRoleId(user?.role_id);
    const [formData, setFormData] = useState({
        titre: '',
        description: '',
        is_active: false, // Par défaut, on peut vouloir créer un album actif
    });

    useEffect(() => {
        // Vérifie si cette logique est nécessaire, par exemple pour définir un utilisateur spécifique comme créateur de l'album
    }, [user]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addGallery(formData);
            refreshList();
            onClose();
            setFormData({ titre: '', description: '', is_active: true });
        } catch (error) {
            console.error('Erreur lors de la création de l\'album', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Créer un Album</h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="titre"
                        placeholder="Titre de l'album"
                        value={formData.titre}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3 dark:bg-slate-200"
                        required
                    />
                    <ReactQuill
                        value={formData.description}
                        onChange={(content) => setFormData(prev => ({ ...prev, description: content }))}
                        className="mb-3"
                    />
                    {/* <textarea
                        name="description"
                        placeholder="Description de l'album"
                        value={formData.description}
                        onChange={handleChange}
                        className="textarea textarea-bordered w-full mb-3 dark:bg-slate-200"
                        required
                    /> */}
                    <label className="label cursor-pointer flex items-center space-x-2 mb-4">
                        <input
                            type="checkbox"
                            name="is_active"
                            checked={formData.is_active}
                            onChange={handleChange}
                            className="checkbox checkbox-primary"
                        />
                        <span className="label-text">Actif</span>
                    </label>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateAlbumModal;
