import React, { useState, useEffect } from 'react';
import ButtonEdit from '../../components/Button/ButtonEdit';
import ButtonDelete from '../../components/Button/ButtonDelete';
import ButtonTel from '../../components/Button/ButtonTel';
import ButtonMail from '../../components/Button/ButtonMail';
import { deletePartenaireKata } from '../../services/partenaireskataServices';
import EditPartenaireModal from './EditPartenaireModal';
import { getUserById } from '../../services/userServices';
import { toast } from 'react-toastify';

const PartenaireKataCard = ({ partenaire, userRole, refreshPartenairesList, user }) => {
  const defaultImage = "/img/default_image.jpg";
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    const fetchAuthor = async () => {
      if (partenaire.user_id) {
        const authorData = await getUserById(partenaire.user_id);
        setAuthor(authorData);
      }
    };

    fetchAuthor();
  }, [partenaire.user_id]);

  const handleDeletePartenaire = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce partenaire ?")) {
      try {
        await deletePartenaireKata(partenaire.id);
        toast.success("Partenaire supprimé avec succès.");
        refreshPartenairesList(); // Call to refresh the partners list after deletion
      } catch (error) {
        console.error("Erreur lors de la suppression du partenaire:", error);
        toast.error("Erreur lors de la suppression du partenaire.");
      }
    }
  };

  // Mapping des images des grades
  const gradeImages = {
    "1er Dan": "/img/belt/ceinture_judo_1D.png",
    "2ème Dan": "/img/belt/ceinture_judo_2D.png",
    "3ème Dan": "/img/belt/ceinture_judo_3D.png",
    "4ème Dan": "/img/belt/ceinture_judo_4D.png",
    "5ème Dan": "/img/belt/ceinture_judo_5D.png",
    "6ème Dan": "/img/belt/ceinture_judo_rouge_blanche.png",
    "7ème Dan": "/img/belt/ceinture_judo_rouge_blanche.png",
    "8ème Dan": "/img/belt/ceinture_judo_rouge.png",
    "9ème Dan": "/img/belt/ceinture_judo_rouge.png",
    "10ème Dan": "/img/belt/ceinture_judo_rouge.png",
  };

  // Check if the user can edit or delete the partner
  const canEditOrDelete = user?.userId === partenaire.user_id || userRole === 1 || userRole === 2 || userRole === 5;

  return (
    <div className={`flex flex-col mb-6 shadow-lg bg-slate-200 dark:bg-slate-400 rounded-lg overflow-hidden w-full`}>
      <div className="flex justify-center items-center">
        <img src={gradeImages[partenaire.passage_grade]} alt={partenaire.passage_grade} className="object-contain w-48" />
      </div>
      <div className="p-4 flex flex-col justify-between">
        <div>
          <h3 className="text-gray-600 font-bold text-xl dark:text-white text-center lg:text-left">Pour le passage du {partenaire.passage_grade}</h3>
          {author && (
            <div className="flex items-center mt-2 mb-4">
              <img src={author.photoURL || defaultImage} alt={author.nom} className="w-10 h-10 object-cover rounded-full mr-2" />
              <div className="text-gray-600 dark:text-slate-200">
                <p className="font-semibold">{author.nom} {author.prenom}</p>
                <p className='text-xs'>{partenaire.club}</p>
              </div>
            </div>
          )}
          <p className="text-gray-600 mt-2 mb-4 dark:text-slate-200" dangerouslySetInnerHTML={{ __html: partenaire.content }}></p>
        </div>
        <div className='flex flex-row gap-4 justify-center mt-4'>
          {partenaire.tel && <ButtonTel tel={partenaire.tel} />}
          {partenaire.mail && <ButtonMail mail={partenaire.mail} />}
          {canEditOrDelete && (
            <>
              <ButtonEdit onClick={() => setEditModalOpen(true)} />
              <ButtonDelete onClick={handleDeletePartenaire} />  
            </>
          )}
        </div>
      </div>
      {editModalOpen && (
        <EditPartenaireModal
          partenaire={partenaire}
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          refreshList={refreshPartenairesList}
        />
      )}
    </div>
  );
};

export default PartenaireKataCard;
