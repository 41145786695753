import React, { useState, useEffect } from "react";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import { getActeursByUser } from "./../../services/userServices";
import { getAllEventsByActorId } from "./../../services/acteureventServices";
import EventDetailsModal from "./../../pages/CalendarPage/EventDetailsModal";
import ObservationsModal from "./ObservationsModal"; // Import the new modal
import { FaInfoCircle } from "react-icons/fa";

const ConvocationsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isObservationsModalOpen, setIsObservationsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedObservations, setSelectedObservations] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!userId) return;

      try {
        const acteursResponse = await getActeursByUser(userId);
        const allEvents = [];
        for (const acteur of acteursResponse) {
          const eventsResponse = await getAllEventsByActorId(acteur.id);
          allEvents.push(...eventsResponse.Events);
        }
        setEvents(allEvents);
      } catch (error) {
        console.error("Erreur lors du chargement des événements", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [userId]);

  if (!userId) {
    return <div>Chargement de l'utilisateur...</div>;
  }

  if (loading) {
    return <div>Chargement des événements...</div>;
  }

  const openEventModal = (event) => {
    setSelectedEvent(event);
    setIsEventModalOpen(true);
  };

  const closeEventModal = () => {
    setIsEventModalOpen(false);
    setSelectedEvent(null);
  };

  const openObservationsModal = (observations) => {
    setSelectedObservations(observations);
    setIsObservationsModalOpen(true);
  };

  const closeObservationsModal = () => {
    setIsObservationsModalOpen(false);
    setSelectedObservations(null);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ARBITRES & CS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Convocations Départementales
      </h2>
      <div className="overflow-x-auto shadow-xl">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Date</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Titre</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Type</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Poste</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Note</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Observations</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id} className="text-left hover:bg-gray-200">
                <td className="px-5 py-3">{new Date(event.start).toLocaleDateString()}</td>
                <td className="px-5 py-3">{event.titre}</td>
                <td className="px-5 py-3">{event.type_event}</td>
                <td className="px-5 py-3">{event.ActeurEvent.poste}</td>
                <td className="px-5 py-3">{event.ActeurEvent.note}</td>
                <td className="px-5 py-3">
                  {event.ActeurEvent.observations ? (
                    <button
                      onClick={() => openObservationsModal(event.ActeurEvent.observations)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      Observations
                    </button>
                  ) : (
                    "Aucune"
                  )}
                </td>
                <td className="px-5 py-3">
                  <button
                    onClick={() => openEventModal(event)}
                    className="text-blue-500 hover:text-blue-700 text-2xl"
                  >
                    <FaInfoCircle />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedEvent && (
        <EventDetailsModal
          event={selectedEvent}
          isOpen={isEventModalOpen}
          onClose={closeEventModal}
          userRole={userRole} // Passer userRole à EventDetailsModal
        />
      )}
      {selectedObservations && (
        <ObservationsModal
          observations={selectedObservations}
          isOpen={isObservationsModalOpen}
          onClose={closeObservationsModal}
        />
      )}
    </div>
  );
}

export default ConvocationsPage;
