import React, { useState } from 'react';
import { FaCalendarAlt, FaMapMarkerAlt, FaInfoCircle } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import EventDetailsModal from './../../pages/CalendarPage/EventDetailsModal';

const EventCardUser = ({ event, userRole }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? '' : date.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const handleInfo = () => {
    setModalOpen(true);
  };

  const isEventPast = new Date(event.end || event.start) < new Date();
  const eventBgClass = isEventPast ? 'bg-slate-300' : 'bg-slate-100';

  const getTypeColor = (type) => {
    switch (type) {
      case "Cours commun Club":
        return "bg-yellow-500";
      case "Stage Club":
        return "bg-green-500";
      case "Tournoi Interclubs":
        return "bg-blue-500";
      case "Compétition":
        return "bg-red-500";
      case "Animation":
        return "bg-purple-500";
      case "Stage & Regroupement Technique":
        return "bg-indigo-500";
      case "Passage de Grade":
        return "bg-orange-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <div className={`${eventBgClass} border-slate-300 dark:border-slate-600 border-2 dark:bg-slate-500 shadow-md rounded-lg overflow-hidden flex flex-col h-full font-montserrat relative`}>
      <img src={event.photo_url} alt={event.titre} className="w-full h-64 object-cover flex-shrink-0" />
      <div className={`absolute top-0 left-0 px-2 py-1 ${getTypeColor(event.type_event)} text-white text-xs p-1 rounded-br-lg`}>
        {event.type_event}
      </div>
      <div className="flex flex-col flex-grow p-4 justify-between">
        <div>
          <h3 className="text-normal font-bold text-gray-600 dark:text-slate-100">{event.titre}</h3>
          <div className="text-sm text-gray-600 dark:text-slate-300" dangerouslySetInnerHTML={{ __html: event.description }} />
          <div className="mt-2">
            <div className="flex items-center text-gray-700 text-sm dark:text-slate-300">
              <FaCalendarAlt className="mr-2" />
              {formatDate(event.start)}
              {event.end && !isNaN(new Date(event.end).getTime()) && (
                <>
                  {" - "}
                  {formatDate(event.end)}
                </>
              )}
            </div>
            <div className="flex items-center text-gray-700 text-sm mt-1 dark:text-slate-300">
              <FaMapMarkerAlt className="mr-2" />
              {event.lieu_event || 'Lieu à préciser'}
            </div>
            {event.agenda_url && (
              <a href={event.agenda_url} target="_blank" rel="noopener noreferrer" className="flex items-center text-black hover:text-primary dark:text-slate-300 dark:hover:text-primary text-sm mt-1">
                <FaInfoCircle className="mr-2" />
                Ajouter à votre agenda
              </a>
            )}
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <Button text="Plus d'infos" onClick={handleInfo} icon={FaInfoCircle} className="w-sm gap-2 text-sm" />
        </div>
      </div>
      <EventDetailsModal event={event} isOpen={modalOpen} onClose={() => setModalOpen(false)} userRole={userRole} />
    </div>
  );
};

export default EventCardUser;
