import React, { useState, useEffect } from "react";
import { FaArrowCircleUp } from "react-icons/fa";

const ScrollIndicator = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Calculer la position verticale de l'icône en prenant en compte la navbar
  const iconPositionY = () => {
    const windowHeight = window.innerHeight;
    const totalHeight = document.body.scrollHeight - windowHeight;
    let scrolled = (scrollY / totalHeight) * (windowHeight - 150); // Ajustement pour éviter navbar et footer
    scrolled = Math.max(135, scrolled); // Éviter la navbar
    scrolled = Math.min(windowHeight - 150, scrolled); // Éviter le footer
    return scrolled;
  };

  // Fonction pour remonter en haut de la page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="fixed -left-11 z-50 w-[80px] items-end"
      style={{
        top: `${iconPositionY()}px`,
        transition: "none", // Pas de transition
      }}
    >
      <div
        className="bg-blue-500 text-white p-2 pl-12 rounded-full cursor-pointer hover:translate-x-2 transition-transform duration-300"
        onClick={scrollToTop}
      >
        <FaArrowCircleUp size={25} />
      </div>
    </div>
  );
};

export default ScrollIndicator;
