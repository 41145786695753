import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { getAllTeamMembers } from "../../services/teamServices";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NosEquipesCarousel.css"; // Assurez-vous de créer ce fichier CSS pour les styles personnalisés

const NosEquipes = () => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const data = await getAllTeamMembers();
      setTeam(data);
    };

    fetchTeamMembers();
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (team.length === 0) {
    return null; // Ne rien afficher s'il n'y a pas de membres d'équipe
  }

  return (
    <section
      className="py-3 bg-gray-100 dark:bg-gray-700 dark:text-white"
      id="nosEquipes"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-extrabold my-4 text-center">LES MEMBRES DU COMITÉ</h2>
        <Slider {...settings}>
          {team.map((member) => (
            <div key={member.id} className="p-4">
              <div className="bg-white dark:bg-slate-500 shadow-md rounded-lg p-4 flex flex-col items-center">
                <img
                  src={member.photoURL}
                  alt={`${member.nom} ${member.prenom}`}
                  className="rounded-full mb-2 w-32 h-32 object-cover"
                />
                <h3 className="text-lg text-center">
                  <span className="font-semibold">{member.nom} </span>
                  <span className="font-normal">{member.prenom}</span>
                </h3>
                <p className="italic text-xs text-center"
                dangerouslySetInnerHTML={{ __html: member.fonction }}>
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default NosEquipes;
