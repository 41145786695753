import React, { useState } from "react";
import { toast } from "react-toastify";
import { FaSave, FaTimes, FaPlus, FaTrash } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importation des styles de React-Quill
import Button from "../../components/Button/Button";
import { createEvent } from "../../services/eventServices";
// import { getSettings } from '../../../services/settingsServices';

const CreateEventModal = ({ isOpen, onClose, refreshList }) => {
  const [formData, setFormData] = useState({
    titre: "",
    description: "",
    start: "",
    end: "",
    type_event: "",
    level_event: "",
    cate_event: [],
    lieu_event: "",
    horaire_event: "", // Gardez la clé pour l'horaire_event
    photo_url: "",
    agenda_url: "",
    is_active: true,
  });
  const [selectedCate, setSelectedCate] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleQuillChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      horaire_event: value,
    }));
  };

  const handleAddCategory = () => {
    if (selectedCate && !formData.cate_event.includes(selectedCate)) {
      setFormData((prev) => ({
        ...prev,
        cate_event: [...prev.cate_event, selectedCate],
      }));
      setSelectedCate("");
    }
  };

  const handleRemoveCategory = (category) => {
    setFormData((prev) => ({
      ...prev,
      cate_event: prev.cate_event.filter((cate) => cate !== category),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const eventData = {
        ...formData,
        cate_event: formData.cate_event.join(","),
      };
      await createEvent(eventData);
      refreshList();
      onClose();
      toast.success("Événement créé avec succès.");
      setFormData({
        titre: "",
        description: "",
        start: "",
        end: "",
        type_event: "",
        level_event: "",
        cate_event: [],
        lieu_event: "",
        horaire_event: "", // Réinitialisez le champ
        photo_url: "",
        agenda_url: "",
        is_active: true,
      });
    } catch (error) {
      console.error("Erreur lors de la création de l'événement", error);
      toast.error("Erreur lors de la création de l'événement.");
    }
  };

  // Liste des types d'événements (à ajuster selon vos données)
  const typeEvents = [
    "Compétition",
    "Animation",
    "Stage & Regroupement Technique",
    "Passage de Grade",
    "Entraînement Départemental",
    "Stage Sportif Départemental",
    "Cours commun Club",
    "Stage Club",
    "Tournoi Interclubs",
    "Autre",
  ];

  // Liste des niveaux d'événements (à ajuster selon vos données)
  const levelEvents = [
    "Club",
    "Départemental",
    "Régional",
    "National",
    "International",
  ];

  // Liste des catégories d'événements
  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Créer un Événement
        </h3>
        <form onSubmit={handleSubmit}>
          <label htmlFor="titre" className="text-gray-800">
            Titre
          </label>
          <input
            type="text"
            name="titre"
            placeholder="Titre de l'événement"
            value={formData.titre}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <label htmlFor="description" className="text-gray-800">
            Description
          </label>
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
            className="textarea textarea-bordered w-full mb-3"
          />
          <label htmlFor="start" className="text-gray-800">
            Date de début
          </label>
          <input
            type="date"
            name="start"
            value={formData.start}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <label htmlFor="end" className="text-gray-800">
            Date de fin
          </label>
          <input
            type="date"
            name="end"
            value={formData.end}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label htmlFor="type_event" className="text-gray-800">
            Type de l'événement
          </label>
          <select
            name="type_event"
            value={formData.type_event}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          >
            <option value="">Sélectionner le type de l'événement</option>
            {typeEvents.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <label htmlFor="level_event" className="text-gray-800">
            Niveau de l'événement
          </label>
          <select
            name="level_event"
            value={formData.level_event}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          >
            <option value="">Sélectionner le niveau de l'événement</option>
            {levelEvents.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
          <label htmlFor="cate_event" className="text-gray-800">
            Catégories de l'événement
          </label>
          <div className="flex items-center mb-3">
            <select
              name="cate_event"
              value={selectedCate}
              onChange={(e) => setSelectedCate(e.target.value)}
              className="input input-bordered w-full mr-2"
            >
              <option value="">Sélectionner une catégorie</option>
              {cateEvents.map((cate) => (
                <option key={cate} value={cate}>
                  {cate}
                </option>
              ))}
            </select>
            <button
              type="button"
              onClick={handleAddCategory}
              className="btn btn-primary"
            >
              <FaPlus />
            </button>
          </div>
          <div className="flex flex-wrap mb-3">
            {formData.cate_event.map((cate, index) => (
              <div
                key={index}
                className="bg-gray-200 dark:bg-gray-700 text-black dark:text-white p-2 rounded-lg m-1 flex items-center"
              >
                <span>{cate}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveCategory(cate)}
                  className="ml-2 text-red-500"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
          <label htmlFor="lieu_event" className="text-gray-800">
            Lieu de l'événement
          </label>
          <input
            type="text"
            name="lieu_event"
            placeholder="Lieu de l'événement"
            value={formData.lieu_event}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label htmlFor="horaire_event" className="text-gray-800">
            Horaires de l'événement
          </label>
          <ReactQuill
            value={formData.horaire_event}
            onChange={handleQuillChange}
            className="mb-3"
          />
          <label htmlFor="photo_url" className="text-gray-800">
            URL de la photo de l'événement
          </label>
          <input
            type="url"
            name="photo_url"
            placeholder="URL de la photo de l'événement"
            value={formData.photo_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label htmlFor="agenda_url" className="text-gray-800">
            URL de l'agenda de l'événement
          </label>
          <input
            type="url"
            name="agenda_url"
            placeholder="URL de l'agenda de l'événement"
            value={formData.agenda_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="is_active"
              checked={formData.is_active}
              onChange={handleChange}
            />
            <span>Actif</span>
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEventModal;
