// src/components/Modal/Modal.js
import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import Button from '../../components/Button/Button';

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold uppercase p-3 text-white bg-gray-500 rounded-md shadow">{title}</h3>
          <Button icon={FaTimes} onClick={onClose} className="btn-ghost" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
