import React, { useState, useEffect } from "react";
import { getGroupsListCommissions } from "../../services/groupServices";
import { countCommissionArticles } from "../../services/articlescommissionsServices";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import { useUser } from "../../contexts/UserContext";
import useScrollToTop from "../../hooks/useScrollToTop";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import Button from "../../components/Button/Button";
import Modal from "./ActeurCommissionModal";
import { Link } from "react-router-dom";

const CommissionsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const [groups, setGroups] = useState([]);
  const [articlesCounts, setArticlesCounts] = useState({});
  const [openGroupIndex, setOpenGroupIndex] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    const fetchCommissions = async () => {
      try {
        const [groupsData, articlesCountsData] = await Promise.all([
          getGroupsListCommissions(),
          countCommissionArticles(),
        ]);

        setGroups(groupsData);

        const counts = {};
        articlesCountsData.forEach((item) => {
          counts[item.group_id] = item.group_count;
        });
        setArticlesCounts(counts);
      } catch (error) {
        toast.error(
          "Erreur lors de la récupération des commissions ou des articles"
        );
      }
    };

    fetchCommissions();
  }, []);

  const handleGroupClick = (index) => {
    setOpenGroupIndex(openGroupIndex === index ? null : index);
  };

  const handleModalOpen = (group) => {
    setSelectedGroup(group);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedGroup(null);
  };

  return (
    <div className="overflow-x-auto px-8 pb-8 bg-gray-100 dark:bg-gray-700 dark:text-white mt-[130px]">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Les Commissions du Comité
      </h2>
      <InfoBubble title="Bienvenue sur la page des Commissions du Comité">
        <p>
          Sur cette page, vous pouvez visualiser les différentes commissions au
          sein du Comité Départemental. En cliquant sur l'une d'elle, vous aurez
          les détails et activités de cette commission tout au long de la saison
        </p>
      </InfoBubble>

      {groups.map((group, index) => {
        const responsables = group.Acteurs.filter(
          (acteur) => acteur.ActeurGroup.is_responsable
        );
        const responsableText =
          responsables.length > 1 ? "Co-Responsable(s)" : "Responsable";

        return (
          <div
            key={group.id}
            className={`max-w-[1280px] mx-auto collapse collapse-arrow rounded-box border mb-4 font-montserrat bg-gradient-to-b from-blue-200 via-blue-100 to-white shadow-lg`}
          >
            <input
              type="radio"
              name="accordion"
              className="peer"
              id={`group-${index}`}
              hidden
              checked={openGroupIndex === index}
            />
            <label
              htmlFor={`group-${index}`}
              className="collapse-title text-xl font-medium cursor-pointer flex justify-between items-center"
              onClick={() => handleGroupClick(index)}
            >
              <span>{group.nom}</span>
              <span className="text-sm italic">
                {articlesCounts[group.id] || 0} Article(s)
              </span>
            </label>
            <div className="collapse-content bg-white text-gray-800 dark:bg-gray-600 dark:text-white p-4 rounded-b-lg">
              <div className="flex flex-col md:flex-row">
                <img
                  src={group.photo_url}
                  alt={group.nom}
                  className="w-full md:w-1/3 h-48 md:h-auto object-cover rounded mb-4 md:mb-0 md:mr-4"
                />
                <div className="flex flex-col justify-between md:w-2/3">
                  <p
                    className="text-sm mb-4"
                    dangerouslySetInnerHTML={{
                      __html:
                        group.description || "Pas de description disponible",
                    }}
                  ></p>
                  <div className="bg-gray-100 border border-gray-300 p-4 rounded mb-4">
                    <p className="text-gray-700 font-semibold mb-2">
                      {responsableText} :
                    </p>
                    <div className="flex items-center gap-4 mb-2">
                      {responsables.map((acteur) => (
                        <div key={acteur.id} className="flex items-center">
                          <img
                            src={acteur.photo_url || "/img/user_avatar.jpg"}
                            alt={`${acteur.prenom} ${acteur.nom}`}
                            className="w-12 h-12 rounded-full object-cover mr-2"
                          />
                          <p className="text-gray-800 text-sm">
                            {acteur.prenom} {acteur.nom}
                          </p>
                        </div>
                      ))}
                    </div>
                    <span
                      className="text-blue-500 cursor-pointer underline text-sm"
                      onClick={() => handleModalOpen(group)}
                    >
                      Tous les membres de la Commission
                    </span>
                  </div>
                  <div className="flex justify-center mt-4">
                    <Link
                      to={`/commission/${group.id}/articles`}
                      className="flex justify-center"
                    >
                      <Button
                        text={
                          articlesCounts[group.id] > 0
                            ? `${articlesCounts[group.id]} Article(s) publié(s)`
                            : "Pas d'articles publiés"
                        }
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Tous les membres de la Commission"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {selectedGroup &&
            selectedGroup.Acteurs.map((acteur) => (
              <div key={acteur.id} className="flex flex-col items-center">
                <img
                  src={acteur.photo_url || "/img/user_avatar.jpg"}
                  alt={`${acteur.prenom} ${acteur.nom}`}
                  className="w-24 h-24 rounded-full object-cover mb-2"
                />
                <p className="text-sm font-semibold text-center">
                  {acteur.prenom} {acteur.nom}
                </p>
                <p className="text-xs text-center">{acteur.ActeurGroup.poste}</p>
              </div>
            ))}
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default CommissionsPage;
