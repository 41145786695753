import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../components/Button/Button';
import { useUser } from '../../contexts/UserContext';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateNewsModal = ({ isOpen, onClose, onCreateSuccess }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    image_url: '',
    author_id: user?.userId || '',
    is_active: true,
    destinataires_news: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      content: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onCreateSuccess(formData); // Call the creation handler passed from parent
      setFormData({ title: '', content: '', image_url: '', author_id: user.userId || '', is_active: true });
      onClose(); // Close the modal after submission
    } catch (error) {
      console.error('Erreur lors de la création de l\'actualité', error);
      toast.error('Erreur lors de la création de l\'actualité.');
    }
  };

  const handleCancel = () => {
    onClose();
    setFormData({ title: '', content: '', image_url: '', author_id: user.userId || '', is_active: true, destinataires_news: 'Responsables clubs' });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg dark:bg-gray-400">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Créer une Actualité</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="title"
            placeholder="Titre de l'actualité"
            value={formData.title}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <ReactQuill
            value={formData.content}
            onChange={handleContentChange}
            className="mb-3"
          />
          <input
            type="url"
            name="image_url"
            placeholder="URL de l'image"
            value={formData.image_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
          />
          {/* Choix entre "Tous public" ou "Responsables clubs" */}
          <label className="block mb-2">
            <span className="text-gray-700">Destinataires</span>
            <select
              name="destinataires_news"
              value={formData.destinataires_news}
              onChange={handleChange}
              className="select select-bordered w-full"
            >
              <option value="Tout public">Tout public</option>
              <option value="Responsables clubs">Responsables clubs</option>
            </select>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="is_active"
              checked={formData.is_active}
              onChange={(e) => setFormData((prev) => ({ ...prev, is_active: e.target.checked }))}
              className="form-checkbox text-primary"
            />
            <span className="text-gray-700">Activer l'actualité</span>
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={handleCancel} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewsModal;
