import React, { useState, useEffect } from "react";
import { getAllEvents } from "./../../services/eventServices";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import { ToastContainer, toast } from "react-toastify";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import Button from "./../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import ActorSelectionModal from "./ActorSelectionModal";
import ActorUnregistrationModal from "./ActorUnregistrationModal";

const ConvocationsAdminPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedType, setSelectedType] = useState("Tous");
  const [selectedDateFilter, setSelectedDateFilter] = useState("Tous");
  const [isActorSelectionModalOpen, setIsActorSelectionModalOpen] = useState(false);
  const [isActorUnregistrationModalOpen, setIsActorUnregistrationModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [events, searchText, selectedType, selectedDateFilter]);

  const fetchEvents = async () => {
    try {
      const data = await getAllEvents();
      setEvents(data);
    } catch (error) {
      console.error("Erreur lors du chargement des événements", error);
      toast.error("Erreur lors du chargement des événements.");
    }
  };

  const applyFilters = () => {
    let filtered = events;

    // Filtre par texte
    if (searchText) {
      filtered = filtered.filter(event =>
        event.titre.toLowerCase().includes(searchText.toLowerCase()) ||
        event.description.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Filtre par type d'événement
    if (selectedType !== "Tous") {
      filtered = filtered.filter(event => event.type_event === selectedType);
    }

    // Filtre par date (passé, futur)
    const now = new Date();
    if (selectedDateFilter === "Passés") {
      filtered = filtered.filter(event => new Date(event.start) < now);
    } else if (selectedDateFilter === "Prochains") {
      filtered = filtered.filter(event => new Date(event.start) >= now);
    }

    setFilteredEvents(filtered);
  };

  const openActorSelectionModal = (eventId) => {
    setSelectedEventId(eventId);
    setIsActorSelectionModalOpen(true);
  };

  const closeActorSelectionModal = () => {
    setIsActorSelectionModalOpen(false);
  };

  const openActorUnregistrationModal = (eventId) => {
    setSelectedEventId(eventId);
    setIsActorUnregistrationModalOpen(true);
  };

  const closeActorUnregistrationModal = () => {
    setIsActorUnregistrationModalOpen(false);
  };

  const goToEventPage = (eventId) => {
    navigate(`/convocations-admin/${eventId}`);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Convocations des Acteurs
      </h2>

      {/* Filtres */}
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 justify-center mb-4">
        <input
          type="text"
          placeholder="Rechercher par titre ou description..."
          className="p-2 border border-gray-300 rounded"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="Tous">Tous les types</option>
          {Array.from(new Set(events.map(event => event.type_event))).map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
        <select
          value={selectedDateFilter}
          onChange={(e) => setSelectedDateFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="Tous">Tous les événements</option>
          <option value="Passés">Événements passés</option>
          <option value="Prochains">Événements à venir</option>
        </select>
      </div>

      <div className="bg-gray-100 dark:bg-gray-500 shadow-lg w-full max-w-[1280px] mx-auto font-montserrat mt-4">
        <div className="overflow-x-auto">
          <table className="table-auto bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                  Date
                </th>
                <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                  Titre
                </th>
                <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                  Type d'Événement
                </th>
                <th className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event) => (
                  <tr
                    key={event.id}
                    className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm"
                  >
                    <td className="px-2 py-3 text-center">
                      {new Date(event.start).toLocaleDateString()}
                    </td>
                    <td className="px-2 py-3 text-left font-semibold">{event.titre}</td>
                    <td className="px-2 py-3 text-left">{event.type_event}</td>
                    <td className="px-2 py-3 text-center">
                      <div className="flex space-x-2">
                        <Button
                          text="Inscription"
                          onClick={() => openActorSelectionModal(event.id)}
                          hoverLabel="Inscrire Acteur"
                        />
                        <Button
                          text="Désinscription"
                          onClick={() => openActorUnregistrationModal(event.id)}
                          hoverLabel="Désinscrire Acteur"
                        />
                        <Button
                          text="Voir Inscrits"
                          onClick={() => goToEventPage(event.id)}
                          hoverLabel="Voir les acteurs inscrits"
                          className="ml-2"
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-4 px-6 text-gray-800">
                    Aucun événement trouvé.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <ActorSelectionModal
        isOpen={isActorSelectionModalOpen}
        onClose={closeActorSelectionModal}
        eventId={selectedEventId}
        eventTitle={filteredEvents.find((event) => event.id === selectedEventId)?.titre}
        eventStart={filteredEvents.find((event) => event.id === selectedEventId)?.start}
        refreshList={fetchEvents}
      />

      <ActorUnregistrationModal
        isOpen={isActorUnregistrationModalOpen}
        onClose={closeActorUnregistrationModal}
        eventId={selectedEventId}
        refreshList={fetchEvents}
      />

      <ToastContainer />
    </div>
  );
};

export default ConvocationsAdminPage;
