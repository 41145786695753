import React, { useState, useEffect } from "react";
import { getClubEvents } from "../../services/eventServices"; 
import { useUser } from "../../contexts/UserContext";
import { getRoleFromRoleId } from "../../utils/roleHelper";
import useScrollToTop from "../../hooks/useScrollToTop";
import Button from "../../components/Button/Button";
import { ToastContainer, toast } from "react-toastify";
import CreateEventModal from "./CreateEventModal";
import EventCard from "./EventCard";
import InfoBubble from "../../components/InfoBubble/InfoBubble";

const ClubEventsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [events, setEvents] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [searchTitle, setSearchTitle] = useState(""); 
  const [selectedCategory, setSelectedCategory] = useState(""); 
  const [selectedType, setSelectedType] = useState(""); 

  useEffect(() => {
    fetchEvents();
  }, [showAll, searchTitle, selectedCategory, selectedType]);

  const fetchEvents = async () => {
    try {
      const response = await getClubEvents();
      const filteredEvents = response.filter((event) => {
        return (
          event.titre.toLowerCase().includes(searchTitle.toLowerCase()) &&
          (selectedCategory === "" || event.cate_event.includes(selectedCategory)) &&
          (selectedType === "" || event.type_event === selectedType)
        );
      });
      setEvents(filteredEvents);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
  };

  const handleAddEvent = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    fetchEvents();
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (!user) {
    return <div>Loading...</div>; 
  }

  const categories = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  const types = [
    "Cours commun Club",
    "Stage Club",
    "Tournoi Interclubs",
  ];

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        RESPONSABLES CLUBS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Proposer Cours communs, Stages et Tournois
      </h2>
      <InfoBubble title="Bienvenue sur la page des propositions de Cours communs, Stages et Tournois Interclubs">
        <p>Sur cette page, les responsables de clubs pourront proposer aux clubs girondins et leurs licenciés des cours communs, stages ou tournois interclubs. Bien renseigner le formulaire en précisant les catégories d'âges. L'événement ajouté sera validé par le CTF ou la commission animation pour être affiché sur le calendrier départemental.</p>
      </InfoBubble>
      <div className="overflow-x-auto w-full p-4 max-w-[1280px] mx-auto">
        <div className="flex flex-col md:flex-row mx-auto w-full gap-4 mb-4">
          {(userRole === "Administrateur" ||
            userRole === "Bureau" ||
            userRole === "Responsables_clubs") && (
            <Button
              text="Proposer un Événement"
              onClick={handleAddEvent}
              className="mb-4"
            />
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <input
            type="text"
            placeholder="Rechercher par titre"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            className="border p-2 rounded flex-grow"
          />
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="border p-2 rounded flex-grow"
          >
            <option value="">Toutes les catégories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className="border p-2 rounded flex-grow"
          >
            <option value="">Tous les types</option>
            {types.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
          {events.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              fetchEvents={fetchEvents}
            />
          ))}
        </div>
      </div>
      <CreateEventModal
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        userId={user.userId}
        fetchEvents={fetchEvents}
      />
      <ToastContainer />
    </div>
  );
};

export default ClubEventsPage;
