import React, { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { getRoleFromRoleId } from "../../utils/roleHelper";
import useScrollToTop from "../../hooks/useScrollToTop";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import { getReglementsArbitrage, deleteReglementArbitrage } from "../../services/reglementarbitrageServices";
import ReglementCard from "./ReglementCard";
import Button from "../../components/Button/Button";
import CreateReglementArbitrageModal from "./CreateReglementArbitrageModal";
import EditReglementArbitrageModal from "./EditReglementArbitrageModal";
import { ToastContainer, toast } from "react-toastify";

const ReglementArbitragePage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [reglements, setReglements] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentReglement, setCurrentReglement] = useState(null);

  useEffect(() => {
    const fetchReglements = async () => {
      const data = await getReglementsArbitrage();
      setReglements(data);
    };
    fetchReglements();
  }, []);

  const handleAddReglementClick = () => {
    setIsCreateModalOpen(true);
  };

  const handleEditReglement = (reglement) => {
    setCurrentReglement(reglement);
    setIsEditModalOpen(true);
  };

  const handleDeleteReglement = async (id) => {
    try {
      await deleteReglementArbitrage(id);
      setReglements(reglements.filter(reglement => reglement.id !== id));
      toast.success("Règlement supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression du règlement:", error);
      toast.error("Erreur lors de la suppression du règlement");
    }
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentReglement(null);
  };

  const refreshReglementsList = async () => {
    const data = await getReglementsArbitrage();
    setReglements(data);
  };

  const handleCreateSuccess = () => {
    closeCreateModal();
    refreshReglementsList();
    toast.success('Règlement créé avec succès');
  };

  const handleEditSuccess = () => {
    closeEditModal();
    refreshReglementsList();
    toast.success('Règlement modifié avec succès');
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        Arbitres & Commissaires Sportifs
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Règlements d'arbitrage
      </h2>
      {(userRole === "Administrateur" || userRole === "Bureau") && (
        <div className="flex justify-center mb-4">
          <Button text="Ajouter un Règlement" onClick={handleAddReglementClick} className="mb-2" />
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 max-w-[1280px] mx-auto mb-4">
        {reglements.map((reglement) => (
          <ReglementCard
            key={reglement.id}
            reglement={reglement}
            onEdit={() => handleEditReglement(reglement)}
            onDelete={() => handleDeleteReglement(reglement.id)}
            canModify={userRole === "Administrateur" || userRole === "Bureau"}
          />
        ))}
      </div>
      <CreateReglementArbitrageModal isOpen={isCreateModalOpen} onClose={closeCreateModal} onCreateSuccess={handleCreateSuccess} />
      <EditReglementArbitrageModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        onEditSuccess={handleEditSuccess}
        reglement={currentReglement}
      />
      <ToastContainer />
    </div>
  );
};

export default ReglementArbitragePage;
