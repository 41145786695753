import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/settings`;
const token = localStorage.getItem("token");

/// GET
export const getSettings = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getSettings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des paramètres:", error);
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};


/// PUT
// Fonction pour mettre à jour les paramètres de l'application
export const updateSettings = async (settings) => {
  try {
    const response = await axios.put(`${apiUrl}/updateSettings`, settings, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to update data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la mise à jour des paramètres:", error);
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PATCH
// Fonction pour mettre à jour l'image par défaut du règlement de Licence-Cotisation
export const updateDefaultImageLicence = async (file, token) => {
  const formData = new FormData();
  formData.append("defaultImageLicence", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/updateDefaultImageLicence`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data.imageUrl; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'image par défaut:",
      error
    );
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour mettre à jour le logo du club
export const updateClubLogo = async (file, token) => {
  const formData = new FormData();
  formData.append("logoClub", file);

  try {
    const response = await axios.patch(`${apiUrl}/updateLogoClub`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data.logo_club; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la mise à jour du logo du club:", error);
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour mettre à jour l'image 1 de la page d'accueil
export const updateFirstHeaderImage = async (file, token) => {
  const formData = new FormData();
  formData.append("firstHeaderImage", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/updateFirstHeaderImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data.imageUrl; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'image header_url1:",
      error
    );
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour mettre à jour l'image 2 de la page d'accueil
export const updateSecondHeaderImage = async (file, token) => {
  const formData = new FormData();
  formData.append("secondHeaderImage", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/updateSecondHeaderImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data.imageUrl; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'image header_url2:",
      error
    );
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

export const updateReglementHeaderImage = async (file, token) => {
  const formData = new FormData();
  formData.append("reglementHeaderImage", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/updateReglementHeaderImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data.imageUrl; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'image header:", error);
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

export const updateReglementFirstImage = async (file, token) => {
  const formData = new FormData();
  formData.append("reglementFirstImage", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/updateReglementFirstImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data.imageUrl; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de la première image facultative:",
      error
    );
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

export const updateReglementSecondImage = async (file, token) => {
  const formData = new FormData();
  formData.append("reglementSecondImage", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/updateReglementSecondImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data.imageUrl; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de la deuxième image facultative:",
      error
    );
    return {}; // Renvoie un objet vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};
