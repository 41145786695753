import React, { useState, useEffect } from "react";
import {
  getSentMessagesByUser,
  deleteMessageById,
} from "../../services/messageServices";
import { useMessage } from "./../../contexts/MessageContext";
import { FaExclamationTriangle } from "react-icons/fa";
import { BsEnvelopeArrowUpFill } from "react-icons/bs";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import { toast } from "react-toastify";

const MessagesSent = ({ userId, refresh, setRefresh }) => {
  const [messages, setMessages] = useState([]);
  const { unreadMessageCount, setUnreadMessageCount } = useMessage();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);

  useEffect(() => {
    if (refresh) {
      loadMessages();
      setRefresh(false);
    }
  }, [refresh]);

  const loadMessages = async () => {
    if (!userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    try {
      const data = await getSentMessagesByUser(userId);
      // Filtrer les messages pour s'assurer que seuls les messages envoyés (copies) sont affichés
      const filteredMessages = data.filter((message) => message.is_copy);
      setMessages(filteredMessages);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
    }
  };

  const promptDeleteMessage = (messageId) => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer le message ?");
    if (confirmDelete) {
      handleDeleteMessage(messageId);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageById(messageId);
      setMessages(messages.filter((message) => message.id !== messageId)); // Optimistically remove the message from state
      toast.success("Message supprimé avec succès.");
    } catch (error) {
      console.error("Error deleting message:", error);
      toast.error("Erreur lors de la suppression du message.");
    }
  };

  useEffect(() => {
    loadMessages();
  }, [userId]);

  return (
    <div className="overflow-x-auto shadow-xl">
      <div className="flex justify-between">
        <h3 className="card-title mb-2 ml-2">
          <BsEnvelopeArrowUpFill className="text-2xl" /> Messages Envoyés
        </h3>
      </div>
      {messages.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-50 divide-y divide-gray-400">
              <thead className="bg-gray-50">
                <tr className="bg-slate-400">
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Détails
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {messages.map((message) => (
                  <tr key={message.id} className="hover:bg-gray-300">
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 ">
                      {new Date(message.created_at).toLocaleDateString("fr-FR")}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      <div className="font-semibold">{`${message.Recipient.nom} ${message.Recipient.prenom}`}</div>
                      <div className="text-sm text-blue-800 font-bold">{message.subject}</div>
                      <div className="text-sm" dangerouslySetInnerHTML={{ __html: message.content }}></div>
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium gap-2 flex">
                      <ButtonDelete onClick={() => promptDeleteMessage(message.id)} hoverLabel="Supprimer" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {messages.map((message) => (
              <div key={message.id} className="bg-white shadow-md rounded-lg p-4 mb-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold text-gray-900">
                    {new Date(message.created_at).toLocaleDateString("fr-FR")}
                  </span>
                  <div className="flex space-x-2">
                    <ButtonDelete onClick={() => promptDeleteMessage(message.id)} hoverLabel="Supprimer" />
                  </div>
                </div>
                <div className="text-sm text-gray-500 mb-2">
                  <div className="font-semibold">{`${message.Recipient.nom} ${message.Recipient.prenom}`}</div>
                  <div className="text-sm text-blue-800 font-bold">{message.subject}</div>
                  <div className="text-sm" dangerouslySetInnerHTML={{ __html: message.content }}></div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4 bg-white">Aucuns messages.</div>
      )}
      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="alert shadow-lg bg-white p-5 rounded">
            <FaExclamationTriangle className="stroke-info shrink-0 w-6 h-6" />
            <div>
              <h3 className="font-bold">Confirmer la Suppression</h3>
              <p className="text-xs">Êtes-vous sûr de vouloir supprimer le message ?</p>
            </div>
            <div className="flex gap-4 mt-4">
              <button className="btn btn-error btn-sm" onClick={() => handleDeleteMessage(messageToDelete)}>
                Effacer
              </button>
              <button className="btn btn-sm" onClick={() => setShowConfirmModal(false)}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessagesSent;
