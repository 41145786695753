import React from 'react';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';

const EventTableUser = ({ events, onEventClick, userRole }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? '' : date.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <table className="bg-white dark:bg-slate-600 max-w-[1280px] mx-auto">
      <thead>
        <tr>
          <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-slate-500">Photo</th>
          <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-slate-500">Titre</th>
          <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-slate-500">Date</th>
          <th className="py-2 px-4 border-b-2 border-gray-300 dark:border-slate-500">Lieu</th>
        </tr>
      </thead>
      <tbody>
        {events.map(event => (
          <tr key={event.id} className="hover:bg-gray-200 dark:hover:bg-slate-500 cursor-pointer" onClick={() => onEventClick(event)}>
            <td className="py-2 px-4 border-b border-gray-300 dark:border-slate-500">
              <img src={event.photo_url} alt={event.titre} className="w-16 h-16 object-cover" />
            </td>
            <td className="py-2 px-4 border-b border-gray-300 dark:border-slate-500">{event.titre}</td>
            <td className="py-2 px-4 border-b border-gray-300 dark:border-slate-500">{formatDate(event.start)}{event.end && ` - ${formatDate(event.end)}`}</td>
            <td className="py-2 px-4 border-b border-gray-300 dark:border-slate-500">{event.lieu_event || 'Lieu à préciser'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EventTableUser;
