import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/users`;
const token = localStorage.getItem("token");

///GET
// Fonction pour récupérer un utilisateur par son ID
export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/oneUser/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de l'utilisateur:", error);
    return null;
  }
};

// Fonction pour récupérer tous les utilisateurs
export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allusers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.data || !Array.isArray(response.data)) {
      throw new Error("Invalid data format: Expected an array");
    }
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des utilisateurs:", error);
    return []; // Retourner un tableau vide en cas d'erreur pour éviter des erreurs côté client
  }
};

// Fonction pour afficher les utilisateurs Administrateurs et Mmebres
export const getAdminsAndMembers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/AdminsAndMembers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des utilisateurs:", error);
    return [];
  }
};

// Fonction pour récupérer les 10 derniers utilisateurs inscrits
export const getLatestUsers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/lastTenUsers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du chargement des derniers utilisateurs:",
      error
    );
    return [];
  }
};

// Fonction pour récupérer tous les utilisateurs à partir d'un acteur
export const getUserIdByActeurId = async (acteurId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/userIdByActeurId/${acteurId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des utilisateurs:", error);
    return null;
  }
};

// Fonction pour récupérer un tableau d'id de user ayant le role_id =1 ou =3
export const getAdminsAndInstructorsIds = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAdminsAndTeachers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des utilisateurs:", error);
    return null;
  }
};

// Fonction pour récupérer un tableau d'id de user ayant le role_id =1
export const getAdminsIds = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAdmins`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des utilisateurs:", error);
    return null;
  }
};

// Fonction pour charger les rôles
export const getRoles = async () => {
  try {
    const response = await axios.get(`${apiUrl}/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!Array.isArray(response.data)) {
      throw new Error("Invalid data format: Expected an array");
    }
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des rôles:", error);
    return []; // Retourner un tableau vide en cas d'erreur
  }
};

// Fonction pour compter le nombre d'utilisateurs
export const countUsers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countUsers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du comptage des utilisateurs:", error);
    return 0;
  }
};

// Fonction pour checker si un email est déjà utilisé
export const checkEmailExists = async (email) => {
  try {
    const response = await axios.get(`${apiUrl}/checkEmail`, {
      params: { email },
    });
    return { exists: response.data.exists };
  } catch (error) {
    console.error("Erreur lors de la vérification de l'email:", error);
    return {
      exists: false,
      error: error.response
        ? error.response.data.message
        : "Erreur lors de la vérification de l'email",
    };
  }
};

// Fonction pour récupérer les adhérents d'un utilisateur et leur groupe
export const getAdherentsByUser = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/${userId}/adherents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des adhérents:", error);
    return [];
  }
};

// Fonction pour récupérer tous les acteurs gérés par un utilisateur
export const getActeursByUser = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/${userId}/acteurs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des acteurs:", error);
    return [];
  }
};

/// POST
// Fonction pour se connecter à l'application avec token
export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${apiUrl}/login`, { email, password });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la connexion de l'utilisateur:", error);
    return null;
  }
};

// Fonction pour s'enregistrer dans l'application en tant qu'utilisateur
export const registerUser = async (nom, prenom, email, password) => {
  try {
    const response = await axios.post(`${apiUrl}/register`, {
      nom,
      prenom,
      email,
      password,
    });
    return { data: response.data };
  } catch (error) {
    console.error("Erreur lors de l'inscription de l'utilisateur:", error);
    let errorMessage = "Erreur lors de l'inscription. Veuillez réessayer.";
    if (
      error.response &&
      error.response.data &&
      typeof error.response.data.message === "string"
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.data.errors) {
      errorMessage = error.response.data.errors
        .map((err) => err.message)
        .join(", ");
    }
    return { error: errorMessage };
  }
};

// Fonction pour envoyer les réponses du formulaire de contact
export const sendContactForm = async (formData) => {
  try {
    await axios.post(`${apiUrl}/send-contact-form`, formData);
  } catch (error) {
    console.error("Erreur lors de l'envoi du message", error);
  }
};

// Fonction pour assigner un adhérent à un utilisateur
export const assignActeursToUser = async (userId, acteurId, token) => {
  if (!acteurId) {
    throw new Error("acteurId ne peut pas être nul ou indéfini");
  }
  try {
    const response = await axios.post(
      `${apiUrl}/assignActeurToUser`,
      { userId, acteurId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Renvoyer les données de la réponse pour une gestion ultérieure
  } catch (error) {
    console.error(
      "Erreur lors de l'assignation de l'adhérent à l'utilisateur",
      error
    );
    console.log(
      `Tentative d'association avec userId: ${userId} et acteurId: ${acteurId}`
    );
    throw error; // Propager l'erreur pour une gestion ultérieure
  }
};

// Fonction pour supprimer un adhérent d'un utilisateur
export const removeActeurFromUser = async (userId, acteurId, token) => {
  try {
    const response = await axios.post(
      `${apiUrl}/removeActeurFromUser`,
      { userId, acteurId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de l'acteur de l'utilisateur",
      error
    );
    console.log(
      `Tentative de suppression avec userId: ${userId} et acteurId: ${acteurId}`
    );
    throw error;
  }
};

// Fonction pour créer un utilisateur
export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${apiUrl}/addUser`, userData);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'utilisateur:", error);
    return null;
  }
};

// Fonction pour créer un utilisateur par Admin
export const addUserByAdmin = async (userData) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Assurez-vous que le token est correctement inclus
      },
    };

    const response = await axios.post(
      `${apiUrl}/addUserByAdmin`,
      userData, // Pas besoin de JSON.stringify
      config
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la création de l'utilisateur:",
      error.response ? error.response.data : error
    );
    console.log("Données envoyées:", userData);
    return null;
  }
};

/// PUT
// Fonction pour mettre à jour un utilisateur et les adhérents gérés
export const updateUser = async (userId, userData, token) => {
  try {
    const response = await axios.put(
      `${apiUrl}/${userId}/updateUser`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
    throw error;
  }
};

///PATCH
// Fonction pour modifier une image de profil de l'utilisateur
export const updateUserProfilePhoto = async (userId, photo, token) => {
  const formData = new FormData();
  formData.append("image", photo); // Assure-toi que la clé correspond à celle attendue par Multer
  try {
    const response = await axios.patch(`${apiUrl}/${userId}/photo`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data", // Nécessaire pour le bon traitement du fichier
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la modification de la photo de profil",
      error
    );
    return null;
  }
};

// Fonction pour mettre à jour les données de l'utilisateur
export const updateUserProfile = async (userId, userData, token) => {
  // Ajoute token en paramètre si nécessaire
  try {
    const response = await axios.patch(
      `${apiUrl}/${userId}/updateUserProfile`, // Assure-toi que le chemin de l'API est correct
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour des données de l'utilisateur:",
      error
    );
    throw error; // Propager l'erreur pour la gérer plus haut dans la pile d'appel
  }
};

/// DELETE
// Fonction pour supprimer un utilisateur
export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${apiUrl}/${userId}/deleteUser`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur:", error);
    return null;
  }
};
