import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/reglementsarbitrage`;
const token = localStorage.getItem("token");

//GET
// Fonction pour afficher tous les reglements d'arbitrage
export const getReglementsArbitrage = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allreglement`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors du chargement des reglements d'arbitrage:", error);
    return [];
  }
}

/// POST
// Fonction pour créer un reglement d'arbitrage
export const createReglementArbitrage = async (reglement) => {
  try {
    const response = await axios.post(`${apiUrl}/addreglement`, reglement, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la création du reglement d'arbitrage:", error);
    return null;
  }
}

/// PUT
// Fonction pour modifier un reglement d'arbitrage
export const updateReglementArbitrage = async (reglement) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updatereglement/${reglement.id}`,
      reglement,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la modification du reglement d'arbitrage:", error);
    return null;
  }
}

/// DELETE
// Fonction pour supprimer un reglement d'arbitrage
export const deleteReglementArbitrage = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deletereglement/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la suppression du reglement d'arbitrage:", error);
    return null;
  }
}

