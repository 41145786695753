import React, { useState, useEffect } from "react";
import { getSettings } from "../../services/settingsServices"; // Assurez-vous que le chemin est correct
import "./HeaderSlider.css";

const Header = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [headerImages, setHeaderImages] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();
        if (settings.length > 0) {
          const headers = [
            {
              src: settings[0].header_url1,
              text: settings[0].header_texte1,
              align: "left",
            },
            {
              src: settings[0].header_url2,
              text: settings[0].header_texte2,
              align: "right",
            },
          ];
          setHeaderImages(headers);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (headerImages.length > 0) {
      const timeout = setTimeout(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % headerImages.length
        );
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [currentImageIndex, headerImages]);

  const getTextAlignment = (align) => {
    switch (align) {
      case "left":
        return "text-left ml-5 left-5";
      case "right":
        return "text-right mr-5 right-5";
      default:
        return "";
    }
  };

  if (headerImages.length === 0) return <div>Chargement...</div>;

  return (
    <header className="header">
      {headerImages.map((slide, index) => (
        <img
          key={index}
          src={slide.src}
          alt={`Header slide ${index + 1}`}
          className={`header-image ${
            index === currentImageIndex ? "active" : ""
          }`}
        />
      ))}
      <div
        className={`text-overlay ${getTextAlignment(
          headerImages[currentImageIndex].align
        )}`}
      >
        <div
          className="header-text-line"
          dangerouslySetInnerHTML={{ __html: headerImages[currentImageIndex].text }}
        />
      </div>
    </header>
  );
};

export default Header;
