import React, { useState } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { createGroup } from "../../services/groupServices";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const colors = [
  { label: "Bleu", value: "bg-blue-400" },
  { label: "Vert", value: "bg-green-400" },
  { label: "Jaune", value: "bg-yellow-400" },
  { label: "Rouge", value: "bg-red-400" },
  { label: "Indigo", value: "bg-indigo-400" },
  { label: "Violet", value: "bg-purple-400" },
  { label: "Rose", value: "bg-pink-400" },
  { label: "Orange", value: "bg-orange-400" },
  { label: "Cyan", value: "bg-teal-400" },
];

const CreateGroup = ({ isActive, onClose, refreshGroups }) => {
  const [formData, setFormData] = useState({
    nom: "",
    ordre_groupe: "",
    is_active: true,
    color_groupe: colors[0].value,
    photo_url: "",
    description: "",
  });

  const resetForm = () => {
    setFormData({
      nom: "",
      ordre_groupe: "",
      is_active: true,
      color_groupe: colors[0].value,
      photo_url: "",
      description: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleColorChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      color_groupe: selectedOption.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newGroup = await createGroup(formData);
      toast.success("Groupe créé avec succès");
      resetForm();
      onClose();
      refreshGroups();
    } catch (error) {
      console.error("Erreur lors de la création du groupe", error);
      toast.error("Erreur lors de la création du groupe");
    }
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      description: value,
    }));
  };

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Créer un Nouveau Groupe
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            className="input input-bordered w-full"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            required
            placeholder="Nom du groupe"
          />
          <input
            type="number"
            className="input input-bordered w-full"
            name="ordre_groupe"
            value={formData.ordre_groupe}
            onChange={handleChange}
            placeholder="Ordre du groupe"
          />
          <input
            type="url"
            className="input input-bordered w-full"
            name="photo_url"
            value={formData.photo_url}
            onChange={handleChange}
            placeholder="URL de l'image"
          />
          <ReactQuill
            value={formData.description}
            onChange={handleContentChange}
            className="mb-3"
          />
          <label className="flex items-center space-x-2">
            <span className="label-text">Groupe actif ?</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              name="is_active"
              checked={formData.is_active}
              onChange={handleChange}
            />
          </label>
          <label className="flex items-center space-x-2">
            <span className="label-text">Couleur du groupe</span>
            <Select
              name="color_groupe"
              value={colors.find(color => color.value === formData.color_groupe)}
              onChange={handleColorChange}
              options={colors}
              getOptionLabel={(option) => (
                <div className="flex items-center">
                  <span
                    className={`w-4 h-4 inline-block rounded-full ${option.value} mr-2`}
                  ></span>
                  {option.label}
                </div>
              )}
              getOptionValue={(option) => option.value}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  display: 'flex',
                  alignItems: 'center',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.data.value,
                  color: state.isSelected ? 'white' : 'black',
                }),
              }}
            />
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={() => {
                onClose();
                resetForm();
              }}
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGroup;
