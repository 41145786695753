import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import ButtonEdit from "../../components/Button/ButtonEdit";
import ButtonDelete from "../../components/Button/ButtonDelete";
import { FaPlus, FaTimes } from "react-icons/fa";
import {
  addActeursToGroup,
  removeActeursFromGroup,
  editActeurGroup,
} from "../../services/acteursServices";
import { toast } from "react-toastify";

const ManageGroupModal = ({
  isOpen,
  onClose,
  acteurId,
  acteurNom,
  acteurPrenom,
  groups,
  acteurGroups,
  refreshData,
}) => {
  const [localActeurGroups, setLocalActeurGroups] = useState([]);
  const [selectedGroupToAdd, setSelectedGroupToAdd] = useState("");
  const [isResponsable, setIsResponsable] = useState(false);
  const [poste, setPoste] = useState("");
  const [editedGroupId, setEditedGroupId] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setLocalActeurGroups(acteurGroups);
      setSelectedGroupToAdd("");
      setIsResponsable(false);
      setPoste("");
      setEditedGroupId(null);
    }
  }, [isOpen, acteurGroups]);

  const handleAddGroup = async () => {
    if (selectedGroupToAdd) {
      try {
        await addActeursToGroup(acteurId, selectedGroupToAdd, isResponsable, poste);
        toast.success("Acteur ajouté au groupe avec succès");
        refreshData();
        onClose();
      } catch (error) {
        toast.error("Erreur lors de l'ajout de l'acteur au groupe");
        console.error("Erreur lors de l'ajout de l'acteur au groupe:", error);
      }
    }
  };

  const handleRemoveGroup = async (groupId) => {
    try {
      await removeActeursFromGroup(acteurId, groupId);
      toast.success("Acteur supprimé du groupe avec succès");
      setLocalActeurGroups(localActeurGroups.filter(group => group.id !== groupId));
      refreshData();
    } catch (error) {
      toast.error("Erreur lors de la suppression de l'acteur du groupe");
      console.error("Erreur lors de la suppression de l'acteur du groupe:", error);
    }
  };

  const handleEditGroup = (groupId) => {
    const group = localActeurGroups.find(group => group.id === groupId);
    if (group) {
      setSelectedGroupToAdd(group.id);
      setIsResponsable(group.ActeurGroup.is_responsable);
      setPoste(group.ActeurGroup.poste || "");
      setEditedGroupId(groupId);
    }
  };

  const handleSaveEditGroup = async () => {
    if (editedGroupId) {
      try {
        await editActeurGroup(acteurId, editedGroupId, isResponsable, poste);
        toast.success("Acteur modifié dans le groupe avec succès");
        const updatedGroups = localActeurGroups.map(group =>
          group.id === editedGroupId ? { ...group, ActeurGroup: { is_responsable: isResponsable, poste } } : group
        );
        setLocalActeurGroups(updatedGroups);
        setEditedGroupId(null);
        setSelectedGroupToAdd("");
        setIsResponsable(false);
        setPoste("");
        refreshData();
      } catch (error) {
        toast.error("Erreur lors de la modification de l'acteur dans le groupe");
        console.error("Erreur lors de la modification de l'acteur dans le groupe:", error);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Gérer les groupes pour {acteurNom} {acteurPrenom}
        </h3>
        <div className="mb-4">
          {localActeurGroups.map((group) => (
            <div key={group.id} className="card bg-gray-200 mb-2 p-4 shadow-md rounded-lg border">
              <h4 className="font-bold">{group.nom}</h4>
              {group.ActeurGroup.is_responsable && <p>Responsable: ✅</p>}
              {group.ActeurGroup.poste && <p>Poste: {group.ActeurGroup.poste}</p>}
              <div className="flex gap-2 mt-2">
                <ButtonEdit onClick={() => handleEditGroup(group.id)} />
                <ButtonDelete onClick={() => handleRemoveGroup(group.id)} />
              </div>
            </div>
          ))}
        </div>
        {editedGroupId ? (
          <>
            <h4 className="text-lg font-semibold mb-2">Modifier le groupe</h4>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Poste:</label>
              <input
                type="text"
                value={poste}
                onChange={(e) => setPoste(e.target.value)}
                className="input input-bordered w-full"
              />
              <div className="flex items-center mt-2">
                <input
                  type="checkbox"
                  id="isResponsable"
                  checked={isResponsable}
                  onChange={(e) => setIsResponsable(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="isResponsable" className="text-sm">
                  Responsable
                </label>
              </div>
              <Button
                onClick={handleSaveEditGroup}
                className="btn-primary flex items-center gap-2 mt-4"
                text="Enregistrer les modifications"
              />
            </div>
          </>
        ) : (
          <>
            <h4 className="text-lg font-semibold mb-2">Ajouter à un nouveau groupe</h4>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Groupe:</label>
              <select
                value={selectedGroupToAdd}
                onChange={(e) => setSelectedGroupToAdd(e.target.value)}
                className="input input-bordered w-full"
              >
                <option value="">Sélectionner un groupe</option>
                {groups
                  .filter((group) => !localActeurGroups.some((ag) => ag.id === group.id))
                  .map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.nom}
                    </option>
                  ))}
              </select>
              <div className="flex items-center mt-2">
                <input
                  type="checkbox"
                  id="isResponsable"
                  checked={isResponsable}
                  onChange={(e) => setIsResponsable(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="isResponsable" className="text-sm">
                  Responsable
                </label>
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium mb-1" htmlFor="poste">
                  Poste:
                </label>
                <input
                  type="text"
                  id="poste"
                  value={poste}
                  onChange={(e) => setPoste(e.target.value)}
                  className="input input-bordered w-full"
                />
              </div>
              <Button
                onClick={handleAddGroup}
                className="btn-primary flex items-center gap-2 mt-4"
                text="Ajouter"
                icon={FaPlus}
              />
            </div>
          </>
        )}
        <Button
          onClick={onClose}
          className="btn-ghost flex items-center gap-2 mt-4"
          text="Fermer"
          icon={FaTimes}
        />
      </div>
    </div>
  );
};

export default ManageGroupModal;
