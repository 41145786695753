import React, { useState, useEffect } from 'react';
import Button from "../../components/Button/Button";
import { FaPlus, FaTimes } from "react-icons/fa";
import { getAllActeursListWithGroup } from './../../services/acteursServices';
import { assignActorToEvent } from './../../services/acteureventServices';
import { toast } from 'react-toastify';
import { sendNotification } from '../../services/notificationServices';
import { getUserIdByActeurId } from './../../services/userServices';
import formatDate from './../../utils/formatDate';

const ActorSelectionModal = ({ isOpen, onClose, eventId, eventTitle, eventStart, refreshList }) => {
  const [acteurs, setActeurs] = useState([]);
  const [selectedActeurId, setSelectedActeurId] = useState("");
  const [poste, setPoste] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchActeurs();
    }
  }, [isOpen]);

  const fetchActeurs = async () => {
    try {
      const data = await getAllActeursListWithGroup();
      setActeurs(data);
    } catch (error) {
      console.error('Erreur lors du chargement des acteurs', error);
      toast.error('Erreur lors du chargement des acteurs.');
    }
  };

  const handleAssign = async () => {
    try {
      await assignActorToEvent(selectedActeurId, eventId, poste);
      toast.success('Acteur inscrit avec succès.');
      
      // Récupérer les utilisateurs responsables de l'acteur
      const users = await getUserIdByActeurId(selectedActeurId);

      // Créer le message de notification
      const message = `Le Responsable de la Manifestation ou le Responsable d'arbitrage vous a convoqué sur l'événement : ${eventTitle}, le ${formatDate(eventStart)} en tant que ${poste}`;
      
      // Envoyer la notification à chaque utilisateur responsable
      for (const user of users) {
        await sendNotification(message, user.user_id);
      }

      refreshList();
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'inscription de l'acteur à l'événement", error);
      toast.error("Erreur lors de l'inscription de l'acteur à l'événement.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Inscrire Acteur
        </h3>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Poste:</label>
          <select
            onChange={(e) => setPoste(e.target.value)}
            className="input input-bordered w-full mb-4"
          >
            <option value="">Sélectionner un poste</option>
            <option value="Organisateur">Organisateur</option>
            <option value="Responsable Comité">Responsable Comité</option>
            <option value="Bénévolontaire">Bénévolontaire</option>
            <option value="Responsable Arbitrage">Responsable Arbitrage</option>
            <option value="Notateur">Notateur</option>
            <option value="Arbitre">Arbitre</option>
            <option value="Commissaire Sportif">Commissaire Sportif</option>
            <option value="Juge">Juge</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Sélectionner Acteur:</label>
          <select
            onChange={(e) => setSelectedActeurId(e.target.value)}
            className="input input-bordered w-full mb-4"
          >
            <option value="">Sélectionner un acteur</option>
            {acteurs.map((acteur) => (
              <option key={acteur.id} value={acteur.id}>
                {acteur.nom} {acteur.prenom}
              </option>
            ))}
          </select>
        </div>
        <Button
          onClick={handleAssign}
          className="btn-primary flex items-center gap-2 mt-4"
          text="Inscrire"
          icon={FaPlus}
        />
        <Button
          onClick={onClose}
          className="btn-ghost flex items-center gap-2 mt-4"
          text="Fermer"
          icon={FaTimes}
        />
      </div>
    </div>
  );
};

export default ActorSelectionModal;
