import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/annonces`;
const token = localStorage.getItem("token");

//GET
// Fonction pour afficher toutes les annonces
export const getAnnonces = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllAnnonces`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des annonces:", error);
    return [];
  }
};

/// POST
// Fonction pour créer une annonce
export const createAnnonce = async (annonce) => {
  try {
    const response = await axios.post(`${apiUrl}/addAnnonce`, annonce, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'annonce:", error);
    return null;
  }
};

/// PUT
// Fonction pour modifier une annonce
export const updateAnnonce = async (annonce) => {
  try {
    const response = await axios.put(`${apiUrl}/updateAnnonce/${annonce.id}`, annonce, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'annonce:", error);
    return null;
  }
};

/// DELETE
// Fonction pour supprimer une annonce
export const deleteAnnonce = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteAnnonce/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'annonce:", error);
    return null;
  }
};