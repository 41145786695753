import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/acteurs`;
const token = localStorage.getItem("token");

//GET
// Fonction pour afficher tous les acteurs
export const getAllActeursListWithGroup = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllActeursListWithGroup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des acteurs:", error);
    return [];
  }
};

// Fonction pour afficher un acteur par son id
export const getActeurById = async (acteurId) => {
  try {
    const response = await axios.get(`${apiUrl}/getActeurById/${acteurId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de l'acteur:", error);
    throw error;
  }
};

// Fonction pour afficher la liste des acteurs
export const getAllActeursList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllActeursList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des acteurs:", error);
    return [];
  }
};

// Fonction pour afficher la liste de tous les officiels
export const getAllOfficielsList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getOfficiels`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors du chargement des officiels:", error);
    return [];
  }
}

/// POST
// Fonction pour créer un acteur par l'administrateur
export const createActeur = async (acteurData) => {
  try {
    const response = await axios.post(`${apiUrl}/addActeur`, acteurData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'acteur:", error);
    throw error;
  }
};

// Fonction pour créer un acteur par un utilisateur
export const createActeurByUser = async (acteurData) => {
  try {
    const response = await axios.post(`${apiUrl}/addActeurByUser`, acteurData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'acteur:", error);
    throw error;
  }
};

// Fonction pour ajouter un acteur à un groupe
export const addActeursToGroup = async (acteurId, groupId, isResponsable, poste) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addGroupe/${acteurId}`,
      { group_id: groupId, is_responsable: isResponsable, poste: poste }, // Inclure poste
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'acteur au groupe:", error);
    throw error;
  }
};

/// PUT
// Fonction pour modifier un acteur par son id
export const updateActeur = async (acteurId, acteurData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateActeur/${acteurId}`,
      acteurData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'acteur:", error);
    throw error;
  }
};

// Fonction pour modifier un acteur dans un groupe
export const editActeurGroup = async (acteurId, groupId, isResponsable, poste) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateActeurGroup/${acteurId}/${groupId}`,
      { is_responsable: isResponsable, poste: poste },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'acteur dans le groupe:", error);
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer un acteur par son id
export const deleteActeur = async (acteurId) => {
  try {
    const response = await axios.delete(`${apiUrl}/removeActeur/${acteurId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'acteur:", error);
    throw error;
  }
};

// Fonction pour supprimer un acteur d'un groupe
export const removeActeursFromGroup = async (acteurId, groupId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/removeGroupe/${acteurId}/${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de l'acteur du groupe:",
      error
    );
    throw error;
  }
};
