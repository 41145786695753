import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/lexique`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer les mots du lexique
export const getLexique = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allLexique`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des mots du lexique:", error);
    throw error;
  }
};
