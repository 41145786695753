import React, { useState, useEffect } from "react";
import {
  getAllPartenaires,
  deletePartenaire,
  updatePartenaireOrder,
  updatePartenaireActivation,
  updatePartenairePhoto,
} from "../../services/partenairesServices";
import Button from "../../components/Button/Button";
import ButtonEdit from "../../components/Button/ButtonEdit";
import ButtonDelete from "../../components/Button/ButtonDelete";
import { FaPlus, FaSave, FaTimes } from "react-icons/fa";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import CreatePartenaireModal from "./CreatePartenaireModal";
import EditPartenaireModal from "./EditPartenaireModal";

const SettingsPartenaires = () => {
  const [partenaires, setPartenaires] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedPartenaire, setSelectedPartenaire] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchPartenaires();
  }, []);

  const fetchPartenaires = async () => {
    try {
      const data = await getAllPartenaires();
      setPartenaires(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des partenaires", error);
    }
  };

  const handleDeletePartenaire = async (partenaire) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${partenaire.nom} ?`)) {
      try {
        await deletePartenaire(partenaire.id);
        toast.success("Partenaire supprimé avec succès");
        fetchPartenaires();
      } catch (error) {
        toast.error("Erreur lors de la suppression d’un partenaire");
        console.error("Erreur lors de la suppression d’un partenaire", error);
      }
    }
  };

  const handleToggleActive = async (partenaire) => {
    try {
      await updatePartenaireActivation(partenaire.id, { is_active: !partenaire.is_active });
      toast.success("Statut du partenaire mis à jour avec succès");
      fetchPartenaires();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du statut du partenaire");
      console.error("Erreur lors de la mise à jour du statut du partenaire", error);
    }
  };

  const openEditModal = (partenaire) => {
    setSelectedPartenaire(partenaire);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedPartenaire(null);
    setIsEditModalOpen(false);
  };

  const openUploadModal = (partenaire) => {
    setSelectedPartenaire(partenaire);
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setSelectedPartenaire(null);
    setSelectedFile(null);
    setIsUploadModalOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updatePartenairePhoto(selectedPartenaire.id, selectedFile, token);
      setPartenaires((prevPartenaires) =>
        prevPartenaires.map((partenaire) =>
          partenaire.id === selectedPartenaire.id ? { ...partenaire, photo_url: imageUrl } : partenaire
        )
      );
      toast.success("Photo mise à jour avec succès!");
      closeUploadModal(); // Fermer la modale après le succès
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      toast.error("Erreur lors de l'upload de l'image.");
    } finally {
      setIsUploading(false);
    }
  };

  const movePartenaireUp = async (index) => {
    if (index === 0) return;
    const newPartenaires = [...partenaires];
    const temp = newPartenaires[index - 1];
    newPartenaires[index - 1] = newPartenaires[index];
    newPartenaires[index] = temp;
    newPartenaires[index - 1].ordre--;
    newPartenaires[index].ordre++;
    setPartenaires(newPartenaires);
    try {
      await updatePartenaireOrder(newPartenaires);
      fetchPartenaires();
      toast.success("Ordre des partenaires mis à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de l'ordre des partenaires");
      console.error("Erreur lors de la mise à jour de l'ordre des partenaires", error);
    }
  };

  const movePartenaireDown = async (index) => {
    if (index === partenaires.length - 1) return;
    const newPartenaires = [...partenaires];
    const temp = newPartenaires[index + 1];
    newPartenaires[index + 1] = newPartenaires[index];
    newPartenaires[index] = temp;
    newPartenaires[index + 1].ordre++;
    newPartenaires[index].ordre--;
    setPartenaires(newPartenaires);
    try {
      await updatePartenaireOrder(newPartenaires);
      fetchPartenaires();
      toast.success("Ordre des partenaires mis à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour de l'ordre des partenaires");
      console.error("Erreur lors de la mise à jour de l'ordre des partenaires", error);
    }
  };

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4">
      <div className="flex justify-center mb-4">
        <Button
          text="Ajouter un Partenaire"
          icon={FaPlus}
          onClick={() => setIsCreateModalOpen(true)}
          className="mb-3 gap-2"
          hoverLabel="Ajouter un nouveau partenaire"
        />
      </div>
      {isCreateModalOpen && (
        <CreatePartenaireModal
          isActive={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refreshPartenaires={fetchPartenaires}
        />
      )}
      {isEditModalOpen && selectedPartenaire && (
        <EditPartenaireModal
          isActive={isEditModalOpen}
          onClose={closeEditModal}
          refreshPartenaires={fetchPartenaires}
          partenaireData={selectedPartenaire}
        />
      )}
      {isUploadModalOpen && selectedPartenaire && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Changer le logo de {selectedPartenaire.nom}
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  icon={FaSave}
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={closeUploadModal}
                  icon={FaTimes}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="overflow-x-auto">
        <h2 className="text-2xl font-bold mb-4 text-left dark:text-dark">
          Gestion des Partenaires
        </h2>
        <div className="hidden lg:block overflow-x-auto">
          <table className="table-auto bg-white dark:bg-slate-300 font-montserrat max-w-[800px] mx-auto border-collapse border-2 border-slate-300">
            <thead className="bg-gray-800 text-white">
              <tr className="border-collapse border-2 border-slate-300">
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Nom
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Photo
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  URL
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Ordre
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Actif
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {partenaires.map((partenaire, index) => (
                <tr key={partenaire.id}>
                  <td className={`px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900`}>
                    {partenaire.nom}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex flex-col items-center">
                      <img src={partenaire.photo_url} alt={partenaire.nom} className="w-16 h-16 object-cover cursor-pointer" onClick={() => openUploadModal(partenaire)} />
                      <span className="text-xs text-gray-500 mt-1 cursor-pointer" onClick={() => openUploadModal(partenaire)}>
                        Modifier le logo
                      </span>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <a href={partenaire.link_url} target="_blank" rel="noopener noreferrer">
                      {partenaire.link_url}
                    </a>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                    {partenaire.ordre}
                    <div className="flex flex-col ml-2">
                      <button onClick={() => movePartenaireUp(index)} className="text-blue-400 hover:text-blue-900">
                        <TbTriangleFilled />
                      </button>
                      <button onClick={() => movePartenaireDown(index)} className="text-blue-400 hover:text-blue-900">
                        <TbTriangleInvertedFilled />
                      </button>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button onClick={() => handleToggleActive(partenaire)}>
                      {partenaire.is_active ? "✅" : "❌"}
                    </button>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex">
                      <ButtonEdit
                        onClick={() => openEditModal(partenaire)}
                        className="mr-2"
                      />
                      <ButtonDelete
                        onClick={() => handleDeletePartenaire(partenaire)}
                        className="ml-2"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="lg:hidden grid grid-cols-1 gap-4">
          {partenaires.map((partenaire, index) => (
            <div key={partenaire.id} className="bg-slate-100 dark:bg-slate-200 shadow-md rounded-lg p-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-900">{partenaire.nom}</h3>
                <div className="flex items-center gap-2">
                  <button onClick={() => movePartenaireUp(index)} className="text-blue-400 hover:text-blue-900">
                    <TbTriangleFilled />
                  </button>
                  <button onClick={() => movePartenaireDown(index)} className="text-blue-400 hover:text-blue-900">
                    <TbTriangleInvertedFilled />
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-center mb-4">
                <img src={partenaire.photo_url} alt={partenaire.nom} className="w-full h-32 object-contain mb-2 cursor-pointer" onClick={() => openUploadModal(partenaire)} />
                <span className="text-xs text-gray-500 mt-1 cursor-pointer" onClick={() => openUploadModal(partenaire)}>
                  Modifier le logo
                </span>
              </div>
              <div className="mb-4">
                <a href={partenaire.link_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  {partenaire.link_url}
                </a>
              </div>
              <div className="flex items-center justify-between">
                <button onClick={() => handleToggleActive(partenaire)} className="text-normal">
                  {partenaire.is_active ? "✅ Affiché" : "❌ Non Affiché"}
                </button>
                <div className="flex gap-2">
                  <ButtonEdit onClick={() => openEditModal(partenaire)} />
                  <ButtonDelete onClick={() => handleDeletePartenaire(partenaire)} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SettingsPartenaires;
