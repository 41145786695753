import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "../../components/Button/Button";
import ButtonEdit from "../../components/Button/ButtonEdit";
import ButtonDelete from "../../components/Button/ButtonDelete";
import { getAllActeursListWithGroup, deleteActeur } from "../../services/acteursServices";
import { getGroupsList } from "../../services/groupServices";
import CreateActeurModal from "./CreateActeurModal";
import EditActeurModal from "./EditActeurModal";
import ManageGroupModal from "./ManageGroupModal";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import { useUser } from "../../contexts/UserContext";
import { getRoleFromRoleId } from "../../utils/roleHelper";
import useScrollToTop from "../../hooks/useScrollToTop";

const ActeursPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [acteurs, setActeurs] = useState([]);
  const [displayedActeurs, setDisplayedActeurs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showManageGroupModal, setShowManageGroupModal] = useState(false);
  const [editActeurId, setEditActeurId] = useState(null);
  const [manageGroupActeur, setManageGroupActeur] = useState(null);

  const fetchActeurs = async () => {
    try {
      const acteursData = await getAllActeursListWithGroup();
      setActeurs(acteursData);
      setDisplayedActeurs(acteursData);
    } catch (error) {
      console.error("Erreur lors du chargement des acteurs:", error);
      toast.error("Erreur lors du chargement des acteurs");
    }
  };

  const fetchGroups = async () => {
    try {
      const groupsData = await getGroupsList();
      setGroups(groupsData);
    } catch (error) {
      console.error("Erreur lors du chargement des groupes:", error);
      toast.error("Erreur lors du chargement des groupes");
    }
  };

  useEffect(() => {
    fetchActeurs();
    fetchGroups();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterActeurs(searchTermLocal, selectedGroup);
  };

  const handleGroupChange = (e) => {
    const selectedGroupLocal = e.target.value;
    setSelectedGroup(selectedGroupLocal);
    filterActeurs(searchTerm, selectedGroupLocal);
  };

  const filterActeurs = (term, group) => {
    let filteredActeurs = acteurs;
    if (term) {
      filteredActeurs = filteredActeurs.filter(
        (acteur) =>
          acteur.nom.toLowerCase().includes(term.toLowerCase()) ||
          acteur.prenom.toLowerCase().includes(term.toLowerCase())
      );
    }
    if (group) {
      filteredActeurs = filteredActeurs.filter((acteur) =>
        acteur.Groups.some((grp) => grp.nom === group)
      );
    }
    setDisplayedActeurs(filteredActeurs);
  };

  const deleteActeurById = async (acteurId) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer cet acteur ? ID: ${acteurId}`)) {
      try {
        await deleteActeur(acteurId);
        toast.success("Acteur supprimé avec succès");
        setActeurs(acteurs.filter((acteur) => acteur.id !== acteurId));
        setDisplayedActeurs(displayedActeurs.filter((acteur) => acteur.id !== acteurId));
      } catch (error) {
        console.error("Erreur lors de la suppression de l'acteur:", error);
        toast.error("Erreur lors de la suppression de l'acteur");
      }
    }
  };

  const handleAddActeur = () => {
    setShowCreateModal(true);
  };

  const openEditModal = (acteurId) => {
    setEditActeurId(acteurId);
    setShowEditModal(true);
  };

  const openManageGroupModal = (acteur) => {
    console.log("Acteur pour gestion de groupes:", acteur);
    setManageGroupActeur(acteur);
    setShowManageGroupModal(true);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing des Acteurs du Judo
      </h2>
      <div className="w-full max-w-[1280px] mx-auto">
        <Button
          text="Ajouter Acteur"
          onClick={handleAddActeur}
          icon={FaPlus}
          className="w-full gap-2"
        />
        <CreateActeurModal
          isOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          refreshList={fetchActeurs}
        />
        <EditActeurModal
          isOpen={showEditModal}
          acteurId={editActeurId}
          onClose={() => setShowEditModal(false)}
          refreshList={fetchActeurs}
        />
        <ManageGroupModal
          isOpen={showManageGroupModal}
          onClose={() => setShowManageGroupModal(false)}
          acteurId={manageGroupActeur?.id}
          acteurNom={manageGroupActeur?.nom}
          acteurPrenom={manageGroupActeur?.prenom}
          groups={groups}
          acteurGroups={manageGroupActeur?.Groups || []}
          refreshData={fetchActeurs}
        />
        <input
          className="input input-bordered mt-4 mb-4 w-full"
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select
          className="input input-bordered mb-4 w-full"
          value={selectedGroup}
          onChange={handleGroupChange}
        >
          <option value="">Tous les groupes</option>
          {groups.map((group) => (
            <option key={group.id} value={group.nom}>
              {group.nom}
            </option>
          ))}
        </select>
      </div>
      <div className="text-center mb-4">
        <p className="text-xl font-semibold">{displayedActeurs.length} acteurs trouvés</p>
      </div>
      <div className="overflow-x-auto shadow-xl">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Photo
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Nom Prénom
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Email
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Téléphone
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase min-w-[400px]">
                Groupes (R = Responsable)
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Club
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Groupe souhaité
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Utilisateur
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedActeurs.map((acteur) => (
              <tr key={acteur.id} className="text-left">
                <td>
                  <img
                    className="rounded-full w-16 object-cover max-h-16"
                    src={acteur.photo_url || "/img/user_avatar.jpg"}
                    alt="Avatar"
                  />
                </td>
                <td className="text-left">
                  {acteur.nom} {acteur.prenom}
                </td>
                <td className="text-left">{acteur.email}</td>
                <td className="text-left">{acteur.tel}</td>
                <td className="text-left text-sm">
                  {acteur.Groups && acteur.Groups.length > 0 ? (
                    acteur.Groups.map((group) => (
                      <div key={group.id}>
                        {group.ActeurGroup.is_responsable ? <span className="text-red-500 font-bold">R</span> : null} {group.nom}
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </td>
                <td className="text-left">{acteur.club_acteur}</td>
                <td className="text-left">
                  {acteur.groupe_souhaite ? acteur.groupe_souhaite : ""}
                </td>
                <td className="text-left">
                  {acteur.Users && acteur.Users.length > 0 ? (
                    acteur.Users.map((user, idx) => (
                      <div key={`${acteur.id}-${idx}`}>
                        {user.nom} {user.prenom}
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </td>
                <td className="flex items-center gap-2">
                  <ButtonEdit
                    onClick={() => openEditModal(acteur.id)}
                    className=""
                    hoverLabel="Éditer"
                  />
                  <ButtonDelete
                    onClick={() => deleteActeurById(acteur.id)}
                    className=""
                    hoverLabel="Supprimer"
                  />
                  <Button
                    onClick={() => openManageGroupModal(acteur)}
                    className="btn-info flex items-center gap-2"
                    text="Groupes"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ActeursPage;
