import React from "react";
import { FaTimes, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button from './../../components/Button/Button';

const EventDetailsModal = ({ event, isOpen, onClose, userRole }) => {
  const navigate = useNavigate(); // Utiliser le hook useNavigate

  if (!isOpen || !event) {
    return null;
  }

  const goToEventPage = (eventId) => {
    navigate(`/convocations-admin/${eventId}`);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
      <div className="relative bg-white dark:bg-slate-600 rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 max-h-[80vh] overflow-y-auto">
        <div className="absolute top-4 right-4">
          <button onClick={onClose} className="text-white bg-primary rounded-full p-2">
            <FaTimes />
          </button>
        </div>
        <div className="flex flex-col md:flex-row h-full overflow-y-auto">
          <div className="flex flex-col w-full md:w-2/3 p-4 overflow-y-auto">
            <h3 className="text-xl leading-6 font-medium mb-4 bg-slate-800 text-white p-2">{event.titre}</h3>
            {event.description && <p className="text-sm text-gray-500 dark:text-slate-200" dangerouslySetInnerHTML={{__html: event.description}}></p>}
            {event.start && <p><strong className="dark:text-slate-400">Date de début :</strong> {new Date(event.start).toLocaleDateString("fr-FR")}</p>}
            {event.end && event.end !== "0000-00-00" && (
              <p>
                <strong className="dark:text-slate-400">Date de fin :</strong> {new Date(event.end).toLocaleDateString("fr-FR")}
              </p>
            )}
            {event.lieu_event && <p><strong className="dark:text-slate-400">Lieu :</strong> {event.lieu_event}</p>}
            {event.type_event && <p><strong className="dark:text-slate-400">Type :</strong> {event.type_event}</p>}
            {event.level_event && <p><strong className="dark:text-slate-400">Niveau :</strong> {event.level_event}</p>}
            {event.cate_event && <p><strong className="dark:text-slate-400">Catégories :</strong> {event.cate_event}</p>}
            {event.horaire_event && (
              <p>
                <strong className="dark:text-slate-400">Horaires :</strong>
                <span
                  className="text-gray-600 dark:text-slate-200"
                  dangerouslySetInnerHTML={{ __html: event.horaire_event }}
                />
              </p>
            )}
            {event.agenda_url && <p><a href={event.agenda_url} target="_blank" rel="noopener noreferrer" className="text-black dark:text-white hover:text-primary dark:hover:text-primary">Ajouter à votre agenda</a></p>}

            {userRole === "Administrateur" || userRole === "Bureau" ? (
              <Button text="Acteurs inscrits" onClick={() => goToEventPage(event.id)} icon={FaUsers} className="w-full mt-4 gap-2" />
            ) : null}
          </div>
          {event.photo_url && (
            <div className="w-full md:w-1/3 flex justify-center items-center p-4 bg-gray-100 dark:bg-gray-600 overflow-hidden">
              <img src={event.photo_url} alt="Event" className="max-w-full h-full object-cover rounded-md shadow"/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsModal;
