import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FaCalendarAlt, FaEnvelope, FaCalendarPlus, FaMapMarked,
  FaAddressCard, FaImage, FaUserTie, FaCalendarCheck, FaYoutube
} from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { GiKimono, GiGong } from "react-icons/gi";
import { MdDashboard, MdOutlineInventory, MdGroups, MdEmojiEvents, MdEventNote } from "react-icons/md";
import { PiTranslateFill, PiExamFill, PiUserListFill, PiArticleNyTimesFill } from "react-icons/pi";
import { LuListTodo } from "react-icons/lu";
import { BsDiagram3Fill } from "react-icons/bs";
import { RiArticleFill } from "react-icons/ri";
import { ImUsers } from "react-icons/im";
import { SiGoogleforms } from "react-icons/si";
import { useUser } from './../../contexts/UserContext'; // Importer useUser

// Association mise à jour des titres de liens et des icônes
const linkIcon = {
  "Inscription": <SiGoogleforms />,
  "Calendrier": <FaCalendarAlt />,
  "Résultats": <MdEmojiEvents />,
  "Actualités": <PiArticleNyTimesFill />,
  "Galerie Photos": <FaImage />,
  "Commissions": <BsDiagram3Fill />,
  "Listing Clubs": <FaMapMarked />,
  "Vie des Clubs": <RiArticleFill />,
  "Lexique": <PiTranslateFill />,
  "Arbitrage Judo": <GiGong />,
  "Techniques": <GiKimono />,
  "Partenaires Kata": <ImUsers />,
  "Messagerie": <FaEnvelope />,
  "Tableau de Bord": <MdDashboard />,
  "Petites Annonces": <FaAddressCard />,
  "Proposer événement": <MdEventNote />,
  "Correspondance": <PiArticleNyTimesFill />,
  "ToDoList": <LuListTodo />,
  "Convocations": <FaCalendarCheck />,
  "Règlement Arbitrage": <GiGong />,
  "Quizz Video": <FaYoutube />,
  "Listing utilisateurs": <PiUserListFill />,
  "Listing acteurs": <GiKimono />,
  "Listing groupes": <MdGroups />,
  "Listing Officiels": <FaUserTie />,
  "Convocations Admin": <FaCalendarCheck />,
  "Notation": <PiExamFill />,
  "Événements": <FaCalendarPlus />,
  "Inventaire": <MdOutlineInventory />,
  "Paramètres": <IoMdSettings />,
};

const sections = {
  1: {
    label: "Visiteurs & Utilisateurs",
    links: ["Inscription", "Calendrier", "Résultats", "Actualités", "Galerie Photos", "Commissions", "Listing Clubs", "Vie des Clubs", "Lexique", "Arbitrage Judo", "Techniques", "Partenaires Kata"]
  },
  2: {
    label: "Responsables Clubs & Enseignants",
    links: ["Messagerie", "Tableau de Bord", "Petites Annonces", "Proposer événement", "Correspondance"]
  },
  3: {
    label: "Membres du Comité",
    links: ["Messagerie", "Tableau de Bord", "Convocations", "ToDoList"]
  },
  4: {
    label: "Arbitres & Commissaires Sportifs",
    links: ["Messagerie", "Tableau de Bord", "Convocations", "Règlement Arbitrage", "Quizz Video"]
  },
  5: {
    label: "Administrateur, CTF, Secrétaire",
    links: ["Messagerie", "Tableau de Bord", "Listing utilisateurs", "Listing acteurs", "Listing groupes", "Listing Officiels", "Convocations Admin", "Notation", "Événements", "Inventaire", "Paramètres"]
  },
};

const MegaMenu = ({ role_id, onClose }) => {
  const { user } = useUser(); // Utiliser le contexte utilisateur pour vérifier l'état de connexion

  // Création d'un tableau ordonné basé sur le role_id pour l'ordre spécifique des sections
  let orderedSections;
  if (role_id === 7) {
    orderedSections = [sections[1], sections[2], sections[3], sections[4]];
  } else if (role_id === 6) {
    orderedSections = [sections[1]]; // Visiteurs
  } else if (role_id === 5) {
    orderedSections = [sections[1], sections[2]]; // Visiteurs + Responsables Clubs
  } else if (role_id === 3) {
    orderedSections = [sections[1], sections[3]]; // Visiteurs + Membres du Comité
  } else if (role_id === 4) {
    orderedSections = [sections[1], sections[4]]; // Visiteurs + Arbitres & Commissaires Sportifs
  } else if (role_id === 1 || role_id === 2) {
    orderedSections = [sections[1], sections[2], sections[3], sections[4], sections[5]]; // Toutes les sections
  } else {
    orderedSections = [sections[1]]; // Par défaut, montrer la section Visiteurs
  }

  // Filtrer les liens en fonction de l'état de connexion de l'utilisateur
  orderedSections = orderedSections.map(section => {
    return {
      ...section,
      links: section.links.filter(link => {
        if (link === "Inscription" && !user) {
          return false; // Ne pas afficher le lien "Inscription" si l'utilisateur n'est pas connecté
        }
        return true;
      })
    };
  });

  return (
    <div className="max-w-[1280px] mx-auto bg-base-200 dark:bg-slate-600 font-montserrat overflow-hidden relative">
      <div className="overflow-y-auto max-h-[70vh]">
        {/* Bouton de fermeture */}
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 mr-6 text-white bg-primary hover:bg-primary-dark rounded-full focus:outline-none h-8 w-8"
          style={{ zIndex: 10 }}
        >
          ×
        </button>
        {orderedSections.map((section, idx) => (
          <div key={idx} className="p-6">
            <div className="font-montserrat text-primary dark:text-white shadow-sm text-lg font-semibold mb-4">{section.label}</div>
            <ul className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {section.links.map((link, linkIdx) => (
                <li key={linkIdx} className="text-center">
                  <NavLink
                    to={`/${link.toLowerCase().replace(/[\s'ç]/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, '')}`}
                    className="dark:bg-gray-800 block relative overflow-hidden rounded-lg p-3 shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 bg-white hover:bg-blue-100"
                    onClick={onClose}
                  >
                    <div className='flex flex-col items-center justify-center dark:bg-gray-800'>
                      <div className="text-3xl text-primary my-3">{linkIcon[link]}</div>
                      <span className="block text-sm font-semibold text-gray-800 dark:text-white">{link}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
