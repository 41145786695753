import React, { useState, useEffect } from 'react';
import Button from "../../components/Button/Button";
import { FaTimes } from "react-icons/fa";
import { getAllActorsByEventId, deleteActorFromEvent } from './../../services/acteureventServices';
import { toast } from 'react-toastify';

const ActorUnregistrationModal = ({ isOpen, onClose, eventId, refreshList }) => {
  const [actors, setActors] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchActors();
    }
  }, [isOpen]);

  const fetchActors = async () => {
    try {
      const data = await getAllActorsByEventId(eventId);
      const sortedActors = data.Acteur.sort((a, b) => {
        const nameA = `${a.nom} ${a.prenom}`.toLowerCase();
        const nameB = `${b.nom} ${b.prenom}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      setActors(sortedActors);
    } catch (error) {
      console.error('Erreur lors du chargement des acteurs', error);
      toast.error('Erreur lors du chargement des acteurs.');
    }
  };

  const handleUnregister = async (acteurId) => {
    try {
      await deleteActorFromEvent(acteurId, eventId);
      toast.success('Acteur désinscrit avec succès.');
      fetchActors();
      refreshList();
    } catch (error) {
      console.error("Erreur lors de la désinscription de l'acteur", error);
      toast.error("Erreur lors de la désinscription de l'acteur.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Désinscrire Acteur
        </h3>
        <div className="mb-4">
          {actors.length > 0 ? (
            actors.map((actor) => (
              <div key={actor.ActeurEvent.acteur_id} className="flex justify-between items-center mb-2">
                <span>{actor.nom} {actor.prenom} - {actor.ActeurEvent.poste}</span>
                <Button
                  onClick={() => handleUnregister(actor.ActeurEvent.acteur_id)}
                  className="btn-danger flex items-center gap-2"
                  text="Désinscrire"
                />
              </div>
            ))
          ) : (
            <p>Aucun acteur inscrit.</p>
          )}
        </div>
        <Button
          onClick={onClose}
          className="btn-ghost flex items-center gap-2 mt-4"
          text="Fermer"
          icon={FaTimes}
        />
      </div>
    </div>
  );
};

export default ActorUnregistrationModal;
