import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/acteursevents`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour afficher tous les insctiptions, notes et observations
export const getAllResults = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allresults`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des inscriptions:", error);
    return [];
  }
};


// Fonction pour afficher tous les acteurs d'un événement
export const getAllActorsByEventId = async (eventId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getActeursByEventId/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du chargement des acteurs de l'événement:",
      error
    );
    return [];
  }
};

// Fonction pour afficher tous les événements d'un acteur
export const getAllEventsByActorId = async (acteurId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getEventsByActeurId/${acteurId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du chargement des événements de l'acteur:",
      error
    );
    return [];
  }
};

/// POST
// Fonction pour ajouter un acteur à un événement
export const assignActorToEvent = async (acteurId, eventId, poste) => {
  try {
    const response = await axios.post(
      `${apiUrl}/assignActorToEvent`,
      { acteur_id: acteurId, event_id: eventId, poste },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'acteur à l'événement:", error);
    throw error;
  }
};

/// PUT
// Fonction pour mettre à jour les informations d'un acteur d'un événement
export const updateActorEvent = async (acteurId, eventId, data) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateActeurInEvent/${acteurId}/${eventId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour des informations de l'acteur:",
      error
    );
    throw error;
  }
};

// Fonction pour toggle la validation d'un acteur sur un évenement
export const toggleActeurValidation = async (acteurId, eventId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/toggleValidation/${acteurId}/${eventId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la validation de l'acteur:", error);
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer un acteur d'un événement
export const deleteActorFromEvent = async (acteurId, eventId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/removeActeurFromEvent/${acteurId}/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de l'acteur de l'événement:",
      error
    );
    throw error;
  }
};
