import React, { useState } from "react";

const ObservationsCell = ({ observations }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const safeObservations = observations || "";
  const truncatedText = safeObservations.length > 100 ? safeObservations.substring(0, 100) + "..." : safeObservations;

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: isExpanded ? safeObservations : truncatedText }}></div>
      {safeObservations.length > 100 && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-blue-500 hover:underline"
        >
          {isExpanded ? "Réduire" : "Suite"}
        </button>
      )}
    </div>
  );
};

export default ObservationsCell;
