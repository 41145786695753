import React, { useState } from "react";
import { FaEdit, FaTrashAlt, FaInfoCircle } from "react-icons/fa";
import { deleteActeur } from "./../../services/acteursServices";
import EditActeurModalByUser from "./EditActeurModalByUser";
import InfoModal from "./InfoModal";

const Card = ({ acteur, fetchActeurs }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleDeleteActeur = async () => {
    try {
      await deleteActeur(acteur.id);
      fetchActeurs();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'acteur :", error);
    }
  };

  const handleEdit = () => setIsEditModalOpen(true);
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    fetchActeurs();
  };

  const openInfoModal = () => setIsInfoModalOpen(true);
  const closeInfoModal = () => setIsInfoModalOpen(false);

  const photoUrl = acteur.photo_url || "./img/user_avatar.jpg";
  const groupes = acteur.Groups ? acteur.Groups.map((group) => group.nom) : [];

  return (
    <div className="flex flex-col md:flex-row bg-white bg-opacity-80 rounded-lg overflow-hidden shadow-md relative m-4 w-full max-w-md">
      <div className="relative w-full h-48 md:w-2/5 md:h-auto">
        <img
          src={photoUrl}
          alt={`${acteur.nom} ${acteur.prenom}`}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="p-4 flex flex-col justify-between w-full h-auto md:w-3/5 md:h-full">
        <div>
          <h3 className="text-lg font-bold uppercase text-gray-800 my-2 w-full font-montserrat">
            {acteur.nom} {acteur.prenom}
          </h3>
          <div className="text-xs mb-3 text-black bg-backgroundMain w-full bg-gray-200 p-2">
            {groupes.length > 0 ? (
              groupes.map((group, index) => (
                <p key={index}>✅ {group}</p>
              ))
            ) : (
              <p>Aucun groupe assigné</p>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between mt-4">
          <div className="flex gap-2">
            <button
              onClick={openInfoModal}
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
            >
              <FaInfoCircle />
            </button>
            <button
              onClick={handleEdit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              <FaEdit />
            </button>
            <button
              onClick={handleDeleteActeur}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              <FaTrashAlt />
            </button>
          </div>
        </div>
        <EditActeurModalByUser
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          acteur={acteur} // Pass the full acteur object
          refreshList={fetchActeurs}
        />
        <InfoModal
          isOpen={isInfoModalOpen}
          onClose={closeInfoModal}
          acteur={acteur}
        />
      </div>
    </div>
  );
};

export default Card;
