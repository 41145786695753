import React, { useState, useEffect } from "react";
import {
  getSettings,
  updateSettings,
  updateFirstHeaderImage,
  updateSecondHeaderImage,
} from "../../services/settingsServices";
import { getAllGalleries } from "../../services/gallerieServices";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HomeSettingsForm = () => {
  const [settings, setSettings] = useState({
    header_url1: "",
    header_texte1: "",
    header_url2: "",
    header_texte2: "",
    about_title: "",
    about_content: "",
    carrousel_id: "",
  });
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUploading1, setIsUploading1] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  useEffect(() => {
    const fetchSettingsAndGalleries = async () => {
      try {
        const [settingsData, galleriesData] = await Promise.all([getSettings(), getAllGalleries()]);
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
        setGalleries(galleriesData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
      setLoading(false);
    };

    fetchSettingsAndGalleries();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleContentChange = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSettings(settings);
      toast.success("Paramètres mis à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des paramètres");
    }
  };

  const handleFileChange1 = (e) => {
    setSelectedFile1(e.target.files[0]);
  };

  const handleFileChange2 = (e) => {
    setSelectedFile2(e.target.files[0]);
  };

  const handleUploadSubmit1 = async (e) => {
    e.preventDefault();
    if (!selectedFile1) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading1(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateFirstHeaderImage(selectedFile1, token);
      setSettings((prevSettings) => ({
        ...prevSettings,
        header_url1: imageUrl,
      }));
      toast.success("Image header_url1 mise à jour avec succès!");
      setSelectedFile1(null);
      setIsUploading1(false);
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image header_url1 :", error);
      toast.error("Erreur lors de l'upload de l'image.");
      setIsUploading1(false);
    }
  };

  const handleUploadSubmit2 = async (e) => {
    e.preventDefault();
    if (!selectedFile2) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading2(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateSecondHeaderImage(selectedFile2, token);
      setSettings((prevSettings) => ({
        ...prevSettings,
        header_url2: imageUrl,
      }));
      toast.success("Image header_url2 mise à jour avec succès!");
      setSelectedFile2(null);
      setIsUploading2(false);
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image header_url2 :", error);
      toast.error("Erreur lors de l'upload de l'image.");
      setIsUploading2(false);
    }
  };

  if (loading) return <div>Chargement...</div>;

  const renderImageCard = (title, imageUrl, onChangeImage, isUploading, handleUploadSubmit, handleFileChange) => (
    <div className="bg-gray-200 shadow-md rounded-lg p-4 flex items-center mb-4">
      <div className="flex-1">
        <label className="text-sm font-medium text-gray-700 mb-1 block">{title}</label>
        <input
          type="text"
          value={imageUrl}
          readOnly
          className="input input-bordered w-full mb-2"
        />
        <Button text="Changer l'image" onClick={onChangeImage} className="mt-2" />
      </div>
      <div className="ml-4">
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Aperçu"
            className="w-32 h-32 object-cover rounded-md"
          />
        )}
      </div>
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Changer l'image
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={() => onChangeImage(false)}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4 max-w-4xl">
      <h3 className="text-xl font-semibold mb-6">Paramètres de la Page d'Accueil</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        {renderImageCard(
          "Lien de la première image du header",
          settings.header_url1,
          () => setIsUploading1(true),
          isUploading1,
          handleUploadSubmit1,
          handleFileChange1
        )}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Texte de la première image du header
          </label>
          <ReactQuill
            value={settings.header_texte1}
            onChange={(value) => handleContentChange('header_texte1', value)}
            className="mb-3 bg-slate-200 dark:bg-slate-100 dark:text-black"
          />
        </div>
        {renderImageCard(
          "Lien de la deuxième image du header",
          settings.header_url2,
          () => setIsUploading2(true),
          isUploading2,
          handleUploadSubmit2,
          handleFileChange2
        )}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Texte de la deuxième image du header
          </label>
          <ReactQuill
            value={settings.header_texte2}
            onChange={(value) => handleContentChange('header_texte2', value)}
            className="mb-3 bg-slate-200 dark:bg-slate-100 dark:text-black"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Titre de la section "À propos"
          </label>
          <input
            type="text"
            name="about_title"
            value={settings.about_title}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Contenu de la section "À propos"
          </label>
          <ReactQuill
            value={settings.about_content}
            onChange={(value) => handleContentChange('about_content', value)}
            className="mb-3 bg-slate-200 dark:bg-slate-100 dark:text-black"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Sélectionnez la galerie pour le carrousel
          </label>
          <select
            name="carrousel_id"
            value={settings.carrousel_id}
            onChange={handleChange}
            className="input input-bordered w-full"
          >
            {galleries.map((gallery) => (
              <option key={gallery.id} value={gallery.id}>
                {gallery.titre}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <Button
            text="Mettre à jour"
            type="submit"
            className="btn-primary gap-2"
          />
        </div>
      </form>
    </div>
  );
};

export default HomeSettingsForm;
