import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../components/Button/Button';
import { createActeur } from '../../services/acteursServices';
import { toast } from 'react-toastify';

const CreateActeurModal = ({ isOpen, onClose, refreshList }) => {
    const [acteurData, setActeurData] = useState({
        nom: '',
        prenom: '',
        email: '',
        tel: '',
        adresse: '',
        cp: '',
        commune: '',
        genre: '',
        date_naissance: '',
        photo_url: '',
        club_acteur: '',
        licence_number: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setActeurData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalActeurData = {
            ...acteurData,
            photo_url: acteurData.photo_url || '/img/user_avatar.jpg',
        };
        try {
            await createActeur(finalActeurData);
            toast.success("Acteur créé avec succès");
            refreshList(); // Refresh the acteurs list on success
            onClose(); // Close modal on success
            setActeurData({
                nom: '',
                prenom: '',
                email: '',
                tel: '',
                adresse: '',
                cp: '',
                commune: '',
                genre: '',
                date_naissance: '',
                photo_url: '',
                club_acteur: '',
                licence_number: '',
            }); // Reset form fields
        } catch (error) {
            console.error("Erreur lors de la création de l'acteur:", error);
            toast.error("Erreur lors de la création de l'acteur");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Ajouter un Acteur</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-bold mb-2">Nom:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="nom"
                            value={acteurData.nom}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Prénom:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="text"
                            name="prenom"
                            value={acteurData.prenom}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Email:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="email"
                            name="email"
                            value={acteurData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Téléphone:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="tel"
                            name="tel"
                            value={acteurData.tel}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Adresse:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="text"
                            name="adresse"
                            value={acteurData.adresse}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Code Postal:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="text"
                            name="cp"
                            value={acteurData.cp}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Commune:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="text"
                            name="commune"
                            value={acteurData.commune}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Genre:</label>
                        <select
                            className="select select-bordered w-full dark:text-black"
                            name="genre"
                            value={acteurData.genre}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Sélectionner le genre</option>
                            <option value="Masculin">Masculin</option>
                            <option value="Féminin">Féminin</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Date de Naissance:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="date"
                            name="date_naissance"
                            value={acteurData.date_naissance}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">URL de la photo:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="url"
                            name="photo_url"
                            value={acteurData.photo_url}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Club de l'Acteur:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="text"
                            name="club_acteur"
                            value={acteurData.club_acteur}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Numéro de Licence:</label>
                        <input
                            className="input input-bordered w-full dark:text-black"
                            type="text"
                            name="licence_number"
                            value={acteurData.licence_number}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Créer" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Fermer" icon={FaTimes} onClick={onClose} className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateActeurModal;
