import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { sendMessage } from "./../../services/messageServices"; // Assurez-vous que le chemin d'importation est correct
import { getAdminsAndMembers } from "./../../services/userServices"; // Assurez-vous que le chemin d'importation est correct
import { IoIosSend } from "react-icons/io";
import Button from "./../../components/Button/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importez les styles de React-Quill

const CreateMessageForm = ({
  onSuccess,
  show,
  onHide,
  userId,
  userRole,
  initialRecipientId = "", // ID initial du destinataire pour pré-remplissage
  initialSubject = "", // Sujet initial pour pré-remplissage
}) => {
  const [messageData, setMessageData] = useState({
    recipient_id: initialRecipientId,
    subject: initialSubject,
    content: "",
  });
  const [recipients, setRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecipients = async () => {
      setIsLoading(true);
      try {
        let response = await getAdminsAndMembers();
        console.log(userRole);

        if (userRole === "user") {
          response = response.filter(
            (user) => user.role_id === 1 || user.role_id === 2 || user.role_id === 3
          );
          console.log("Tous les utilisateurs:", response);
        }
        setRecipients(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors du chargement des destinataires:", error);
        toast.error("Erreur lors du chargement des destinataires.");
        setIsLoading(false);
      }
    };

    fetchRecipients();
  }, [userRole]);

  // Effect pour réinitialiser les données du formulaire lorsque les valeurs initiales changent
  useEffect(() => {
    setMessageData({
      recipient_id: initialRecipientId,
      subject: initialSubject,
      content: "",
    });
  }, [initialRecipientId, initialSubject, show]);

  const handleChange = (e) => {
    setMessageData({ ...messageData, [e.target.name]: e.target.value });
  };

  const handleContentChange = (content) => {
    setMessageData({ ...messageData, content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { recipient_id, subject, content } = messageData;

    if (!recipient_id || !subject || !content) {
      toast.error("Veuillez remplir tous les champs.");
      return;
    }
    try {
      await sendMessage({
        ...messageData,
        sender_id: userId,
      });
      toast.success("Message envoyé avec succès.");
      if (onSuccess) {
        onSuccess(); // Action de succès, par exemple fermer le formulaire
      }
      onHide();
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
      toast.error("Erreur lors de l'envoi du message.");
    }
  };

  if (isLoading) {
    return <p>Chargement des destinataires...</p>;
  }

  if (!show) {
    return null; // Ne pas afficher le formulaire si `show` est false
  }

  return (
    <div className="mt-5 container mx-auto p-4 shadow-lg rounded-lg bg-white dark:bg-gray-200 dark:text-gray-800">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Destinataire:</label>
          <select
            name="recipient_id"
            value={messageData.recipient_id}
            onChange={handleChange}
            className="select select-bordered w-full max-w-xs"
            required
          >
            <option value="">Sélectionnez un destinataire</option>
            {recipients.map((recipient) => (
              <option key={recipient.id} value={recipient.id}>
                {recipient.nom} {recipient.prenom}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Objet:</label>
          <select
            name="subject"
            value={messageData.subject}
            onChange={handleChange}
            className="input input-bordered w-full max-w-xs"
            required
          >
            <option value="">Sélectionnez une option</option>
            <option value="Demande de renseignements">Demande de renseignements</option>
            <option value="Compétitions">Compétitions</option>
            <option value="Arbitrage">Arbitrage</option>
            <option value="Autre">Autre</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Contenu:</label>
          <ReactQuill
            value={messageData.content}
            onChange={handleContentChange}
            className="dark:bg-gray-200 dark:text-gray-800"
            required
          />
        </div>

        <div className="flex justify-center gap-4 items-center w-full px-4 mt-4 font-montserrat">
          <Button
            text="Envoyer"
            icon={IoIosSend}
            type="submit"
            className="w-xl gap-2 mb-4"
          />
          <Button text="Annuler" onClick={onHide} className="w-xl gap-2 mb-4" />
        </div>
      </form>
    </div>
  );
};

export default CreateMessageForm;
