import React, { useState, useEffect } from "react";
import { getAllOfficielsList } from "../../services/acteursServices";
import { getGroupsList } from "../../services/groupServices";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "../../hooks/ScrollIndicator";
import { useUser } from "../../contexts/UserContext";
import { getRoleFromRoleId } from "../../utils/roleHelper";
import useScrollToTop from "../../hooks/useScrollToTop";

const OfficielsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [officiels, setOfficiels] = useState([]);
  const [displayedOfficiels, setDisplayedOfficiels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  const fetchOfficiels = async () => {
    try {
      const officielsData = await getAllOfficielsList();
      setOfficiels(officielsData);
      setDisplayedOfficiels(officielsData);
      updateFilteredGroups(officielsData);
    } catch (error) {
      console.error("Erreur lors du chargement des officiels:", error);
      toast.error("Erreur lors du chargement des officiels");
    }
  };

  const fetchGroups = async () => {
    try {
      const groupsData = await getGroupsList();
      setGroups(groupsData);
    } catch (error) {
      console.error("Erreur lors du chargement des groupes:", error);
      toast.error("Erreur lors du chargement des groupes");
    }
  };

  useEffect(() => {
    fetchOfficiels();
    fetchGroups();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterOfficiels(searchTermLocal, selectedGroup);
  };

  const handleGroupChange = (e) => {
    const selectedGroupLocal = e.target.value;
    setSelectedGroup(selectedGroupLocal);
    filterOfficiels(searchTerm, selectedGroupLocal);
  };

  const filterOfficiels = (term, group) => {
    let filteredOfficiels = officiels;
    if (term) {
      filteredOfficiels = filteredOfficiels.filter(
        (officiel) =>
          officiel.nom.toLowerCase().includes(term.toLowerCase()) ||
          officiel.prenom.toLowerCase().includes(term.toLowerCase())
      );
    }
    if (group) {
      filteredOfficiels = filteredOfficiels.filter((officiel) =>
        officiel.Groups.some((grp) => grp.nom === group)
      );
    }
    setDisplayedOfficiels(filteredOfficiels);
    updateFilteredGroups(filteredOfficiels);
  };

  const updateFilteredGroups = (officielsList) => {
    const groupsSet = new Set();
    officielsList.forEach((officiel) => {
      officiel.Groups.forEach((group) => {
        groupsSet.add(group.nom);
      });
    });
    setFilteredGroups([...groupsSet]);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing des Officiels du Comité
      </h2>
      <div className="w-full max-w-[1280px] mx-auto">
        <input
          className="input input-bordered mt-4 mb-4 w-full"
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <select
          className="input input-bordered mb-4 w-full"
          value={selectedGroup}
          onChange={handleGroupChange}
        >
          <option value="">Tous les groupes</option>
          {filteredGroups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </select>
      </div>
      <div className="text-center mb-4">
        <p className="text-xl font-semibold">
          {displayedOfficiels.length} officiels trouvés
        </p>
      </div>
      <div className="overflow-x-auto shadow-xl">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Photo
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Nom Prénom
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase min-w-[400px]">
                Groupes
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider">
                Titre Arbitrage
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedOfficiels.map((officiel) => (
              <tr key={officiel.id} className="text-left">
                <td>
                  <img
                    className="rounded-full w-16 h-16 object-cover"
                    src={officiel.photo_url || "/img/user_avatar.jpg"}
                    alt="Avatar"
                  />
                </td>
                <td className="text-left">
                  {officiel.nom} {officiel.prenom}
                </td>
                <td className="text-left">
                  {officiel.Groups && officiel.Groups.length > 0 ? (
                    officiel.Groups.map((group) => (
                      <div key={group.id}>{group.nom}</div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </td>
                <td className="text-left">{officiel.titre_arbitrage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OfficielsPage;
