import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../components/Button/Button';
import { addClubGirondin } from './../../services/clubsgirondinsServices';
import { useUser } from './../../contexts/UserContext';
import { getRoleFromRoleId } from "./../../utils/roleHelper";

const CreateClubModal = ({ isOpen, onClose, refreshList }) => {
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [formData, setFormData] = useState({
    nom_club: '',
    commune: '',
    secteur: '',
    secteur_geo: '',
    numero_club: '',
    tel: '',
    mail: '',
    president: '',
    enseignant: '',
    adresse: '',
    site_internet: '',
    reseaux_sociaux: '',
    lien_logo: '',
    coordonnees_gps: '',
    lien_maps: '',
    disciplines: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addClubGirondin(formData);
      refreshList();
      onClose();
      setFormData({
        nom_club: '',
        commune: '',
        secteur: '',
        secteur_geo: '',
        numero_club: '',
        tel: '',
        mail: '',
        president: '',
        enseignant: '',
        adresse: '',
        site_internet: '',
        reseaux_sociaux: '',
        lien_logo: '',
        coordonnees_gps: '',
        lien_maps: '',
        disciplines: ''
      });
    } catch (error) {
      console.error('Erreur lors de la création du club', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box-clubsgirondins bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg max-h-full overflow-y-auto">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Créer un Club</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="nom_club"
            placeholder="Nom du club"
            value={formData.nom_club}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
            required
          />
          <input
            type="text"
            name="commune"
            placeholder="Commune"
            value={formData.commune}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
            required
          />
          <input
            type="text"
            name="secteur"
            placeholder="Secteur"
            value={formData.secteur}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="text"
            name="secteur_geo"
            placeholder="Secteur géographique"
            value={formData.secteur_geo}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="text"
            name="numero_club"
            placeholder="Numéro du club"
            value={formData.numero_club}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="text"
            name="tel"
            placeholder="Téléphone"
            value={formData.tel}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="email"
            name="mail"
            placeholder="Email"
            value={formData.mail}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="text"
            name="president"
            placeholder="Président"
            value={formData.president}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="text"
            name="enseignant"
            placeholder="Enseignant(s)"
            value={formData.enseignant}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="text"
            name="adresse"
            placeholder="Adresse"
            value={formData.adresse}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="text"
            name="site_internet"
            placeholder="Site Internet"
            value={formData.site_internet}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="text"
            name="reseaux_sociaux"
            placeholder="Réseaux Sociaux"
            value={formData.reseaux_sociaux}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="text"
            name="lien_logo"
            placeholder="Lien Logo"
            value={formData.lien_logo}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="text"
            name="coordonnees_gps"
            placeholder="Coordonnées GPS"
            value={formData.coordonnees_gps}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <input
            type="text"
            name="lien_maps"
            placeholder="Lien Google Maps"
            value={formData.lien_maps}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"
          />
          <input
            type="text"
            name="disciplines"
            placeholder="Disciplines"
            value={formData.disciplines}
            onChange={handleChange}
            className="input input-bordered w-full mb-3 dark:bg-slate-200"

          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateClubModal;
