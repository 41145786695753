import React from "react";
import ButtonEdit from "../../components/Button/ButtonEdit";
import ButtonDelete from "../../components/Button/ButtonDelete";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const ReglementCard = ({ reglement, onEdit, onDelete, canModify }) => {
  return (
    <div className="card bg-gray-200 shadow-lg p-4 rounded-lg border dark:border-gray-700 dark:bg-gray-800">
      <h3 className="text-lg font-semibold mb-2 bg-gray-800 text-white p-2 rounded-lg">{reglement.title}</h3>

      {reglement.lien_url_image && (
        <img
          src={reglement.lien_url_image}
          alt={reglement.title}
          className="w-full h-48 object-cover mb-4 rounded-lg"
        />
      )}
      <div className="text-sm mb-4">
        <ReactQuill value={reglement.content} readOnly theme="bubble" />
      </div>
      {reglement.lien_url && (
        <a
          href={reglement.lien_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          Voir le règlement
        </a>
      )}
      {canModify && (
        <div className="flex justify-start mt-4">
          <ButtonEdit onClick={onEdit} className="mr-2" />
          <ButtonDelete onClick={onDelete} />
        </div>
      )}
    </div>
  );
};

export default ReglementCard;
